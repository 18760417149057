<div class="row">
  <div class="col">
    <div class="custom-pagination d-flex justify-content-center font-medium">
      @if (totalItems) {
        <pagination [itemsPerPage]="itemsPerPage" [totalItems]="totalItems"
          (pageChanged)="OnpageChanged($event)" [(ngModel)]="currentPage" name="currentPage" [maxSize]="5"
          [boundaryLinks]="true" [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
          [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" (contextmenu)="disableRightClick($event)">
        </pagination>
      }
    </div>
    <ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
      <img src="assets/images/icons/angle-left-solid.svg" alt="angle-left-solid-image">
    </ng-template>
    <ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
      <img src="assets/images/icons/angle-right-solid.svg" alt="angle-right-solid-image">
    </ng-template>
    <ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
      <img src="assets/images/icons/angles-right-solid.svg" alt="angles-right-solid-image">
    </ng-template>
    <ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
      <img src="assets/images/icons/angles-left-solid.svg" alt="angles-left-solid-image">
    </ng-template>
    <!--  <div class="text-center font-medium" *ngIf="totalItems" style="color: #686868;">
    Showing Results for {{prev_page_count +"-"+ current_page_count}} out of {{totalItems}}
  </div> -->
</div>
</div>
