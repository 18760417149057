import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { AppService } from 'src/app/core/services/app.service';
import { S3UploadServices } from '../../../core/services/s3-upload-services.service';
import { LoaderService, RestService } from 'src/app/core/services';
import pLimit from 'p-limit';
import { catchError, from, map, mergeMap, of, Subscription, toArray } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormatBytesPipe } from 'src/app/shared/pipes';
import { API_ENDPOINT, API_KEYPOINT, ASSET_TYPE_CONFIG, CONFIRMATION_POPUP_CONFIG, UPLOAD_MESSAGES } from 'src/app/core/constants';
import { FolderMgmtUtill } from 'src/app/common/utils/folderMgmtUtill';
import { DialogModule } from 'primeng/dialog';
import { NgStyle } from '@angular/common';
import { ConfirmationPopUpComponent } from '../deletepopup/confirmationPopUp.component';
import { FormatBytesPipe as FormatBytesPipe_1 } from '../../pipes/format-bytes.pipe';

interface FileUpload {
  file: File;
  status:  0 | 1 | 2 | 3 | 4;  //'pending' | 'uploading' | 'uploaded' | 'error'  | 'deleted'
  message: 0 | 1 | 2 | 3;   //'InQueue | alreadyExist | Unsupported | InternalServerError'
  progress: number;
  data: {}
}
@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss'],
    standalone: true,
    imports: [
        DialogModule,
        NgStyle,
        ConfirmationPopUpComponent,
        FormatBytesPipe_1,
    ],
})

export class UploadComponent implements OnInit {
  @Input() childFolderId: any;
  @Input() assetPathofFolder: any;
  @Input() modalType: any;
  @Output() closeModalEvent = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: ElementRef;
  message: string[] = [];
  isModalClose: boolean = true;
  isSaveDisabled: boolean = false;
  isModalCopy: boolean = false;
  formD: any = '';
  success: Boolean = false;
  zipSize: any = 0;
  uploadDone: boolean = false;
  uploadDoneWithError: boolean = false;
  allow: any = true;
  zipSizeValue: boolean = false;
  totalAssetSize: number = 0;
  selectedFilesList: Map<string, FileUpload> = new Map();
  userDetails: any;
  isFileZip: boolean = false;
  isUploadClicked: boolean = false;
  private subscriptions: Subscription[] = [];
  formatBytesPipe = new FormatBytesPipe();
  count = {
    Total : 0,
    Success : 0,
    Failed : 0
  }
  confirmationModal: boolean = false;
  uploadMessages = UPLOAD_MESSAGES;

  constructor(
    private s3UploadService: S3UploadServices,
    public appService: AppService,
    public authService: AuthService,
    public loader: LoaderService,
    private messageService: MessageService,
    private restService: RestService,
    private folderMgmtUtils: FolderMgmtUtill
  ) {}

  ngOnInit(): void {
    this.allow = this.modalType !== 'zip';
    this.userDetails = this.authService.getAuthData();
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    window.addEventListener('unload', this.handleUnload); // Trigger API only when leaving
  }

  // ========== Browse files method for select single and multiple assets for upload=====
  selectFiles(event: any) {
    const CHUNK_SIZE = 100; // Batch process 100 files at a time

    // Function to process files in chunks asynchronously
    const processFilesChunk = async (files: any[], startIndex: number, chunkSize: number) => {
      const endIndex = Math.min(startIndex + chunkSize, files.length);
      const filesToProcess = files.slice(startIndex, endIndex);

      // Process each file in parallel using Promise.all
      await Promise.all(filesToProcess.map(processFile));

      // Process the next chunk if there are more files
      if (endIndex < files.length) {
        await processFilesChunk(files, endIndex, chunkSize);
      } else if (this.modalType === 'zip') {
        this.callUploadZipFile(); // Trigger zip upload after all files are processed
      }
    };

    // Function to process a single file
    const processFile = async (file: any) => {
      try {
        const ext = file.name.split('.').pop().toLowerCase();
        let fileExists: any = this.selectedFilesList.get(file.name);

        // If the file already exists and is deleted, remove it from the list
        if (fileExists?.status === 4) {
          this.selectedFilesList.delete(file.name);
          fileExists = 0;
        }
        // If the file does not already exist and it's not a zip file or zip modal is active
        if (!fileExists && (ext !== 'zip' || this.modalType === 'zip')) {
          this.selectedFilesList.set(file.name, {
            file,
            status: 0, // Pending
            message: 0, // In Queue
            progress: -1, // Initial progress
            data: {}
          });

          // Update counters and UI-related flags
          this.count.Total++;
          this.totalAssetSize += file.size;
          this.isSaveDisabled = false;
          this.uploadDone = false;
          this.isUploadClicked = false;
        } else if (ext === 'zip') {
          // Show a warning if the user tries to upload a zip file without the correct modal
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: 'Please use "Zip Upload" to upload zip file.',
          });
        } else {
          // Show a warning if the file already exists in the list
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: 'File already exists in the list.',
          });
        }
      } catch (error) {
        console.error('Error processing file:', error);
      }
    };

    // Handle the file selection
    const handleFiles = () => {
      if (event && event.target && event.target.files.length > 0) {
        const files = Array.from(event.target.files);
        // Process files in chunks asynchronously to avoid UI freezing
        processFilesChunk(files, 0, CHUNK_SIZE);
      }
    };

    // Check if the folder is selected before proceeding
    if (this.childFolderId || parseInt(this.childFolderId) === 0) {
      handleFiles();
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'Folder not selected, Please select the folder.',
      });
    }

    // Clear the event to avoid memory leaks
    event = null;
  }
  

  callUploadZipFile() {
    if (!this.selectedFilesList.size) {
      return;
    }
  
    const zipExtensions = ['zip'];
    let zipFileSize = 0;
    let validFiles = new Map(
      Array.from(this.selectedFilesList.entries()).filter(([key, file]: [string, any]) => {
        // Check if the file's status is 4, and remove it if so
        if (file.status === 4) {
          return false; // Remove files with status 4
        }
  
        const ext = file.file.name.split('.').pop().toLowerCase();
        const fileSize: any = this.formatBytesPipe.transform(file.file.size, 2);
        const [size, unit] = fileSize.split(' ');
        const sizeValue = parseFloat(size);
        zipFileSize = file.file.size;
  
        this.isFileZip = !zipExtensions.includes(ext);
        this.zipSize = sizeValue;
        this.zipSizeValue = unit === 'MB' && sizeValue > 100;
  
        // Check for valid zip extension
        if (this.isFileZip) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: 'Invalid File Extension, Zip file required.',
          });
          this.count.Total--;
          this.totalAssetSize -= zipFileSize;
          return false; // Remove invalid files
        }
  
        // Check for valid size (less than 5GB)
        if (unit === 'GB' && sizeValue > 5) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: 'File size must be less than 5GB.',
          });
          this.totalAssetSize -= zipFileSize;
          this.count.Total--;
          return false; // Remove large files
        }
  
        return true; // Keep valid files for further processing
      })
    );
  
    // For each valid file, call the API to check if the zip is already present or being processed
    validFiles.forEach((file, key) => {
      const params = {
        folderId: this.childFolderId,
        zipFileName: file.file.name,
      };
  
      this.restService.post(API_KEYPOINT.folder.isZipExist, params).subscribe({
        next: (data: any) => {
          if (data.code === 200) {
            const isZipAlreadyPresent = data.result.present;
            const isZipProcessing = data.result.processing;
  
            if (isZipAlreadyPresent && isZipProcessing) {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning!',
                detail: "Zip upload is currently in progress!",
              });
              this.totalAssetSize -= zipFileSize;
              this.count.Total--;
              validFiles.delete(key); // Remove the file since it's being processed
            } else if (isZipAlreadyPresent) {
              CONFIRMATION_POPUP_CONFIG.TITLE = "Folder already exists. Do you want to upload it again?";
              CONFIRMATION_POPUP_CONFIG.SUBTITLE = "";
              this.confirmationModal = true;
            }
          }
        },
        error: (err) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
        }
      });
    });
  
    this.selectedFilesList = validFiles; // Update the list with valid files
  }
  

  //----------------------------Function to upload multiple Files----------------------
  async uploadFiles() {
    this.isUploadClicked = true;
    this.isSaveDisabled = true;
    this.message = [];
  
    if (!this.selectedFilesList || this.selectedFilesList.size === 0) {
      this.message.push('File Required');
      return;
    }
  
    const batchSize = 25; // Set batch size to 25
    const limit = pLimit(batchSize); // Limit concurrency to 25 uploads
  
    let i = 0;
  
    // Function to upload a single file and handle errors
    const uploadFile = async (file: any, index: any, data: any) => {
      try {
        if (this.uploadDoneWithError && file.status === 3) {
          this.count.Failed--;
          file.status = 1;  // Reset to uploading
          return await this.upload(file, data);
        } else if (file.status === 0) {
          file.status = 1;  // Set to uploading
          return await this.upload(file, data);
        }
      } catch (error) {
        console.error(error);
        file.status = 3;
        file.message = 'Error during upload';
        this.count.Failed++;
      }
      return null;
    };
  
    // Loop through the map to upload files in batches
    const mapIterator = this.selectedFilesList.values(); // Get an iterator for the file objects
  
    while (i < this.selectedFilesList.size) {
      const batch: any[] = [];
      let count = 0;
      let assetParams: any[] = [];
  
      while (count < batchSize && i < this.selectedFilesList.size) {
        const file = mapIterator.next().value;
        if (file && file.file != null && file.status != 2) {
          batch.push(file);
          const params = {
            assetName: file.file.name,
            assetPath:  this.modalType === 'zip'? "uploadZip" : this.assetPathofFolder,
            mimetype: file.file.type,
            assetSize: file.file.size
          };
          assetParams.push(params);
          count++;
        }
        i++;
      }
      const uploadType = this.modalType === 'zip' ? ASSET_TYPE_CONFIG.ZIP_UPLOAD : ASSET_TYPE_CONFIG.NORMAL_UPLOAD;
      let response: any;
      try {
        response = await this.s3UploadService.getPreSignedUrl(uploadType, this.childFolderId, assetParams);
      } catch (err: any) {
        // Handle the error 
        console.error('Error fetching pre-signed URLs:', err);
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: err.error.message || "Something Went Wrong!",
        });

        batch.forEach((file) => {
          if(file.status != 3){
            this.count.Failed++;
            file.status = 3;
            file.message = 3;
          }
        });
        continue; // Skip this batch due to pre-signed URL error
      }

      // Upload each file in the batch
      const uploadBatchPromises = batch.map((file, index) =>
        limit(() => uploadFile(file, i + index, response))
      );
  
      await Promise.all(uploadBatchPromises); // Wait for the current batch to finish
    }
  
    // Sort files in selectedFilesList based on status after all uploads
    this.selectedFilesList = new Map(
      [...this.selectedFilesList.entries()].sort((a, b) => {
        if (a[1].status === 3 && b[1].status !== 3) {
          return -1;  // a should come first
        } else if (a[1].status !== 3 && b[1].status === 3) {
          return 1;   // b should come first
        } else {
          return a[1].status - b[1].status;  // Otherwise, sort by status
        }
      })
    );
  
    if (this.checkForFileWithServerError()) {
      this.uploadDoneWithError = true;
      this.isUploadClicked = false;
    } else {
      this.uploadDone = true;
      this.uploadDoneWithError = false;
    }
  }
  
  
  //----------------------------Single Asset Uplaod Function---------------------------------
  async upload(file: any, data: any) {
    return new Promise(async (resolve) => {
      const asset = file.file;
      if (file) {
        try {
          let event = await this.s3UploadService.uploadFile(asset, file, data);
          if (event.code == 200) {
            const data = event.result;
            if (data[asset.name].status == true) {
              file.status = 2;
              file.data = data[asset.name].data;
              this.count.Success++;
              file.file = { size: asset.size };
              if (this.modalType === 'zip') {
                this.zipUpload(data[asset.name].data);
              }
              resolve((this.success = true));
            } else if (data[asset.name].error == "Asset already present") {
              file.status = 3;
              file.message = 1;
              this.count.Failed++;
              resolve(false);
            }
            else if (data[asset.name].error == "Unsupported file format") {
              file.status = 3;
              file.message = 2;
              this.count.Failed++;
              resolve(false);
            }
            else{
              file.status = 3;
              file.message = 4;
              this.count.Failed++;
              resolve(false);
            }
          } else if (event.error) {
            file.status = 3;
            this.handleUploadError(event, file);
            this.count.Failed++;
            resolve(false);
          } else {
            file.status = 3;
            file.message = 3;
            this.handleUnknownError(file);
            this.count.Failed++;
            resolve(false);
          }

          file.progress = -1;
        } catch (error) {
          console.error(error);
          file.status = 3;
          file.message = 3;
          file.progress = -1;
          this.count.Failed++;
          this.handleUnknownError(file);
          resolve(false);
        }
      }
    });
  }

  handleUploadError(event: any, file: any) {
    const { code, message } = event.error;

    if (code === 400) {
      file.message = 2;
    } else if (code === 500) {
      file.message = 3;
    } else {
      const msg =
        message === 'Request Entity Too Large.'
          ? 'Please do not upload over 10 files at once.'
          : 'Something went wrong. Could not upload the file(s), please check your internet connection and try uploading again';

      file.message = 3;
      this.message.push(msg);
    }
  }

  handleUnknownError(file: any) {
    const msg =
      'Something went wrong. Could not upload the file(s), please check your internet connection and try uploading again';
    this.message.push(msg);
  }

  async saveFiles() {
    const uploadLimit = 100;  // Batch size of 100
    let totalAssets = 0;
    const saveFiles: any = [];
    let errorDisplayed = false; // Flag to track if error message has been shown

    // Preparing payload
    for (const [key, value] of this.selectedFilesList.entries()) {
      if (value?.status == 2) {
        saveFiles.push(value?.data);
        totalAssets++;
      }
    }

    const processBatch = (batch: any[]) => {
      const params = {
        details: JSON.stringify(batch)
      };
  
      return this.restService.post(API_ENDPOINT.baseEndpoint + this.userDetails.clientId + '/' + API_ENDPOINT.assets.saveUploads, params).pipe(
        map((response: any) => {
          // If successful, return the response
          return response;
        }),
        catchError((err: any) => {
          // Check if error is code 504 and the error message has not already been displayed
          if (!errorDisplayed) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: err.error.message || "Something Went Wrong !",
            });
            errorDisplayed = true; // Set flag to true after first error
          }
          // Return `null` to indicate a failed API call
          return of(null);
        })
      );
    };
  
    const batches: any = [];
    for (let i = 0; i < totalAssets; i += uploadLimit) {
      batches.push(saveFiles.slice(i, i + uploadLimit));
    }
  
    // Use `from` and `mergeMap` to process batches with limited concurrency
    from(batches).pipe(
      mergeMap((batch: any) => processBatch(batch), 4), // Limit to 4 concurrent API calls
      toArray() // Collect all results into an array
    ).subscribe({
      next: (results: any) => {
        // Check if any result is null (failed API calls)
        const allSuccessful = results.every((result: any) => result !== null);
  
        if (allSuccessful) {
          // All batches succeeded
          this.handleBatchResponse(results[0], batches[0][0].folderId, totalAssets);
        } else {
          // At least one batch failed
          if (!errorDisplayed) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error!',
              detail: 'Some files failed to upload.',
            });
          }
          this.closeModalEvent.emit(false);
        }
      },
      error: () => {
        // Handle any unexpected error
        if (!errorDisplayed) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error!',
            detail: 'Some files failed to upload.',
          });
        }
        this.closeModalEvent.emit(false);
      }
    });
  }
  
  
  
  // Extract the common logic to a separate function for reusability
  handleBatchResponse(data: any, folderId: any, total : any) {
    this.uploadDone = false;
    this.isModalClose = false;
    this.message = [];
    this.isModalCopy = false;
    let params = {
      assetUploadCount: total,
      folderId: folderId,
    };
    this.closeModalEvent.emit(params);
    this.selectedFilesList.clear();
    if (data.code == 200) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success!',
        detail: 'Asset(s) uploaded successfully!',
      });
    }
  }

  zipUpload(data: any) {
    let fileName: any;

    for (const [key, value] of this.selectedFilesList.entries()) {
      fileName = key;
    }

    const params = {
      parentFolderId: `${this.childFolderId}`,
      folderPath: this.assetPathofFolder + '/',
      zipFolderName: fileName,
      zipFolderPath: data.assetPath
    }

    if (data) {
      this.subscriptions.push(
        this.restService.post(API_ENDPOINT.baseEndpoint + this.userDetails.clientId + '/' + API_ENDPOINT.folder.uploadZipFolder, params).subscribe({
          next: (event: any) => {
              this.handleSuccess(event.result.folderId, event.result.isAssetSkipped);
            },
          error: (err) => {
            this.handleError(err);
          }
        })
      );
    }
  }
  
  private handleSuccess(folderId: string, isAssetSkipped: boolean) {
    setTimeout(() => {
      this.isModalClose = false;
      this.closeModalEvent.emit({ success: true, folderId });
      this.folderMgmtUtils.setOpenQueueTrigger(true);
      if (isAssetSkipped) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: 'Uploaded successfully! However, some files were skipped due to unsupported formats.',
        });
      } else {
        this.messageService.add({
          severity: 'success',
          summary: 'Success!',
          detail: 'Zip file is being extracted. Please wait for a while...',
        });
      }
    }, 500);
  }
  
  private handleError(err: any) {
    this.deleteAssets();
    this.closeModalEvent.emit(false);
  
    let msg = 'Something went wrong. Could not upload the file(s), please check your internet connection and try uploading again';
    if (err.error.message === 'Request Entity Too Large.') {
      msg = 'Please do not upload files over 1 GB at once.';
    } else if (err.error.message === 'Cannot upload empty ZIP file.') {
      msg = 'Cannot upload an empty ZIP file.';
    }
    else{
      msg = err.error.message || "Something went wrong!"
    }
  
    this.message.push(msg);
    this.messageService.add({
      severity:'warn',
      summary: 'Warning!',
      detail: msg,
    });
  
    setTimeout(() => {
      this.isModalClose = false;
    }, 1000);
  }
  

  reset() {
    this.fileInput.nativeElement.value = '';
  }
  //================= delete selected file for media uploader============
  removeImage(fileName: any, media: any) {
    // Remove the last message if exists
    this.message.pop();

    // Update total asset size
    this.totalAssetSize -= media.file.size;

    const isZip = fileName.endsWith('.zip');

    if (isZip) {
        // Unsubscribe from all subscriptions and reset the modal state for zip files
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.resetUploadState();
    } else if (media.status === 2) {
        // If the media has status 2, delete the asset
        this.deleteAssets(media);
    }

    // Update counters based on media status
    this.updateCount(media);

    // Mark the file as deleted (status 4) and remove the file reference
    this.offloadMedia(media);

    // Check if upload is complete
    this.checkUploadCompletion();
}

resetUploadState() {
    this.uploadDone = false;
    this.success = false;
    this.message = [];
    this.isModalCopy = false;
}

updateCount(media: any) {
    if (media.status === 2) {
        this.count.Success--;
    } else if (media.status === 3) {
        this.count.Failed--;
    }
    this.count.Total--;
}

offloadMedia(media: any) {
    media.status = 4;
    media.file = null; // Offloading file to reduce memory usage
}

checkUploadCompletion() {
    // Check if all files have either succeeded or failed
    if (this.count.Total > 0  && this.count.Total === this.count.Success + this.count.Failed) {
        this.uploadDone = true;
    }

    // If no files are left, reset the upload state completely
    if (this.count.Total === 0) {
        this.uploadDone = false;
        this.uploadDoneWithError = false;
        this.isUploadClicked = false;
        this.success = false;
        this.message = [];
        this.isModalCopy = false;
    }
    
    this.reset();
}

  //=====================delete Assets after cancel===========================================

  deleteAssets(asset? : any) {
    let assetDetail : any = [];
    // preparing payload
    if(asset){
      assetDetail.push(asset.data);
    }
    else{
      for (const [key, value] of this.selectedFilesList.entries()) {
        const value = this.selectedFilesList.get(key);
        if(value?.status == 2){
          assetDetail.push(value?.data);
        }
      }
    }

    let params = {
      objects: assetDetail.map((item : any) => ({
          assetPath: item.assetPath,
          assetName: item.assetName,
          assetType: item.assetType
      }))
  };
    if(params.objects.length>0) 
      this.restService.post(API_ENDPOINT.baseEndpoint + 
                            this.userDetails.clientId + '/' +
                            API_ENDPOINT.assets.clearStorage, 
                            params).subscribe(() => {});
  }

  //================= cancel btn click while assets upload============
  emptyFileList() {
    if (this.uploadDone == true) {
      this.deleteAssets();
    }
    this.uploadDone = false;
    this.success = false;
    this.closeModalEvent.emit(false);
    this.isModalClose = false;
    this.message = [];
    this.isModalCopy = false;
  }

  replaceName(name: any) {
    return (
      name.split(/\.(?=[^\.]+$)/)[0].replace(/[^A-Z0-9]+/gi, '_') +
      '.' +
      name.split(/\.(?=[^\.]+$)/)[1]
    );
  }

  async singleRetry(file: any) {
    this.isUploadClicked = true;
    this.isSaveDisabled = true;
    this.message.pop();

    if (file.status == 3 && file.message == 3) {
      const uploadType = this.modalType === 'zip' ? ASSET_TYPE_CONFIG.ZIP_UPLOAD : ASSET_TYPE_CONFIG.NORMAL_UPLOAD;
      const assetParams: any[] = [{ 
        assetName: file.file.name,
        assetPath: this.modalType === 'zip' ? "uploadZip" : this.assetPathofFolder,
        mimetype: file.file.type,
        assetSize: file.file.size
      }]
      let response: any;
      try {
        response = await this.s3UploadService.getPreSignedUrl(uploadType, this.childFolderId, assetParams);
      } catch (err: any) {
        // Handle the error 
        console.error('Error fetching pre-signed URLs:', err);
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: err.error.message || "Something Went Wrong!",
        });
      }
      if(response.result[file.file.name].status == true){
        file.status = 1;
        file.message = 0;
        this.count.Failed--;
        await this.upload(file, response);
      }
    }
      if (this.checkForFileWithServerError()) {
        this.uploadDoneWithError = true;
        this.isUploadClicked = false;
      } else {
        this.uploadDone = true;
        this.uploadDoneWithError = false;
      }
    }

    allowMultipleSelection() {
      if (this.modalType == 'zip') {
        return false;
      } else {
        return 'allow';
      }
    }

    selectedFilesArray() {
      return Array.from(this.selectedFilesList.entries())
        .filter(([key, value]) => value.status !== 4)
        .map(([key, _value]) => key); // Return only the key
    }

    checkForFileWithServerError(){
      const hasFileWithServerError = Array.from(this.selectedFilesList.values()).some(file => file.status === 3 && file.message === 3);

      return hasFileWithServerError;

    }

    // Function to handle browser/tab close event
    handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Optionally prompt user before closing the tab
      event.preventDefault();
    }

    handleUnload = (event: Event) => {
      this.deleteAssets();
    }

    clearAsset(){
      const media: any = this.selectedFilesList.values().next().value; // Get the single file from the map
      this.removeImage(media.file.name, media)
      this.confirmationModal = false;
    }

    ngOnDestroy(){
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
      window.removeEventListener('unload', this.handleUnload);
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }
