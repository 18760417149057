import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'slide-range',
    templateUrl: './slider-range.component.html',
    styleUrls: ['./slider-range.component.scss'],
    standalone: true,
    imports: [DecimalPipe]
})
export class SliderRangeComponent {

  @Input('min') min:number = 0;
  @Input('max') max:number = 100;
  @Input('step') step:number = 1;

  @Input('valueMin') valueMin:any = 10;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input('valueMax') valueMax:any = 90;

  slide1 = 0;
  slide2:any;
  maxslide: any;

  @ViewChildren('rangeSlider') sliders: QueryList<ElementRef<HTMLInputElement>>; // Access all sliders with a template reference

  
  constructor() {}
  
  ngOnInit(){
    this.slide2 = this.max;
  }

  ngAfterViewInit(): void {
      // using SetTimeOut() to delay the change detection this will be detected on next browser micro task.
      setTimeout(() => {
        // Update maxslide
        this.maxslide = this.valueMax;
  
        // Access slider elements
        this.sliders.forEach((sliderRef: ElementRef<HTMLInputElement>, index: number) => {
          const slider = sliderRef.nativeElement;
  
          // Assuming that the second slider is the one with the max value
          if (index === 1) {
            this.slide2 = Math.floor(parseFloat(slider.max)).toString();
          }
  
          // Add event listener for 'input' event
          slider.addEventListener('input', () => this.getVals(slider));
        });
      }, 0);
  }
  
  getVals(self: any) {
    // Get slider values
    const parent = self.parentNode as HTMLElement;
    const slides = parent.getElementsByTagName("input");
  
    // Parse slider values as floats
    const slide1 = parseFloat(slides[0].value);
    const slide2 = parseFloat(slides[1].value);
  
    // Ensure slide1 is the smaller value
    const [minValue, maxValue] = [Math.min(slide1, slide2), Math.max(slide1, slide2)];
    // Emit min and max values
    this.valueChange.emit({
      type: 'rangeValues',
      data: {
        minValue: minValue,
        maxValue: maxValue,
      },
    });

    // Update class properties
    this.slide1 = minValue;
    this.slide2 = maxValue.toString();
  }
  
  //to get changes from the slider
  doChangeOnSlider(event:any)
  {
    this.getVals(event);
  }
}
