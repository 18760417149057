import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import {
  API_ENDPOINT,
  CONFIRMATION_POPUP_CONFIG,
} from 'src/app/core/constants';
import { RestService } from 'src/app/core/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { DialogModule } from 'primeng/dialog';
import { ConfirmationPopUpComponent } from '../deletepopup/confirmationPopUp.component';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-add-to-collection',
    templateUrl: './add-to-collection.component.html',
    styleUrls: ['./add-to-collection.component.scss'],
    standalone: true,
    imports: [
        DialogModule,
        FormsModule,
        ReactiveFormsModule,
        ConfirmationPopUpComponent,
        AsyncPipe,
    ],
})
export class AddToCollectionComponent {
  isDeleteCollectionModal: Boolean = false;
  isDeleteModalShow: Boolean = false;
  isSingleCollection: Boolean = false;
  isOpenCollectionModal: boolean = false;
  collectionListForm: any = '';
  isCreateModalShow: boolean = false;
  createCollectionFrom: any = '';
  removeData =
    'Are you sure you want to remove the asset(s) from the collection?';
  collectionArr: any = [];
  totalText = 100;
  private collectionListSubject = new BehaviorSubject<any>([]);
  collectionList$ = this.collectionListSubject.asObservable();
  assetArr: any = [];
  flattenedItem: any;
  @Input() singleCollectionDetails: any;
  @Input() type: any;
  @Input() eventtocollection: any;
  @Input() assetDetails: any;
  selectedItems: any;
  @Output() closeCollectionModal = new EventEmitter<any>();

  constructor(
    public authService: AuthService,
    private messageService: MessageService,
    private restService: RestService
  ) {
    this.collectionListForm = new FormGroup({
      require: new FormControl('allRecords'),
      search: new FormControl(''),
      assetType: new FormControl(''),
      sort: new FormControl({ '': 'DESC' }),
    });
    /* Add Reactive Form for create collection */
    this.createCollectionFrom = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(2),
        Validators.pattern('^[A-Za-z0-9_ -]+$'),
      ]),
    });
  }

  ngOnInit() {
    // Initialize selectedItems based on singleCollectionDetails
    if (this.singleCollectionDetails && this.singleCollectionDetails._source) {
      const { _source, _index, _id, id, _score, sort, isSelected } =
        this.singleCollectionDetails;
      this.selectedItems = {
        ..._source,
        _index,
        _id,
        id,
        _score,
        sort,
        isSelected,
      };
    } else {
      this.selectedItems = { ...this.singleCollectionDetails };
    }

    // Assign _id if missing
    if (!this.selectedItems._id) {
      this.selectedItems['_id'] =
        this.selectedItems['assetId'] || this.selectedItems['id'];
      delete this.selectedItems['assetId'];
      delete this.selectedItems['id'];
    }

    // Handle modal states based on eventtocollection
    if (this.eventtocollection === 'single') {
      if (this.selectedItems.collectionId) {
        this.isDeleteModalShow = true;
        this.isDeleteCollectionModal = true;
        CONFIRMATION_POPUP_CONFIG.TITLE = this.removeData;
        CONFIRMATION_POPUP_CONFIG.SUBTITLE = '';
      } else {
        this.isSingleCollection = true;
        this.getCollectionList();
      }
    } else {
      this.isSingleCollection = false;
      this.getCollectionList();
    }

    if (this.type === 'create') {
      this.isCreateModalShow = true;
    } else if (this.type === 'add' && !this.isDeleteModalShow) {
      this.isOpenCollectionModal = true;
    }
  }

  getCollectionList() {
    this.restService.get(
      API_ENDPOINT.baseEndpoint + this.authService.getAuthData()?.clientId + "/" + API_ENDPOINT.collections.collections, {
        includeAssets: false
        })
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            this.collectionListSubject.next(data.result);
          }
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!!!',
            detail: error.error.message,
          });
          this.collectionListSubject.next(null);
        },
      });
  }
  closeToCollectionModal() {
    this.closeCollectionModal.emit(false);
  }

  // openCollectionModal(asset: any, event: any) {
  //   //document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  // }

  resetInput() {
    this.createCollectionFrom.reset();
  }

  afterReassignAssetsToACollection() {
    this.assetArr = [];
    this.collectionArr = [];
    this.isSingleCollection = false;
    this.isOpenCollectionModal = true;
  }

  async saveToCollection(data: any) {
    let multiAssetList = [];
    multiAssetList = [];
    this.collectionArr.push(data.id);
    if (this.isSingleCollection && this.selectedItems.collectionId) {
      this.openDeleteCollectionModal();
    }
    if (this.isSingleCollection && !this.selectedItems.collectionId) {
      this.assetArr.push({
        assetId: this.selectedItems?._id,
        folderId: this.selectedItems.folderId,
      });
      this.restService
        .post(API_ENDPOINT.baseEndpoint + this.authService.getAuthData()?.clientId + "/" + API_ENDPOINT.collections.collections + "/" + data?.id +
          "/" +API_ENDPOINT.collections.addAsset, { assetArr: this.assetArr })
        .subscribe({
          next: async (data: any) => {
            if (data.code == 200) {
              // this.router.navigate(['/main/collections']);
              this.messageService.add({
                severity: 'success',
                summary: 'Success!',
                detail: 'Asset(s) saved to Collection successfully!',
              });
              this.assetArr = [];
              this.collectionArr = [];
              this.isSingleCollection = false;
              this.isOpenCollectionModal = false;
              this.closeCollectionModal.emit(true);
            }
          },
          error: (error) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message,
            });
            this.assetArr = [];
            this.collectionArr = [];
          },
        });
    } else {
      // loop to remove source
      for (const item of this.assetDetails) {
        if (item._source) {
          this.flattenedItem = {
            ...item._source,
            _index: item._index,
            _id: item._id,
            id: item.id,
            _score: item._score,
            sort: item.sort,
            isSelected: item.isSelected,
          };
          multiAssetList.push(this.flattenedItem);
        } else {
          multiAssetList.push(item);
        }
      }

      //to key in _id format for redirection
      // if (!multiAssetList._id) {
      for (const element of multiAssetList) {
        if (element['assetId']) {
          element['_id'] = element['assetId'];
          //delete element['assetId'];
        }
        if (element['id']) {
          element['_id'] = element['id'];
          //delete element['id'];
        }
        // }
      }

      multiAssetList.forEach((asset: any) => {
        if (asset.isAssetSelected == true || asset.isSelected == true) {
          if (asset.collectionId) {
            this.assetArr.push({
              assetId: asset._id,
              collectionId: asset.collectionId,
              folderId: asset.folderId,
            });
          } else {
            this.assetArr.push({
              assetId: asset._id,
              folderId: asset.folderId,
            });
          }
        }
      });
      this.restService
        .post(API_ENDPOINT.baseEndpoint + this.authService.getAuthData()?.clientId + "/" + API_ENDPOINT.collections.collections + "/" + data?.id +
        "/" +API_ENDPOINT.collections.addAsset, { assetArr: this.assetArr })
        .subscribe({
          next: async (data: any) => {
            if (data.code == 200) {
              // this.router.navigate(['/main/collections']);
              this.messageService.add({
                severity: 'success',
                summary: 'Success!',
                detail: 'Asset(s) saved to Collection successfully!',
              });
              this.assetArr = [];
              this.collectionArr = [];
              this.isOpenCollectionModal = false;
              this.closeCollectionModal.emit(true);
            }
          },
          error: (error) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message,
            });
            this.assetArr = [];
            this.collectionArr = [];
          },
        });
    }
  }

  /* open modal for delete collection confirmation modal  */
  openDeleteCollectionModal() {
    this.isDeleteModalShow = true;
    this.isDeleteCollectionModal = true;
    CONFIRMATION_POPUP_CONFIG.TITLE = this.removeData;
    CONFIRMATION_POPUP_CONFIG.SUBTITLE = '';
  }

  /* close modal for create collection  */
  onCancel() {
    this.createCollectionFrom.reset();
    this.isCreateModalShow = false;
    this.closeCollectionModal.emit(false);
  }

  /* API integration for create collection  */
  async submitFormData(createCollectionFrom: any) {
    this.createCollectionFrom.value.name = this.createCollectionFrom.value.name
      .trim()
      .replace(/\s+/g, ' ');
    if (this.createCollectionFrom.valid) {
      const queryParams = {
        name: this.createCollectionFrom.value.name,
      };
      this.restService
        .post(API_ENDPOINT.baseEndpoint + this.authService.getAuthData()?.clientId + "/" + API_ENDPOINT.collections.collections, queryParams)
        .subscribe({
          next: async (data: any) => {
            if (data.code == 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'Success!',
                detail: 'Collection created successfully',
              });
              this.isCreateModalShow = false;
              this.getCollectionList();
              if (this.type === 'create') {
                this.closeCollectionModal.emit(true);
              }
              this.createCollectionFrom.reset();
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning!',
                detail: 'Something went wrong please try later',
              });
            }
          },
          error: (error) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message || 'The input value is invalid',
            });
            this.createCollectionFrom.reset();
          },
        });
    } else {
      setTimeout(function () {
        if (document.querySelector('.required')) {
          document.querySelector('required')?.scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
  }

  openToCreateCollection() {
    this.isCreateModalShow = true;
  }

  closedeleteModal() {
    this.isDeleteModalShow = false;
    this.isDeleteCollectionModal = false;
    this.closeCollectionModal.emit(false);
  }

  /* delete collection API integration  */
  deleteCollectionFromAsset() {
    this.assetArr = [];
    this.assetArr.push({
      assetId: this.selectedItems._id,
    });
    const param: any = {
      assetArr: this.assetArr,
    };
    this.restService
      .post(API_ENDPOINT.baseEndpoint + this.authService.getAuthData()?.clientId + "/" + API_ENDPOINT.collections.collections + "/" +
        this.selectedItems.collectionId + "/" + API_ENDPOINT.collections.delete, param)
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            this.closeCollectionModal.emit(true);

            this.isDeleteModalShow = false;
            this.isDeleteCollectionModal = false;
            this.assetArr = [];
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Selected asset removed from the collection',
            });
          }
        },
        error: (error: any) => {
          this.assetArr = [];
          this.isDeleteModalShow = false;
          this.isDeleteCollectionModal = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
  }
}
