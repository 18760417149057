import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  AppDataService,
  PermissionsEngine,
  RestService,
} from 'src/app/core/services';
import { MessageService } from 'primeng/api';
import {
  FormGroup,
  FormControl,
  Validators,
  NgForm,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/core/services';
import { IFolderDetails } from 'src/app/common/utils/folderMgmtUtill';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  ACCESS_TYPE_FILTERS,
  API_ENDPOINT,
  API_KEYPOINT,
} from 'src/app/core/constants';
import { Subscription } from 'rxjs';
import { DialogModule } from 'primeng/dialog';
import { NgClass } from '@angular/common';
import { CommonUtils } from 'src/app/common/utils/common.utils';
import { DropdownModule } from 'primeng/dropdown';

//Interfaces for creating root folder
interface IFolder {
  folderId: Number;
  parentId: Number;
  folderName: String;
  folderAccess: Array<IFolderAccess>;
}

interface IFolderAccess {
  assignedTo: Number;
  roleId: Number;
  accessType: Number;
} //IFolderAccess

interface IAccessType {
  id: Number;
  name: String;
}

interface Iuser {
  userId: Number;
  roleId: Number;
  firstName: String;
  lastName: String;
  roleName: String;
  email: String;
  contactNumber: String;
  active: Boolean;
  profession: String;
  disabled: Boolean;
}

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss'],
  standalone: true,
  imports: [DialogModule, FormsModule, ReactiveFormsModule, NgClass, DropdownModule],
})
export class CreateFolderComponent {
  firstItem: IFolderDetails = {
    firstName: 'First',
    lastName: 'Folder',
    userId: 1,
    folderCount: '',
    assignedCount: '',
    parentId: 0,
    assetCounts: '',
    untaggedAssetsCount: '',
    roleId: 1,
    folderName: '',
    folderId: 0,
    isTaged: 0,
    createdAt: '',
    createByFirstname: '',
    createByLastname: '',
    accessType: 2,
    isCollapse: false,
    isFolderRightClick: false,
    children: [],
  };
  isCreateModalShow: boolean = false;
  creareSubfolderForm: any;
  isRootCreateFolderModalShow: boolean = false;
  handleButtonClicked: boolean = false;
  folderAccessList: any = [];
  parentFolderList: any = [];
  userDetails: any;
  isDisabled: boolean = false;
  accessTypeList = ACCESS_TYPE_FILTERS;
  userList: Array<Iuser> = [];
  folder: IFolder = {
    folderId: 0,
    folderName: '',
    parentId: 0,
    folderAccess: new Array<IFolderAccess>()
  };
  @Input() childFolderId: any;
  @Input() folderDetails: any;
  @Input() objectForSourceTrack: any;
  @Input() folderType: any;
  @Output() onFolderRefresh = new EventEmitter();
  isEditMode: any = 'add';
  private subscription: Subscription;
  originalValue: any;
  isFormDirty: boolean = false;
  commonUtils = new CommonUtils();

  constructor(
    public permissionsEngine: PermissionsEngine,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public appService: AppService,
    public authService: AuthService,
    private restService: RestService,
    private AppDataService: AppDataService
  ) {
    this.userDetails = this.authService.getAuthData();
    this.creareSubfolderForm = new FormGroup({
      folderName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern('^[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$'),
      ]),
      parentId: new FormControl(''),
      folderId: new FormControl(0),
      folderAccess: new FormControl(this.folderAccessList),
    });
  }

  ngOnChanges() {
    switch (this.folderType) {
      case 'sub':
        this.isCreateModalShow = true;
        this.getParentFolderAccess(this.objectForSourceTrack.folderId);
        break;
      case 'root':
        this.isRootCreateFolderModalShow = true;
        this.openRootCreateFolderModal();
        break;
    }
  }
  ngOnInit() {
    this.subscription = this.AppDataService.currentfolderDataCreation.subscribe(
      (param) => {
        if (param && param.type) {
          this.isEditMode = param.type;
          if (this.isEditMode === 'edit' && param.data?.folderId) {
            this.folder.folderId = param.data.folderId;
          }
          this.openRootCreateFolderModal();
        }
      }
    );
  }
  async callInitItem(folderId?: any) {
    this.restService
      .get(
        API_ENDPOINT.baseEndpoint +
          this.userDetails.clientId +
          '/folder/' +
          folderId +
          '/access'
      )
      .subscribe((data: any) => {
        const iFolderKeys = Object.keys(this.folder) as (keyof IFolder)[];
        this.folder = {
          ...this.folder,
          ...this.commonUtils.pickKeys(data.result, iFolderKeys),
        };
        this.folder.folderAccess = this.folder.folderAccess.filter(
          (item: any) => item.roleId !== 1 && item.roleId !== 2
        );
        this.userList.forEach((obj: any) => {
          this.folder.folderAccess.forEach((fobj: any) => {
            if (obj.userId == fobj.assignedTo) {
              obj.disabled = true;
            }
          });
        });
      });
    this.originalValue = { ...this.folder };
  }

  getAllUserList() {
    this.restService
      .get(API_KEYPOINT.user.getUserList)
      .subscribe((data: any) => {
        this.userList = data.result;
        this.userList.forEach((obj: any) => {
          // TaskId-> 165541 if user is admin or superadmin then that user will be disabled and while creating new folder , that folder will be assigned to itself with all-access by default
          obj.disabled =
            (this.userDetails.roleId === 1 ||
              this.userDetails.roleId === 2 ||
              this.userDetails.roleId === 4 ||
              this.userDetails.roleId === 6) &&
            obj.userId === this.userDetails.userId;
        });
        this.userList = this.userList.filter(
          (user) => user.roleId != 1 && user.roleId != 2
        );
        this.userList = this.userList.map(user => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName} [${user.email}]`,
          disabled: false
        }));
      });
    if (this.isEditMode === 'edit') {
      this.callInitItem(this.folder.folderId);
    }
  }

  //============= Open create folder modal  =========//
  openCreateModal() {
    this.isCreateModalShow = true;
    this.getParentFolderAccess(this.objectForSourceTrack.folderId);
  }
  //============= close create folder modal  =========//
  closeCreateModal() {
    this.isCreateModalShow = false;
    let params = { fileDetails: this.objectForSourceTrack ,cancelsub: true };
    this.onFolderRefresh.emit(params);
    this.creareSubfolderForm.reset();
  }
  closeRootCreateModal(frmFolder: any) {
    frmFolder.resetForm();
    this.isRootCreateFolderModalShow = false;
    this.isFormDirty = false;
    //setting the isDisabled property to false for the userList Array
    this.userList.forEach((item: any) => {
      item.isDisabled = false;
    });
    this.folder.folderAccess = [{} as IFolderAccess];
    this.AppDataService.changeFolderCreation({});
    let params = { fileDetails: this.objectForSourceTrack, cancelRoot: true };
    this.onFolderRefresh.emit(params);
  }
  //============= create folder api  =========//
  createSubfolder(frmFolder: any) {
    this.handleButtonClicked = true;
    this.creareSubfolderForm.value.folderName =
      this.creareSubfolderForm.value.folderName.trim().replace(/\s+/g, ' ');
    this.creareSubfolderForm.value.parentId =
      this.objectForSourceTrack.folderId;
    this.parentFolderList.filter((el: any) => {
      this.folderAccessList.push({
        assignedTo: el.assignedTo,
        roleId: el.roleId,
        accessType: el.accessType,
      });
    });
    this.creareSubfolderForm.value.folderAccess = this.folderAccessList;
    let endpoint =
      'client/' +
      this.userDetails.clientId +
      '/' +
      API_ENDPOINT.folder.folderCreate;
    if (frmFolder.valid) {
      this.restService
        .post(endpoint, this.creareSubfolderForm.value)
        .subscribe({
          next: (data: any) => {
            if (data.code === 200) {
              this.isCreateModalShow = false;
              this.creareSubfolderForm.reset();
              if (this.objectForSourceTrack.folderId === 0) {
                let params = {
                  fileDetails: this.objectForSourceTrack,
                  createroot: 'root',
                };
                this.onFolderRefresh.emit(params);
              } else {
                let params = {
                  fileDetails: this.objectForSourceTrack,
                  create: 'create',
                };
                this.onFolderRefresh.emit(params);
              }
              this.messageService.add({
                severity: 'success',
                summary: 'Success!',
                detail: data.message,
              });
              this.handleButtonClicked = false;
            }
          },
          error: (err: any) => {
            this.isCreateModalShow = false;
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: err.error.message,
            });
            this.handleButtonClicked = false;
            let params = {
              fileDetails: this.objectForSourceTrack,
              cancelRoot: true,
            };
            this.onFolderRefresh.emit(params);
          },
        });
      this.folderAccessList = [];
    } else {
      setTimeout(function () {
        if (document.querySelector('.required')) {
          document.querySelector('required')?.scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
  }
  openRootCreateFolderModal() {
    this.getAllUserList();
    this.folder.folderAccess = [{} as IFolderAccess];
    //If admin or super admin is creating the rootfolder then that folder will be by default assigned to itself. TaskId-> 165541
    if (
      this.userDetails.roleId == 1 ||
      this.userDetails.roleId == 2 ||
      this.userDetails.roleId == 6
    ) {
      if (this.folder.folderAccess.length === 1) {
        this.folder.folderAccess[0] = {
          assignedTo: this.userDetails.userId,
          roleId: this.userDetails.roleId,
          accessType: 4,
        };
      }
    }
    //User Story 176108: Hardcoded Admin should be able to see all folders that are being created in the system
    //& should be able to perform read-write-upload-delete operations on all visible folders
    //added this condition to give self RWU access by default if the user is  media validator
    if (this.userDetails.roleId == 4) {
      //media validator role id  =  4
      if (this.folder.folderAccess.length === 1) {
        this.folder.folderAccess[0] = {
          assignedTo: this.userDetails.userId,
          roleId: this.userDetails.roleId,
          accessType: 3,
        }; //accessType :  3 RWU access
      }
    }
    this.isRootCreateFolderModalShow = true;
  }
  //=========call Folder access api =========//
  getParentFolderAccess(item: any) {
    let folderId = item.folderId ? item.folderId : item;
    if (folderId !== 0) {
      this.restService
        .get(
          API_ENDPOINT.baseEndpoint +
            this.userDetails.clientId +
            '/' +
            API_ENDPOINT.folder.getParentFolderAccess,
          {
            folderId: folderId,
          }
        )
        .subscribe((data: any) => {
          if (data.code === 200) {
            this.parentFolderList = data.result;
          }
        });
    }
  }
  //function to disable user when user user keyboard to chnage user
  onKeyDowns(event: FocusEvent, index: number) {
    this.userList.forEach((obj: any) => {
      if (
        obj.userId == this.folder.folderAccess[index].assignedTo &&
        event.isTrusted == true
      ) {
        // This condition will enable the previous user in the list when Enter key is pressed
        obj.disabled = true;
      }
    });
    event.preventDefault(); // Prevent navigation with arrow keys
  }

  removeUserOnfolderAccessList(index: number, frmFolder: NgForm) {
    this.userList.forEach((obj) => {
      if (obj.userId == this.folder.folderAccess[index].assignedTo) {
        obj.disabled = false;
      }
    });
    this.folder.folderAccess.splice(index, 1);
    this.onFormChange(frmFolder); // Mark form as dirty after removing an item
  } //removeUserOnfolderAccessList
  async addItem(frmFolder: NgForm) {
    this.folder.folderAccess.push({} as IFolderAccess);
    this.userList.forEach((obj) => {
      this.folder.folderAccess.forEach((fobj) => {
        if (obj.userId == fobj.assignedTo) {
          obj.disabled = true;
        }
      });
    });
    this.onFormChange(frmFolder); // Mark form as dirty after removing an item
  } //addItem
  submitFormDataToCreateFolder(frmFolder: NgForm) {
    this.userList.forEach((obj) => {
      this.folder.folderAccess.forEach((fobj) => {
        if (obj.userId == fobj.assignedTo) {
          fobj.roleId = obj.roleId;
        }
      });
    });
    this.folder.folderName = this.folder.folderName.trim().replace(/\s+/g, ' ');
    if (frmFolder.form.valid || this.isFormDirty) {
      const reqParams = this.folder;
      let queryCall = this.restService.post(
        'client/' +
          this.userDetails.clientId +
          '/' +
          API_ENDPOINT.folder.folderCreate,
        reqParams
      );
      let msg = '';
      queryCall.subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            if (this.isEditMode === 'edit') {
              msg = 'Folder edited successfully!';
            } else {
              msg = data.message;
            }
            let params = {
              fileDetails: this.objectForSourceTrack,
              createroot: 'root',
            };
            this.onFolderRefresh.emit(params);
            this.isRootCreateFolderModalShow = false;
            this.isFormDirty = false; // Reset dirty state after submission
            frmFolder.resetForm();
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: data.message,
            });
          }
        },
        error: (err) => {
          frmFolder.resetForm();
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
          this.isRootCreateFolderModalShow = false;
          let params = {
            fileDetails: this.objectForSourceTrack,
            cancelRoot: true,
          };
          this.onFolderRefresh.emit(params);
        },
      });
      //setting the isDisabled property to false for the userList Array
      this.userList.forEach((item: any) => {
        item.isDisabled = false;
      });
      this.folder.folderAccess = [{} as IFolderAccess];
      this.AppDataService.changeFolderCreation({});
    } else {
      setTimeout(function () {
        if (document.querySelector('.required')) {
          document.querySelector('required')?.scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
  } //submitFormData
  changeUser(event: any, index: number) {
    this.userList.forEach((obj: any) => {
      if (
        obj.userId == this.folder.folderAccess[index].assignedTo &&
        event.pointerId == 1
      ) {
        //This condition will enable the previous user in the list
        obj.disabled = false;
      } else if (
        obj.userId == this.folder.folderAccess[index].assignedTo &&
        event.pointerId == 0
      ) {
        //This will disable the new selected user in the list
        obj.disabled = true;
      }
    });
  } //changeUser
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.isRootCreateFolderModalShow = false;
    this.isCreateModalShow = false;
    this.isFormDirty = false;
  }
  // Called whenever form value changes
  onFormChange(frmFolder: NgForm) {
    this.isFormDirty =
      JSON.stringify(this.folder) !== JSON.stringify(this.originalValue);
    frmFolder.form.markAllAsTouched();
    frmFolder.form.updateValueAndValidity();
  }
}
