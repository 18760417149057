<p-dialog [modal]="true" [(visible)]="isModalClose" [breakpoints]="{'1030px': '850px', '991px': '90vw'}"
  [style]="{width: '1100px'}" [showHeader]="false" [resizable]="false" [closeOnEscape]="false">
  <div class="row">
    <div class="col-md-6">
      <div class="card drag-drop-panel radius-24 text-center h-100">
        <input [disabled]="count.Total > 0 && modalType==='zip' || (!uploadDone && isUploadClicked)"
        #fileInput class="btn bg-transparent w-100 drag-drop-file h-100" type="file"
        [multiple]="allowMultipleSelection()" ngFileDragDrop (change)="selectFiles($event)" title="" />
        <div class="card-body d-flex flex-column justify-content-center min-h-auto">
          <div class="p-2 mb-4">
            <img src="assets/images/icons/upload-asset.png" alt="" width="" height="">
          </div>
          <div class="upload-btn-wrapper w-100">
            <button [disabled]="count.Total > 0 && modalType==='zip' || (!uploadDone && isUploadClicked)"
            class="btn bg-transparent w-100"> Drag & Drop files to upload </button>
          </div>
          <div class="fs-24 my-3">
            Or
          </div>
          <div class="my-2">
            <div class="row">
              <div class="col-8 m-auto">
                <div class="upload-btn-wrapper w-100">
                  <button
                    [disabled]="count.Total > 0 && modalType==='zip' || (!uploadDone && isUploadClicked)"
                  class="btn btn-primary w-100"> Browse File </button>
                  <input
                    [disabled]="count.Total > 0 && modalType==='zip' || (!uploadDone && isUploadClicked)"
                    type="file" multiple={{allow}} #inputFile (change)="selectFiles($event)"
                    (click)="inputFile.value = ''" title="" class="cursorpointer" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card h-100 border-0 upload-files-items">
          <div class="card-body min-h-auto">
            <h3 class="font-bold">Selected Files</h3>
            @if (selectedFilesList && selectedFilesList.size > 0) {
              <div class="d-grid d-flex gap-3 font-medium justify-content-between"
                >
                <div> Total Uploads: {{count.Total}} ({{totalAssetSize | formatBytes:2:'exactValue'}})
                </div>
                <div> Successful: {{count.Success}}
                </div>
                <div> Failed: {{count.Failed}}
                </div>
              </div>
            }
            <div class="upload-list-panel mt-3">
              <ng-container>
                @for (file of selectedFilesArray(); track file; let i = $index) {
                  <div class="upload-list">
                    <div class="d-flex d-grid gap-3 pt-3 pb-4">
                      <div class="icon-left justify-content-start">
                        @if (selectedFilesList.get(file)?.status == 0 || selectedFilesList.get(file)?.status == 1) {
                          <i class="nm_icon mt-1 align-items-start">
                            <img src="/assets/images/icons/ic_folder_upload.svg" alt="">
                          </i>
                        }
                        @if (selectedFilesList.get(file)?.status == 2) {
                          <i class="nm_icon">
                            <img src="/assets/images/icons/btn-select.svg" style="height: 17px;" alt="">
                          </i>
                        }
                        @if (selectedFilesList.get(file)?.status == 3) {
                          <i class="nm_icon">
                            <img src="assets/images/icons/alert.png" alt="">
                          </i>
                        }
                      </div>
                      @if (selectedFilesList.get(file)?.status != 3) {
                        <div
                          class="file-name flex-grow-1 fs-18 font-bold text-break "> {{file}}
                        </div>
                      }
                      @if (selectedFilesList.get(file)?.status == 3) {
                        <div
                          class="file-name flex-grow-1 fs-18 font-bold text-break text-danger "> {{file}}
                        </div>
                      }
                      @if (selectedFilesList.get(file)?.status != 3) {
                        <div class="asset-size">
                        {{selectedFilesList.get(file)?.file?.size | formatBytes:2:'exactValue'}}</div>
                      }
                      @if (selectedFilesList.get(file)?.status != 3) {
                        <div
                          class="icon-end">
                          @if (selectedFilesList.get(file)?.status != 1) {
                            <button
                              class="btn btn-sm icon-only-btn"> <img src="/assets/images/icons/ic_delete_upload.svg"
                            (click)="removeImage(file, selectedFilesList.get(file))"> </button>
                          }
                        </div>
                      }
                      @if (selectedFilesList.get(file)?.status == 3) {
                        <div
                          class="icon-end">
                          <div class="asset-size text-danger">{{selectedFilesList.get(file)?.file?.size | formatBytes:2:'exactValue'}}</div>
                          @if (selectedFilesList.get(file)?.message == 3) {
                            <button class="btn btn-sm icon-only-btn">
                              <img src="assets/images/icons/ic_retry.svg" (click)="singleRetry(selectedFilesList.get(file))" alt="refresh">
                            </button>
                          }
                          <button class="btn btn-sm icon-only-btn">
                            <img src="assets/images/icons/ic_delete.svg" (click)="removeImage(file, selectedFilesList.get(file))" alt="delete">
                          </button>
                        </div>
                      }
                    </div>
                    @if (selectedFilesList.get(file)?.status == 3) {
                      <div
                        class="flex-grow-1 fs-18 font-bold text-break text-danger "> {{ uploadMessages[selectedFilesList.get(file)?.message ?? 0] }}
                      </div>
                    }
                    @if (selectedFilesList.get(file).progress  >= 0) {
                      <div class="progress-bar-group pt-2">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" attr.aria-valuenow="{{ selectedFilesList.get(file)?.progress }}"
                          aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: selectedFilesList.get(file)?.progress + '%' }"> </div>
                        </div>
                        <div class="dam-primary-100 fs-14 font-bold mt-1">
                          {{ selectedFilesList.get(file)?.progress }}%
                        </div>
                      </div>
                    }
                  </div>
                }
              </ng-container>
            </div>
            @if (message.length) {
              <div role="alert">
                <li class="invalid-feedback d-block font-medium mb-2 p-3">{{ message[0] }}</li>
              </div>
            }
          </div>
          @if (modalType === 'zip' && zipSizeValue==true && isSaveDisabled==false && count.Total > 0) {
            <div
              class="valid-feedback d-block font-medium mb-2 p-3">
              File size is greater than 100 MB, it could take longer ...
            </div>
          }
          @if (isUploadClicked && uploadDone==false && uploadDoneWithError==false) {
            <div
              class="valid-feedback d-block font-medium mb-2 p-3">
              Please wait while your files are being uploaded....
            </div>
          }
          @if (modalType === 'zip' && count.Total> 1) {
            <div
              class="invalid-feedback d-block font-medium mb-2 p-3">
              Please do not upload over 1 files at once.
            </div>
          }
          <div class="d-flex upload-asset-modal-btn d-gird gap-3 justify-content-md-end">
            <button type="button" class="btn btn-secondary btn-min-width" (click)="emptyFileList()"
            [disabled]="(!uploadDone && isUploadClicked)">Cancel</button>
            <!-- <button type="button" class="btn btn-primary btn-min-width" [disabled]="!selectedFiles || isSaveDisabled"></button> -->
            @if ((count.Success == 0 && count.Failed == 0) || (count.Total > count.Success + count.Failed)) {
              <button type="button" class="btn btn-primary btn-min-width"
                [disabled]="(modalType === 'zip' && count.Total > 1  )||count.Total <=0  || isFileZip || isUploadClicked"
              (click)="uploadFiles()">Upload</button>
            }
            @if (uploadDone) {
              <button type="button" class="btn btn-primary btn-min-width"
                [disabled]="count.Failed"
              (click)="saveFiles();[$event.stopPropagation()]">Save</button>
            }
            @if (uploadDoneWithError==true && count.Failed > 0 && !isUploadClicked) {
              <button type="button"
              class="btn btn-secondary btn-min-width" (click)="uploadFiles()">Retry Failed Uploads</button>
            }
          </div>
        </div>
      </div>
    </div>
    <app-confirmationPopUp [show]="confirmationModal" (onCancel)="clearAsset()"
    (onConfirm)="confirmationModal = false;"> </app-confirmationPopUp>
  </p-dialog>