<div
  class="preview-slider-modal d-block"
  tabindex="0"
  (keydown)="onKeyPress($event)"
  #sliderModal>
  <div (click)="closeSlider()">
    <button class="btn-close" type="button" (click)="closeSlider()"></button>
  </div>
    <div class="upper-slider-wrapper">
      <div class="carousel-row">
        <button (click)="navigatePrev()" class="slicked-prev custom-arrow-btn">
        </button>
        <p-carousel #carousel  class="carousel upper-slider py-0 slick-slider" [value]="assetImageList" [numVisible]="1"  [lazy]="true" 
          [numScroll]="1" [page]="currentSlideIndex"  [circular]="true" [responsiveOptions]="responsiveOptions"
           [showIndicators]="false" [showNavigators]="false" (onPage)="onPageChange($event)" (onLazyLoad)="loadAssets($event)">
         
            <ng-template pTemplate="asset" let-asset let-i="index">
              <div class="slide slick-track  h-100">
                <div class="m-2 h-100 my-0">
                  
                  <div class="image-wrapper">
                    <!-- Image Asset -->
                     @if(!assetRefresh){

                      @if (asset?.assetType== 0) {
                        <div class="text-center image-container">
                          @if ((asset$ | async); as assets) {
                          <img [src]="assets" class="w-50 mt-3 img-fluid" alt="Img" loading="lazy" />
                          }
                        </div>
                        }
    
                        <!-- Video Asset -->
                        @if (asset?.assetType== 1 ) {
                        <div class="text-center video-container">
                          <video id="media{{i}}" [src]="asset$ | async" class="mw-100 mh-100 m-auto" alt="video" controls
                            disablePictureInPicture controlsList="noplaybackrate nodownload">
                          </video>
                        </div>
                        }
    
                        @if (asset?.assetType == 3) {
                        <div class="media-container">
                          <img src="assets/images/icons/audio_icon.svg" class="audio-image" alt="Audio">
                          <audio id="media{{i}}" [src]="asset$ | async" class="audio-player" controls controlsList="noplaybackrate nodownload">
                          </audio>
                        </div>
                        }                 
                     }         
                    <!-- Asset Title and Actions -->
                    <div class=" image-caption events__item-title d-flex justify-content-between px-3">
                      <span class="text-truncate w-75">{{ currentAsset?.title || currentAsset?.assetName }}</span>
                      <div class="d-flex gap-lg-2">
                        <i (click)="searchResult(asset._id, asset?.assetPath)" class="prev_page_dd_info_icons">
                          <img src="/assets/images/pages/asset-card/asset_preview_detail_icon.svg"
                            alt="asset preview detail">
                          <span class="hover-text-details">Details</span>
                        </i>
                        <i (click)="onActionChange(asset)" class="prev_page_dd_info_icons">
                          <img src="/assets/images/pages/asset-card/ic_download.svg" style="height: 25px;"
                            alt="Download">
                          <span class="hover-text-details">Download</span>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
           
        </p-carousel>
        <button (click)="navigateNext()" class="slicked-next custom-arrow-btn">
        </button>
      </div>
    </div>
</div>
