import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-no-preview',
    templateUrl: './no-preview.component.html',
    styleUrls: ['./no-preview.component.scss'],
    standalone: true
})
export class NoPreviewComponent {
  @Input() message : any;

}
