export const globals = {
  releaseNumber: 2.8,
  sessionCookie: '_dam_entpri_session',
  oAuthProvider: '_dam_sso_provider_session',
  defaultCorp: '_defaultcorp',
  releaseCookie: 'dam_entpri_release',
  searchtext: '_searchtext',
  appliedFilter: '_appliedFilter',
  _dam_redirectURL: '_dam_redirectURL'
};
