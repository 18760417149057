<div class="navcntr d-lg-flex d-none justify-content-between w-100 px-3">
  <div class="d-flex gap-4 justify-content-between align-items-center">
    <ng-container >
      @for (item of theMenuItems; track item; let ind = $index) {
        <div>
          <a class="d-flex align-items-center font-medium fs-14" (click)="outputSelectedMenuItem( item)">
            <i class="nm_icon me-2">
              <img src="{{item.icon}}" alt="image">
            </i>
            {{item.name}}
          </a>
        </div>
      }
    </ng-container>

  </div>



</div>
