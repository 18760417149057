<div class="upload-component search-by-img">
  <p-dialog [modal]="true" [visible]="true" [breakpoints]="{'1030px': '850px', '991px': '90vw'}"
    [style]="{width: '1100px'}" [showHeader]="false" [resizable]="false" [closeOnEscape]="false"
    class="p-dialog-container">
    <div class="col-sm-12">
      <div class="header-container w-100 modal-header border-0">
        <div class="header-title">Search by Image</div>
        @if (view.status) {
          <div class="minimizer" (click)="minimize()">
            <img src="assets/images/icons/ic_minimize.svg" alt="minimize icon">
          </div>
        }
      </div>
    </div>
    <div class="row no-gutter p-4">
      <div class="col-md-6 search-by-img-colleft">
        <div class="card drag-drop-container radius-24 text-center h-100">
          <input class="btn bg-transparent w-100 drag-drop-file h-100" type="file"
            accept="image/png, image/gif, image/jpeg" multiple ngFileDragDrop (change)="selectFiles($event)" />
            <div class="card-body d-flex flex-column justify-content-center min-h-auto">
              <div class="p-2 mb-4">
                <img alt="image" src="assets/images/icons/file-upload.svg" width="40%">
              </div>
              <div class="my-2">
                <div class="row">
                  <div class="col-8 m-auto">
                    <div class="upload-btn-wrapper w-100">
                      <button class="btn bg-transparent w-100">
                        <a class="choosefile"> Choose a file </a> &nbsp; or Drag it here </button>
                        <input class="cursorpointer" (change)="selectFiles($event)" type="file" accept="image/png, image/gif, image/jpeg"
                          multiple>
                        </div>
                      </div>
                      <div class="text-center pb-2">
                        <span class="ps-fs-8 ps-text-clr-grey">Supported file types:
                          <span class="font-medium ps-clr-dark">JPG, PNG, JPEG, JFIF, WEBP</span></span>
                        </div>
                        <div>
                          <span class="ps-text-clr-grey ps-fs-8">Max file size:
                            <span class="font-medium ps-clr-dark">500MB</span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 search-by-img-colright">
                  <div class="card h-100 border-0 upload-files-items">
                    <div class="card-body min-h-auto">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="left">
                          @if (view.upload) {
                            <div class="selected-files">Selected Files</div>
                          } @else {
                            <div class="selected-files">Queued Images</div>
                          }
                          @if (view.upload) {
                            <div class="totol-uploads">Total Uploads:
                            {{totalAssetSize | formatBytes:2:'exactValue'}}</div>
                          }
                          @if (view.status) {
                            <div class="noti-text">
                              @if (dataRefresh) {
                                Updating queue...
                              } @else {
                                Image retrieval queue will be cleared after 24hrs
                              }
                            </div>
                          }
                        </div>
                        @if (view.status && imageRevData?.length>0) {
                          <div class="right cursorpointer">
                            <img src="/assets/images/pages/dashboard/ic_refresh.png" width="18px" [class.rotate]="dataRefresh"
                              [class.disabled]="dataRefresh" (click)="[getImageRevStatus(),rotateAnim()]" alt="refresh">
                            </div>
                          }
                        </div>
                        <div class="d-grid d-flex gap-3 font-medium justify-content-between"></div>
                        <div class="upload-list-panel mt-3">
                          @if (view.upload) {
                            @for (file of selectedFilesArray(); track file; let i = $index) {
                              <div class="upload-list">
                                <div class="d-flex d-grid gap-3 pt-3 pb-3 align-items-center">
                                  <div class="icon-left justify-content-start">
                                    @if (selectedFilesList.get(file)?.status == 0 || selectedFilesList.get(file)?.status == 1) {
                                      <i
                                        class="nm_icon mt-1 align-items-start">
                                        <img src="/assets/images/icons/ic_folder_upload.svg" alt="folder upload image">
                                      </i>
                                    }
                                    @if (selectedFilesList.get(file)?.status == 2 ) {
                                      <i
                                        class="nm_icon">
                                        <img src="/assets/images/icons/btn-select.svg" style="height: 17px;" alt="select button image">
                                      </i>
                                    }
                                    @if (selectedFilesList.get(file)?.status == 3 ) {
                                      <i
                                        class="nm_icon">
                                        <img src="assets/images/icons/alert.png" alt="alert image">
                                      </i>
                                    }
                                  </div>
                                  @if (selectedFilesList.get(file)?.status != 3) {
                                    <div
                                      class="file-name flex-grow-1 fs-18 font-bold text-break "> {{file}}
                                    </div>
                                  }
                                  @if (selectedFilesList.get(file)?.status == 3) {
                                    <div
                                      class="file-name flex-grow-1 fs-18 font-bold text-break text-danger "> {{file}}
                                    </div>
                                  }
                                  @if (selectedFilesList.get(file)?.status != 3) {
                                    <div class="asset-size">
                                    {{selectedFilesList.get(file)?.file?.size | formatBytes:2:'exactValue'}}</div>
                                  }
                                  @if (selectedFilesList.get(file)?.status != 3) {
                                    <div
                                      class="icon-end">
                                      @if (selectedFilesList.get(file)?.status != 1) {
                                        <button class="btn btn-sm icon-only-btn">
                                          <img src="/assets/images/icons/ic_delete_upload.svg" (click)="removeImage(selectedFilesList.get(file))"> </button>
                                        }
                                      </div>
                                    }
                                    @if (selectedFilesList.get(file)?.status == 3) {
                                      <div
                                        class="icon-end">
                                        <div class="asset-size text-danger">{{selectedFilesList.get(file)?.file?.size | formatBytes:2:'exactValue'}}</div>
                                        @if (selectedFilesList.get(file)?.message == 3) {
                                          <button class="btn btn-sm icon-only-btn">
                                            <img src="assets/images/icons/ic_retry.svg" (click)="singleRetry(selectedFilesList.get(file))" alt="refresh">
                                          </button>
                                        }
                                        <button class="btn btn-sm icon-only-btn">
                                          <img src="assets/images/icons/ic_delete.svg" (click)="removeImage(selectedFilesList.get(file))" alt="delete">
                                        </button>
                                      </div>
                                    }
                                  </div>
                                  @if (selectedFilesList.get(file)?.status == 3) {
                                    <div
                                      class="flex-grow-1 fs-18 font-bold text-break text-danger "> {{ uploadMessage[selectedFilesList.get(file)?.message ?? 0] }}
                                    </div>
                                  }
                                  @if (selectedFilesList.get(file).progress  >= 0) {
                                    <div class="progress-bar-group pt-2"
                                      >
                                      <div class="progress">
                                        <div class="progress-bar" role="progressbar"
                                          attr.aria-valuenow="{{ selectedFilesList.get(file)?.progress }}" aria-valuemin="0"
                                        aria-valuemax="100" [ngStyle]="{ width: selectedFilesList.get(file)?.progress + '%' }"> </div>
                                      </div>
                                      <div class="dam-primary-100 fs-14 font-bold mt-1">
                                        {{ selectedFilesList.get(file)?.progress }}%
                                      </div>
                                    </div>
                                  }
                                </div>
                              }
                            }
                            @if (view.status) {
                              <div class="status-list">
                                @for (item of imageRevData; track item; let i = $index) {
                                  <div class="status-list-item gap-3 pt-3 pb-4">
                                    <div class="icon-container">
                                      @if (item.status) {
                                        <i class="nm_icon">
                                          <img src="/assets/images/icons/btn-select.svg" alt="select button image">
                                        </i>
                                      } @else {
                                        <i class="nm_icon">
                                          <img src="/assets/images/pages/dashboard/ic_cloud_warning.svg" alt="cloud warning image">
                                        </i>
                                      }
                                    </div>
                                    <div class="asset-details flex-grow-1 ">
                                      <div class="name flex-grow-1 fs-18 font-bold text-break">
                                        {{item.assetName}}
                                      </div>
                                      <div class="desc">
                                        @if (item.status) {
                                          Similar images found
                                        } @else {
                                          Fetching similar images
                                        }
                                      </div>
                                    </div>
                                    <div class="action-container">
                                      <div class="btn-group">
                                        <button class="btn btn-sm icon-only-btn" [disabled]="!item.status">
                                          <img src="/assets/images/pages/dashboard/ic_navigator.svg" (click)="onAssetAction('redirect',item)"
                                            alt="refresh">
                                          </button>
                                          <button class="btn btn-sm icon-only-btn" (click)="onAssetAction('delete',item)">
                                            <img src="/assets/images/icons/icon-remove-primary.svg" style="height: 16px;" alt="delete">
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                          <div class="d-flex upload-asset-modal-btn d-gird gap-3 justify-content-md-end">
                            @if (view.upload ) {
                              <button type="button" class="btn btn-secondary btn-min-width"
                                (click)="onAssetAction('cancel')" [disabled]="(!uploadDone && isUploading)">Cancel
                              </button>
                            }
                            @if (!uploadDone && view.upload) {
                              <button [disabled]="count.Total ==0 || isUploading"
                                type="button" class="btn btn-primary btn-min-width" (click)="uploadFiles()">
                                Upload
                              </button>
                            }
                            @if (view.status && imageRevData?.length>0) {
                              <button type="button" class="btn btn-secondary btn-min-width"
                                (click)="onAssetAction('clear')">Clear All
                              </button>
                            }
                            @if (uploadDone && view.upload) {
                              <button type="button" class="btn btn-primary d-btn-primary btn-min-width"
                                [disabled]="fileAlreadyExistsOrError.length>0 || count.Total ==0"
                              (click)="submitImages();[$event.stopPropagation()]">Find Similar Images</button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </p-dialog>
                </div>
                <app-confirmationPopUp [show]="confirmationModal" (onCancel)="confirmationModal = false;"
                (onConfirm)="onAssetAction('deleteAll')"> </app-confirmationPopUp>
                <p-toast></p-toast>