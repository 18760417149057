@if (dataLoaded) {
  <app-header></app-header>
  <div class="main-component" id="clientadmin">
    <div class="admin-wrapper h-100">
      <div class="assets-folder-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
}
@if (showUploadModal) {
  <app-global-upload [hidden]="!isGlobalUploadModalVisible"></app-global-upload>
}

