import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { AppService } from './app/core/services/app.service';
import { MessageService } from 'primeng/api';
import { MenuItems } from './app/common/menu-items/menu-items';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from './app/core/interceptors/token.interceptor';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { appRoutes } from './app/app.routing';
import { CoreModule } from './app/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { AppComponent } from './app/app.component';
import { importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';


bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, CoreModule, FormsModule, ReactiveFormsModule, BrowserModule, DropdownModule, DialogModule, DatePipe, CalendarModule, ToastModule),
        AppService,
        MessageService,
        MenuItems,
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideRouter(appRoutes),
    ]
})
  .catch(err => console.error());
