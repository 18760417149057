import { NgClass } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { fabric } from 'fabric';
import { DialogModule } from 'primeng/dialog';
import { LoaderService } from 'src/app/core/services';


@Component({
  selector: 'app-image-editor',
  standalone: true,
  imports: [DialogModule, NgClass],
  templateUrl: './image-editor.component.html',
  styleUrl: './image-editor.component.scss'
})
export class ImageEditorComponent implements AfterViewInit {
  show: boolean = true;
  @Input() imageUrl: any;
  @Input() assetExtension: string;
  @Output() onActionPerformed = new EventEmitter();
  @ViewChild('canvasContainer', { static: true }) canvasContainer!: ElementRef;
  canvas!: fabric.Canvas;
  selectedAspectRatio: string = 'Custom';
  loading: boolean = true;

  constructor(private loaderService: LoaderService) { }

  ngAfterViewInit() {
    const container = this.canvasContainer.nativeElement;
    this.canvas = new fabric.Canvas('imageCanvas', {
      width: container.clientWidth,
      height: container.clientHeight,
    });

    // Restrict cropping rectangle within canvas
    this.canvas.on('object:scaling', (e) => {
      const obj = e.target as fabric.Rect;
      if (obj) {
        const maxWidth = this.canvas.width!;
        const maxHeight = this.canvas.height!;

        // Restrict width and height
        if (obj.width! * obj.scaleX! > maxWidth) {
          obj.scaleX = maxWidth / obj.width!;
        }
        if (obj.height! * obj.scaleY! > maxHeight) {
          obj.scaleY = maxHeight / obj.height!;
        }
      }
    });
    this.loadImageFromUrl(this.imageUrl);

    // this.canvas.on('mouse:wheel', (opt) => {
    //   const delta = opt.e.deltaY;
    //   const zoomFactor = delta > 0 ? 0.9 : 1.1; // Zoom out for positive delta, in for negative
    //   this.zoomImage(zoomFactor);

    //   opt.e.preventDefault();
    //   opt.e.stopPropagation();
    // });

  }


  // Load Image
  loadImageFromUrl(imageUrl: string) {
    fabric.Image.fromURL(imageUrl, (img) => {
      // Calculate scale to fit
      const canvasAspect = this.canvas.width! / this.canvas.height!;
      const imageAspect = img.width! / img.height!;
      let scale = 1;

      if (canvasAspect > imageAspect) {
        scale = this.canvas.height! / img.height!;
      } else {
        scale = this.canvas.width! / img.width!;
      }

      // Scale image
      img.scale(scale);
      img.set({
        left: (this.canvas.width! - img.getScaledWidth()) / 2,
        top: (this.canvas.height! - img.getScaledHeight()) / 2,
        selectable: false, // Disable selection for the background image
      });

      this.canvas.clear(); // Clear previous content
      this.canvas.add(img);

      // Add cropping rectangle
      this.addCroppingRectangle(img.getScaledWidth()!, img.getScaledHeight()!, 0);

      this.canvas.renderAll(); // Ensure everything is rendered
      this.loading = false;
    }, {
      crossOrigin: 'anonymous' // Handle cross-origin requests if the image URL is from another domain
    });
  }

  // Change aspect ratio and update canvas
  addCroppingRectangle(scaledWidth: number, scaledHeight: number, angle: number) {
    // Remove the existing cropping rectangle, if any
    const existingRect = this.canvas.getObjects().find((obj) => obj.type === 'rect') as fabric.Rect;
    if (existingRect) {
      this.canvas.remove(existingRect);
    }
    // Ensure the cropping rectangle fits within the canvas dimensions
    const rectWidth = Math.min(scaledWidth, this.canvas.width!);
    const rectHeight = Math.min(scaledHeight, this.canvas.height!);

    // Center the cropping rectangle
    const cropRect = new fabric.Rect({
      left: this.canvas.width! / 2,
      top: this.canvas.height! / 2,
      originX: 'center',
      originY: 'center',
      width: rectWidth,
      height: rectHeight,
      fill: this.createGridPattern(rectWidth, rectHeight), // Semi-transparent fill for visibility
      stroke: '#00008B', // Border color
      strokeWidth: 2.6,
      selectable: true, // Enable selection
      hasControls: true, // Hide default resize controls
      hasBorders: false, // Hide the borders (selection frame)
      // lockMovementX: true, // Prevent horizontal movement
      // lockMovementY: true, // Prevent vertical movement
      angle: angle || 0, // Align rectangle's angle with the image's angle
      // Dash pattern for the border to create dashed lines
      strokeDashArray: [2, 3.5], // Dashed border pattern (5px dash, 5px gap)
      // Set corner style and size for bold corners
      cornerStyle: 'rect', // Rectangle corners (for bold effect)
      cornerSize: 10, // Bold corner size (adjust as needed)
      transparentCorners: false, // Disable transparent corners to maintain the bold effect
    });

    setTimeout(() => {
      this.canvas.add(cropRect);
      this.canvas.setActiveObject(cropRect); // Set it as the active object for editing
    }, 5);

  }

  changeAspectRatio(widthRatio: number = 0, heightRatio: number = 0) {
    this.selectedAspectRatio = `${widthRatio}:${heightRatio}`;
    const imageObject = this.canvas.getObjects().find((obj) => obj.type === 'image') as fabric.Image;

    if (!imageObject) return;

    // Step 1: Get rotated bounding box dimensions
    const angle = (imageObject.angle || 0) * (Math.PI / 180); // Convert angle to radians
    const imgWidth = imageObject.width!;
    const imgHeight = imageObject.height!;

    const rotatedWidth = Math.abs(Math.cos(angle)) * imgWidth + Math.abs(Math.sin(angle)) * imgHeight;
    const rotatedHeight = Math.abs(Math.sin(angle)) * imgWidth + Math.abs(Math.cos(angle)) * imgHeight;

    // Step 2: Scale rotated image to fit within the canvas
    const canvasWidth = this.canvas.width!;
    const canvasHeight = this.canvas.height!;
    const canvasAspect = canvasWidth / canvasHeight;
    const rotatedAspect = rotatedWidth / rotatedHeight;

    let scale = 1;
    if (rotatedAspect > canvasAspect) {
      scale = canvasWidth / rotatedWidth;
    } else {
      scale = canvasHeight / rotatedHeight;
    }

    const scaledWidth = rotatedWidth * scale;
    const scaledHeight = rotatedHeight * scale;

    imageObject.scale(scale);
    imageObject.set({
      left: canvasWidth / 2,
      top: canvasHeight / 2,
      originX: 'center',
      originY: 'center',
    });

    // Step 3: Adjust the cropping rectangle
    let rectWidth = scaledWidth;
    let rectHeight = scaledHeight;

    if (widthRatio && heightRatio) {
      if (scaledWidth / scaledHeight > widthRatio / heightRatio) {
        rectWidth = (scaledHeight * widthRatio) / heightRatio;
      } else {
        rectHeight = (scaledWidth * heightRatio) / widthRatio;
      }
    }

    // Remove the existing cropping rectangle, if any
    const existingRect = this.canvas.getObjects().find((obj) => obj.type === 'rect') as fabric.Rect;
    if (existingRect) {
      this.canvas.remove(existingRect);
    }

    // Create a new cropping rectangle and rotate it
    const cropRect = new fabric.Rect({
      width: rectWidth,
      height: rectHeight,
      left: canvasWidth / 2,
      top: canvasHeight / 2,
      originX: 'center',
      originY: 'center',
      angle: imageObject.angle || 0, // Align rectangle's angle with the image's angle
      fill: this.createGridPattern(rectWidth, rectHeight), // Semi-transparent fill
      stroke: '#00008B', // Border color
      strokeWidth: 2.6,
      selectable: true, // Enable selection
      hasControls: true, // Enable resizing
      hasBorders: false, // Show border
      // lockMovementX: true, // Prevent horizontal movement
      // lockMovementY: true, // Prevent vertical movement
      strokeDashArray: [2, 3.5], // Dashed border pattern (5px dash, 5px gap)
      // Set corner style and size for bold corners
      cornerStyle: 'rect', // Rectangle corners (for bold effect)
      cornerSize: 10, // Bold corner size (adjust as needed)
      transparentCorners: false, // Disable transparent corners to maintain the bold effect
    });

    // Step 4: Add and render the cropping rectangle
    setTimeout(() => {
      this.canvas.add(cropRect);
      this.canvas.setActiveObject(cropRect);
      this.canvas.renderAll();
    }, 2);
  }

  rotateImage(angle: number) {
    const imageObject = this.canvas.getObjects().find((obj) => obj.type === 'image') as fabric.Image;
    const cropRect = this.canvas.getObjects().find((obj) => obj.type === 'rect') as fabric.Rect;

    if (imageObject) {
      // Step 1: Calculate the new angle
      const currentAngle = imageObject.angle || 0;
      const newAngle = (currentAngle + angle) % 360;

      // Step 2: Get the original dimensions of the image
      const originalWidth = imageObject.width!;
      const originalHeight = imageObject.height!;

      // Step 3: Calculate the rotated bounding box dimensions
      const radians = (newAngle * Math.PI) / 180;
      const rotatedWidth =
        Math.abs(originalWidth * Math.cos(radians)) +
        Math.abs(originalHeight * Math.sin(radians));
      const rotatedHeight =
        Math.abs(originalWidth * Math.sin(radians)) +
        Math.abs(originalHeight * Math.cos(radians));

      // Step 4: Scale the image to fit within the canvas
      const scaleX = this.canvas.width! / rotatedWidth;
      const scaleY = this.canvas.height! / rotatedHeight;
      const scale = Math.min(scaleX, scaleY, 1);

      imageObject.set({
        scaleX: scale,
        scaleY: scale,
        angle: newAngle,
        left: this.canvas.width! / 2,
        top: this.canvas.height! / 2,
        originX: 'center',
        originY: 'center',
      });

      // Retain cropping rectangle position and size
      if (cropRect) {
        this.addCroppingRectangleAfterRotation(imageObject, cropRect, angle);
      }

      // Step 5: Render canvas
      this.canvas.renderAll();
    }
  }


  flipImage(flipType = 'horizontal') {
    const imageObject = this.canvas.getObjects().find((obj) => obj.type === 'image') as fabric.Image;
    const cropRect = this.canvas.getObjects().find((obj) => obj.type === 'rect') as fabric.Rect;

    if (!imageObject || !cropRect) {
      console.error("Image or cropping rectangle not found.");
      return;
    }

    // Get the image's rotation angle
    const imgAngle = (imageObject.angle || 0) % 360;

    // Adjust flip type based on rotation
    let adjustedFlipType = flipType;
    if (imgAngle === 90 || imgAngle === -90 || imgAngle === -270 || imgAngle === 270) {

      // Swap the meaning of horizontal and vertical flips
      adjustedFlipType = flipType === 'horizontal' ? 'vertical' : 'horizontal';
    }

    // Perform the flip
    if (adjustedFlipType === 'horizontal') {
      imageObject.flipX = !imageObject.flipX;
    } else if (adjustedFlipType === 'vertical') {
      imageObject.flipY = !imageObject.flipY;
    }

    // Reposition the cropping rectangle (if necessary)
    const imgCenter = imageObject.getCenterPoint(); // Image center
    const rectCenter = cropRect.getCenterPoint(); // Cropping rectangle center

    let dx = rectCenter.x - imgCenter.x;
    let dy = rectCenter.y - imgCenter.y;

    // Adjust dx and dy based on flip
    if (imageObject.flipX) dx = -dx;
    if (imageObject.flipY) dy = -dy;

    cropRect.setPositionByOrigin(
      new fabric.Point(imgCenter.x + dx, imgCenter.y + dy),
      'center',
      'center'
    );

    // Render the changes
    this.canvas.renderAll();
  }

  zoomImage(factor: number) {
    const imageObject = this.canvas.getObjects().find((obj) => obj.type === 'image') as fabric.Image;

    if (!imageObject) {
      console.error("Image not found.");
      return;
    }

    // Step 1: Get current image properties
    const canvasCenter = this.canvas.getCenter();
    const imgLeft = imageObject.left!;
    const imgTop = imageObject.top!;
    const imgScaleX = imageObject.scaleX || 1;
    const imgScaleY = imageObject.scaleY || 1;

    // Calculate the offset of the image relative to the canvas center
    const offsetX = imgLeft - canvasCenter.left;
    const offsetY = imgTop - canvasCenter.top;

    // Step 2: Apply the zoom factor
    const newScaleX = imgScaleX * factor;
    const newScaleY = imgScaleY * factor;

    imageObject.scaleX = newScaleX;
    imageObject.scaleY = newScaleY;

    // Step 3: Adjust position to maintain relative offset
    imageObject.left = canvasCenter.left + offsetX * factor;
    imageObject.top = canvasCenter.top + offsetY * factor;

    // Render the updated canvas
    this.canvas.renderAll();
  }

  addCroppingRectangleAfterRotation(imageObject: fabric.Image, cropRect: fabric.Rect, angleDelta: number) {
    // Calculate the new angle of the cropping rectangle
    const newAngle = (cropRect.angle || 0) + angleDelta;

    // Get the previous center point of the cropping rectangle
    const cropCenter = cropRect.getCenterPoint();

    // Translate the center point to the rotated coordinate space
    const radians = (angleDelta * Math.PI) / 180;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);

    const dx = cropCenter.x - imageObject.left!;
    const dy = cropCenter.y - imageObject.top!;

    const rotatedX = dx * cos - dy * sin + imageObject.left!;
    const rotatedY = dx * sin + dy * cos + imageObject.top!;

    // Update the cropping rectangle properties
    cropRect.set({
      left: rotatedX,
      top: rotatedY,
      angle: newAngle,
      originX: 'center',
      originY: 'center',
    });

    // Step 7: Render the canvas
    this.canvas.setActiveObject(cropRect);
    this.canvas.renderAll();
  }

  cropImage() {
    const imageObject = this.canvas.getObjects().find((obj) => obj.type === 'image') as fabric.Image;
    const cropRect = this.canvas.getObjects().find((obj) => obj.type === 'rect') as fabric.Rect;

    if (!imageObject || !cropRect) {
      console.error('Image or cropping rectangle not found.');
      return;
    }

    // Step 1: Retrieve image properties
    const imgElement = imageObject.getElement() as HTMLImageElement;
    const imgWidth = imageObject.width!;
    const imgHeight = imageObject.height!;
    const imgScaleX = imageObject.scaleX || 1;
    const imgScaleY = imageObject.scaleY || 1;
    const imgAngle = (imageObject.angle || 0) * (Math.PI / 180); // Convert to radians

    const imgCenter = imageObject.getCenterPoint();
    const imgFlipX = imageObject.flipX ? -1 : 1;
    const imgFlipY = imageObject.flipY ? -1 : 1;

    // Step 2: Retrieve cropping rectangle properties
    const rectWidth = cropRect.width! * cropRect.scaleX!;
    const rectHeight = cropRect.height! * cropRect.scaleY!;
    const rectCenter = cropRect.getCenterPoint();

    // Step 3: Translate cropping rectangle to image space
    const dxCanvas = rectCenter.x - imgCenter.x;
    const dyCanvas = rectCenter.y - imgCenter.y;

    // Transform coordinates to image's local space
    const rotatedDx = (dxCanvas * Math.cos(-imgAngle) - dyCanvas * Math.sin(-imgAngle)) / imgScaleX;
    const rotatedDy = (dxCanvas * Math.sin(-imgAngle) + dyCanvas * Math.cos(-imgAngle)) / imgScaleY;

    const rectCenterX = rotatedDx / imgFlipX + imgWidth / 2;
    const rectCenterY = rotatedDy / imgFlipY + imgHeight / 2;

    const rectImageWidth = rectWidth / Math.abs(imgScaleX);
    const rectImageHeight = rectHeight / Math.abs(imgScaleY);

    // Step 4: Clip the crop rectangle to the image boundaries
    const cropX = Math.max(0, rectCenterX - rectImageWidth / 2);
    const cropY = Math.max(0, rectCenterY - rectImageHeight / 2);
    const cropWidth = Math.min(rectImageWidth, imgWidth - cropX);
    const cropHeight = Math.min(rectImageHeight, imgHeight - cropY);

    if (cropWidth <= 0 || cropHeight <= 0) {
      console.error('Crop rectangle is completely outside the image.');
      this.changeAspectRatio();
      this.loaderService.setLoading(false);
      return;
    }

    // Step 5: Crop the image
    const cropCanvas = document.createElement('canvas');
    cropCanvas.width = cropWidth;
    cropCanvas.height = cropHeight;

    const ctx = cropCanvas.getContext('2d');
    if (!ctx) {
      console.error('Failed to get 2D context.');
      return;
    }

    ctx.translate(cropWidth / 2, cropHeight / 2);
    ctx.scale(imgFlipX, imgFlipY);
    ctx.drawImage(
      imgElement,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      -cropWidth / 2,
      -cropHeight / 2,
      cropWidth,
      cropHeight
    );

    // Step 6: Rotate the cropped image
    const rotatedCanvas = document.createElement('canvas');
    const rotationRadians = imgAngle;
    const rotatedWidth = Math.abs(cropWidth * Math.cos(rotationRadians)) + Math.abs(cropHeight * Math.sin(rotationRadians));
    const rotatedHeight = Math.abs(cropWidth * Math.sin(rotationRadians)) + Math.abs(cropHeight * Math.cos(rotationRadians));

    rotatedCanvas.width = rotatedWidth;
    rotatedCanvas.height = rotatedHeight;

    const rotatedCtx = rotatedCanvas.getContext('2d');
    if (!rotatedCtx) {
      console.error('Failed to get 2D context for rotation.');
      return;
    }

    rotatedCtx.translate(rotatedWidth / 2, rotatedHeight / 2);
    rotatedCtx.rotate(rotationRadians); // Apply the original image's rotation
    rotatedCtx.drawImage(
      cropCanvas,
      -cropWidth / 2,
      -cropHeight / 2,
      cropWidth,
      cropHeight
    );

    // Step 7: Download the rotated cropped image
    const croppedImageURL = rotatedCanvas.toDataURL(this.assetExtension);
    this.onActionPerformed.emit({
      action: 1,
      data: croppedImageURL
    });
  }


  createGridPattern(rectWidth: any, rectHeight: any) {
    const gridCanvas = document.createElement('canvas');
    gridCanvas.width = rectWidth; // Set grid canvas width equal to the rectangle's width
    gridCanvas.height = rectHeight; // Set grid canvas height equal to the rectangle's height
    const gridCtx = gridCanvas.getContext('2d');
    if (gridCtx) {
      // Set grid line color and style
      gridCtx.strokeStyle = '#C5C5C5'; // Light gray lines
      gridCtx.lineWidth = 2;
      gridCtx.setLineDash([3.5, 5]); // Dotted line style (5px dash, 5px space)

      // Draw horizontal lines (3 divisions, 2 lines)
      const numHorizontalDivisions = 3;
      for (let i = 1; i < numHorizontalDivisions; i++) {
        gridCtx.beginPath();
        gridCtx.moveTo(0, (rectHeight / numHorizontalDivisions) * i);
        gridCtx.lineTo(rectWidth, (rectHeight / numHorizontalDivisions) * i); // Draw horizontal dotted lines
        gridCtx.stroke();
      }

      // Draw vertical lines (2 divisions, 1 line)
      const numVerticalDivisions = 3;
      for (let i = 1; i < numVerticalDivisions; i++) {
        gridCtx.beginPath();
        gridCtx.moveTo((rectWidth / numVerticalDivisions) * i, 0);
        gridCtx.lineTo((rectWidth / numVerticalDivisions) * i, rectHeight); // Draw vertical dotted lines
        gridCtx.stroke();
      }
    }
    const dataURL = gridCanvas.toDataURL();

    // Return the image as a pattern source
    return new fabric.Pattern({
      source: dataURL, // Use the data URL as the pattern source
      repeat: 'repeat',   // Repeat the pattern
    });
  }

  move() {
    const imageObject = this.canvas.getObjects().find((obj) => obj.type === 'image') as fabric.Image;
    const cropRect = this.canvas.getObjects().find((obj) => obj.type === 'rect') as fabric.Rect;

    if (!imageObject) {
      console.error('Image object not found');
      return;
    }

    // Enable image movement
    imageObject.set({
      selectable: true,
    });

    // Keep the image at the back
    this.canvas.setActiveObject(cropRect);

    // Ensure the image stays at the back during interactions
    this.canvas.on('object:moving', (event) => {
      const target = event.target;
      if (target === imageObject) {
        this.canvas.setActiveObject(cropRect); // Ensure image is always at the back
      }
    });

    this.canvas.renderAll();
  }

  close() {
    this.onActionPerformed.emit(
      {
        action: 0,
        data: {}
      });
  }

  initiateCropping() {
    this.loaderService.setLoading(true);
    setTimeout(() => {
      this.cropImage();
    }, 0);
  }

}
