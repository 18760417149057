@if (isCollapsible) {
  <div class="filter__sidebar-initial sidenav d-flex flex-column" [ngClass]="{'sidenav-open': isSidenavOpen}">
    <!-- Management -->
    @if (isCollapsible) {
      <button type="button" class="pt-2 w-35 btn position-relative p-0 filter__sidebar-toggle-btn mt-4" (click)="toggleSidebar()" [ngClass]="{'show': !isSidenavOpen, '': isSidenavOpen}" data-bs-toggle="collapse"
        data-bs-target="#filterSideNav" aria-expanded="false" aria-controls="collapseWidthExample">
        <img src="/assets/images/pages/management/expand_circle_right.svg" width="15px" alt="expand_circle_right_image" />
      </button>
    }
    <!-- Managements Section -->
    @for (item of routerConfig.childs; track item; let i = $index) {
      <div class="word-show-a">
        <div>
          @if (isCollapsible) {
            <button type="button" routerLink="/{{item.url}}"
              [class.active]="isActiveSidebar === item.number" (click)="redirectChild(item)"
              class="btn w-35 tooltip-refer position-relative p-0 filter__sidebar-toggle-btn sidenav-close-section custom-tooltip-hover">
              @if (isActiveSidebar !== item.number) {
                <img src={{item.imgUrl}} width="15px" alt="image" />
              }
              @if (isActiveSidebar === item.number) {
                <img src={{item.activeImgUrl}} width="15px" alt="image" />
              }
              @if (!(isActiveSidebar === item.number)) {
                <span class="hover-text-details">{{item.label}}</span>
              }
            </button>
          }
        </div>
      </div>
    }
  </div>
}

<div class="filter__sidebar-wrapper transition bg-white collapse-horizontal collapse" [ngClass]="{'sidenav-open': isSidenavOpen}" id="filterSideNav">
  <div class="d-flex transition justify-content-between headmanage mt-3">
    <span class="d-flex align-items-center gap-2">
      <h5 class="m-0 sidenav-management">Management</h5>
    </span>
    @if (!isCollapsible) {
      <button type="button" class="border-0 bg-white transition-toggle-btn" aria-label="Close"
        data-bs-toggle="collapse" data-bs-target="#filterSideNav" aria-expanded="true" (click)="toggleSidebar()">
        <img src="/assets/images/pages/management/expand_circle_left.svg" width="15px" alt="expand_circle_left_image" />
      </button>
    }
  </div>

  <div class="d-flex flex-column">
    @for (item of routerConfig.childs; track item; let i = $index) {
      <div class="word-show-a">
        <div class="d-flex single-tab-hover" [class.active]="isActiveSidebar === item.number">
          @if (!isCollapsible) {
            <button type="button" routerLink="/{{item.url}}"
              (click)="redirectChild(item)"
              class="btn w-100 word-left position-relative gap-4 p-0 filter__sidebar-toggle-btn sidenav-close-section">
              @if (isActiveSidebar !== item.number) {
                <img src={{item.imgUrl}} width="15px" alt="image" />
              }
              @if (isActiveSidebar === item.number) {
                <img src={{item.activeImgUrl}} width="15px" alt="image" />
              }
              @if (!isCollapsible) {
                <span class="sidenav-open-section manage-name">
                  <span [ngClass]="isActiveSidebar == item.number ? 'word-color' : ''">{{item.label}}</span>
                </span>
              }
            </button>
          }
        </div>
      </div>
    }
  </div>
</div>
