import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noValueFoundPipe',
  standalone: true
})

// The transform function takes three arguments:

// 1.items: An array of objects to process.
// 2.replacement: A string used to replace empty values.
// 3.keys: An array of key names to check for empty values.

export class NoValueFoundPipe implements PipeTransform {

  transform(items: { [key: string]: any }[], replacement: string, keys: string[]): { [key: string]: any }[] {
    return items?.map(item => {
      // Create a new object with the same properties
      const newItem = { ...item };
      
      // Check each key and replace empty values with the replacement word here
      keys.forEach(key => {
        if (newItem[key] === "") {
          newItem[key] = replacement; // Use the provided replacement value for the specified key
        }
      });
      return newItem;
    });
  }

}
