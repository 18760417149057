import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-media-controls',
  templateUrl: './media-controls.component.html',
  styleUrls: ['./media-controls.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class MediaControlsComponent implements AfterViewInit, OnDestroy {
  @Input() mediaElementRef!: ElementRef;
  @Input() currentTime: any = 0;
  @Input() captionTrack: any[] = [{ name: 'Off' }];
  @Input() videoDuration: any;
  @Input() activeCue: string;
  @Input() captionContainer: boolean = false;
  @Output() cueChanged = new EventEmitter<VTTCue[] | null>();
  subtitleOff: boolean = true;
  isCaptionVisible: boolean = false;
  mediaToggleFlag: boolean = false;
  remainingTime: string;
  intervalId: any;
  selectedTrack: string = 'Default';
  currentVolumeVal: number = 100;
  isVolumeVisible: boolean = false;
  @ViewChild('subtitleDisplay') subtitleDisplay!: ElementRef;
  isVolumeOn: boolean = false;
  mediaEnded = false;
  currentTrackHtml: any;

  ngAfterViewInit(): void {
    setTimeout(() => {
      const media = this.mediaElementRef?.nativeElement;
      if (media) {
        media.onended = () => {
          this.mediaToggleFlag = !this.mediaToggleFlag;
          this.mediaEnded = true;
        };
      }
    }, 500);
  }

  togglePlayPause() {
    const media = this.mediaElementRef?.nativeElement;
    this.mediaToggleFlag = !this.mediaToggleFlag;
    if (this.mediaToggleFlag) {
      media.play();
      this.startUpdatingTrack();
    } else {
      media.pause();
      clearInterval(this.intervalId);
    }
    if (this.mediaEnded) {
      this.currentTime = 0;
      this.mediaEnded = false;
    }
  }

  adjustTrack(event: any) {
    const newTime = parseFloat((event.target as HTMLInputElement).value);
    this.currentTime = newTime;
    this.mediaElementRef.nativeElement.currentTime = newTime;
  }

  onTrackChange(trackName: string) {
    this.selectedTrack = trackName;
    this.isCaptionVisible = !this.isCaptionVisible;
    if (trackName === 'Off') {
      this.subtitleOff = false;
      return;
    }
    const media = this.mediaElementRef?.nativeElement;
    if (!media) return;
    const tracks = Array.from(media.textTracks) as TextTrack[];

    for (let track of tracks) {
      track.mode = 'disabled';
    }

    setTimeout(() => {
      const subtitleDisplay = document.getElementById('subtitleDisplay');
      if (subtitleDisplay) subtitleDisplay.innerHTML = '';
    }, 0);

    for (let track of tracks) {
      if (track.label === trackName) {
        this.subtitleOff = true;
        track.mode = 'hidden';

        if (track.cues) {
          const cues = Array.from(track.cues) as VTTCue[];
          this.cueChanged.emit(cues ?? null);
          const currentTime = media.currentTime;
          const currentCue = cues.find(
            (cue) => cue.startTime <= currentTime && cue.endTime >= currentTime
          );
          setTimeout(() => {
            const subtitleDisplay = document.getElementById('subtitleDisplay');
            if (subtitleDisplay && currentCue) {
              subtitleDisplay.innerHTML = '';
              subtitleDisplay.innerHTML = currentCue.text ?? ' ';
            }
          }, 0);
        }
      }
    }
  }

  startUpdatingTrack() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.intervalId = setInterval(() => {
      if (this.currentTime < this.videoDuration) {
        this.currentTime = this.mediaElementRef?.nativeElement.currentTime;
      } else {
        clearInterval(this.intervalId);
      }
    }, 1000);
  }

  toggleCaptionSelect() {
    this.isCaptionVisible = !this.isCaptionVisible;
  }

  adjustVolume(event: any) {
    this.currentVolumeVal = event.target.value;
    const mediaElement = this.mediaElementRef?.nativeElement;
    if (mediaElement) {
      mediaElement.volume = this.currentVolumeVal / 100;
    }
  }

  toggleVolume() {
    this.isVolumeOn = !this.isVolumeOn;
    this.mediaElementRef.nativeElement.muted =
      !this.mediaElementRef?.nativeElement.muted;
  }

  onVolumeHover() {
    this.isVolumeVisible = true;
  }

  convertTime(seconds: string): string {
    if (!seconds && seconds !== '0') return '0:00';

    const totalSeconds = Math.floor(Number(seconds));
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;

    const paddedMinutes = minutes < 10 ? `${minutes}` : minutes;
    const paddedSeconds = secs < 10 ? `0${secs}` : secs;

    if (hours > 0) {
      return `${hours}:${paddedMinutes}:${paddedSeconds}`;
    } else {
      return `${paddedMinutes}:${paddedSeconds}`;
    }
  }

  isValidDuration(value: any): boolean {
    return typeof value === 'number' && isFinite(value) && value >= 0;
}

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
