import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private appService: AppService) {}

  canActivate() {
    if (this.authService.getAuthData()) {
      return true;
    } else {
      if(window.location.href.includes('folderPath')) {
        this.appService.previousHitURL = window.location.href;
      }
      this.router.navigate(['/']);
      return false;
    }
  }
}
