import { AbstractControl, ValidatorFn } from '@angular/forms';
import { APP_ROUTE } from 'src/app/core/constants';

export class CommonUtils {
  public constructor() {}

  public isObject(arg: any) {
    return arg !== null && typeof arg === 'object';
  }
  arrayToObject(array: any) {
    array.reduce((obj: any, item: any) => {
      obj[item.id] = item;
      return obj;
    }, {});
  }

  getCharCount(str: any, letter: any) {
    let letterCount = 0;
    for (let position = 0; position < str.length; position++) {
      if (str.charAt(position) === letter) {
        letterCount += 1;
      }
    }
    return letterCount;
  }

  getKeyValuePairsFromArray(
    data: any[],
    conditionKey: string,
    conditionValue: any,
    extractKeys: any[]
  ) {
    if (conditionKey) {
      return data
        .filter((obj) => obj[conditionKey] === conditionValue)
        .map((obj) =>
          extractKeys.reduce(
            (acc: any, key: any) => ({ ...acc, [key]: obj[key] }),
            {}
          )
        );
    } else {
      return data.map((obj) =>
        extractKeys.reduce(
          (acc: any, key: any) => ({ ...acc, [key]: obj[key] }),
          {}
        )
      );
    }
  }

  getValuesFromArray(
    data: any[],
    conditionKey: string,
    conditionValue: any,
    extractKey: string
  ) {
    if (conditionKey) {
      return data
        .filter((obj) => obj[conditionKey] === conditionValue)
        .map((obj) => obj[extractKey]);
    } else {
      return data.map((obj) => obj[extractKey]);
    }
  }

  scrollToAppMain() {
    let ele = document.getElementById('appmain');
    if (ele) {
      ele.scrollTo(0, 0);
    }
  }

  removeDuplicates(arr: any, key: any): any {
    const uniqueKeys = new Set();
    const uniqueItemsMap = new Map();
    for (const item of arr) {
      const keyValue = item[key];
      if (!uniqueKeys.has(keyValue)) {
        uniqueKeys.add(keyValue);
        uniqueItemsMap.set(keyValue, item);
      }
    }
    return uniqueKeys || [];
  }

  removeDuplicatesByKey(arr: any, key: any, outputKey: any) {
    const uniqueValues = new Set();
    const result = [];

    for (const item of arr) {
      const keyValue = item[key];
      if (!uniqueValues.has(keyValue)) {
        uniqueValues.add(keyValue);
        if (outputKey) {
          result.push(item[outputKey]);
        } else {
          result.push(item);
        }
      }
    }

    return result;
  }

  removeDuplicateValues(arr: any, key: any) {
    const uniqueIds = new Set<number>();
    return arr.filter((obj: any) => {
      if (!uniqueIds.has(obj[key])) {
        uniqueIds.add(obj[key]);
        return true;
      }
      return false;
    });
  }

  getFileExt(asset: any) {
    return asset.assetName.substring(asset.assetName.lastIndexOf('.') + 1);
  }

  setDefaultImage(ele: any, defaultImage: string) {
    let eleRef = document.getElementById(ele);
    if (eleRef) {
      eleRef.setAttribute('src', defaultImage);
    }
  }

  removeClinetID(myArray: any) {
    if (myArray.length > 0) {
      return myArray.slice(1); // Returns a new Array without ClinetID
    }
    return myArray;
  }

  // function to create link of copied/moved folder received in the mail
  shareCopyFolderUrl(folderPath: any) {
    let editUrl = '';
    let clientId = '';
    if (folderPath !== undefined) {
      const segments = folderPath.split('/');
      clientId = segments[0];
      const cleanedSegments = this.removeClinetID(segments).filter(
        (segment: any) => segment !== ''
      );
      editUrl = cleanedSegments.join(',');
    }
    const shareLink =
      `${window.location.origin}` +
      APP_ROUTE.assets +
      `/?folderPath=${editUrl}` +
      `/` +
      clientId;
    return shareLink;
  }

  isValidSearch(searchText: any) {
    const pattern = new RegExp('\\s*\\S+(?: \\S+)*\\s*');
    return pattern.test(searchText);
  }

  // function to validate URLs
  async isValidUrl(url: string): Promise<boolean> {
    try {
      new URL(url);
      // Check if the resource exists
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch (e) {
      return false;
    }
  }

  /*validation for asset title and description*/
  assetInputValidator(
    maxLength: number
  ): (control: AbstractControl) => { [key: string]: any } | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value.length > maxLength) {
        return { maxLengthExceeded: true };
      }
      return null;
    };
  }

  getFileExtIcon(asset: any): string {
    return asset?.fileExt || 'default'; // Fallback to a default icon if no extension is found
  }

  // Validator to check for Lowercase
  hasLowercaseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasLowercase = /[a-z]/.test(control.value);
      return hasLowercase ? null : { missingLowercase: true };
    };
  }
  // Validator to check for Uppercase
  hasUppercaseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasUppercase = /[A-Z]/.test(control.value);
      return hasUppercase ? null : { missingUppercase: true };
    };
  }

  // Validator to check for digits
  hasDigitValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasDigit = /\d/.test(control.value);
      return hasDigit ? null : { missingDigit: true };
    };
  }

  // Validator to check for special characters
  hasSpecialCharacterValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasSpecialCharacter = /[!@#$%^&*]/.test(control.value);
      return hasSpecialCharacter ? null : { missingSpecialCharacter: true };
    };
  }

  pickKeys<T extends Record<string, any>>(
    source: Partial<T>,
    keys: (keyof T)[]
  ): Partial<T> {
    return keys.reduce((acc, key) => {
      if (key in source) {
        acc[key] = source[key];
      }
      return acc;
    }, {} as Partial<T>);
  }

  // filter the query params and return object without any empty or null values
  filterObject(params : any){
    const filteredObject = Object.fromEntries(
      Object.entries(params).filter(([key, value]) =>
        value !== null &&
        value !== undefined &&
        !(typeof value === 'string' && value.trim() === '') &&
        !(Array.isArray(value) && value.length === 0) &&
        !(typeof value === 'object' && Object.keys(value).length === 0)
      )
    );
    return filteredObject;
  }
}
