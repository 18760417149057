<header class="header-panel d-flex align-items-center">
  <nav class="navbar navbar-expand-lg w-100 navbar-dark">
    <div class="container-fluid">
      <!-- <a class="navbar-brand" href="#">Navbar</a> -->
      <div class="align-items-center d-flex header-nav-wrapper">
        <div class="header_logo">
          <a [routerLink]="appRouteUrl.home" class="navbar-brand text-white text-decoration-none">
            <span class="logo_img">
              <img src="assets/images/pages/header/jio-logo.svg" alt="jio-logo-image">
              <div class="logo_text">DAM</div>
            </span>
          </a>
        </div>
        <div>
          <button class="navbar-toggler" type="button" data-bs-auto-close="inside outside" data-bs-toggle="collapse"
            data-bs-target="#primarynavigation" aria-controls="primarynavigation" aria-expanded="false"
            aria-label="Toggle navigation" (click)="isMenuCollapsed = !isMenuCollapsed">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse navbar-collapse_custom" id="primarynavigation"
            [ngbCollapse]="isMenuCollapsed">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 navbar-space">
              @for (item of routerConfig; track item; let i = $index) {
              @if (item.isShowMenu) {
              <li class="nav-item dropdown nav-arrow" routerLinkActive="active" (click)="toggleSidenav(item)">
                <div>
                  @if (item.url === '') {
                  <div>
                    <a class="nav-link text-white dropdown-toggle" title="{{item.label}}" data-bs-toggle="dropdown"
                      aria-expanded="false" role="button">
                      {{item.label}}&nbsp;&nbsp;<span></span>
                    </a>
                    @if (item.isChildOpen) {
                    <ul class="dropdown-menu dropdown-menu-end">
                      @for (chlidItem of item.childs; track chlidItem) {
                      @if (chlidItem.isShowMenu) {
                      <li class="pointer">
                        @if (chlidItem.url) {
                        <div>
                          <a aria-hidden="true" routerLink="/{{chlidItem.url}}" (click)="redirectChild(chlidItem)"
                            class="dropdown-item" routerLinkActive="active"
                            (click)="isMenuCollapsed = true">{{chlidItem.label}}</a>
                        </div>
                        }
                      </li>
                      }
                      }
                    </ul>
                    }
                  </div>
                  } @else {
                  <a class="nav-link text-white" routerLink="/{{item.url}}" title="{{item.label}}"
                    routerLinkActive="active" (click)="isMenuCollapsed = true">
                    {{item.label}}
                  </a>
                  }
                </div>
              </li>
              }
              }
              <li class="nav-item dropdown nav-arrow d-lg-none">
                <a title="Clip List" class="nav-link text-white" routerLinkActive="active"
                  (click)="isMenuCollapsed = true"> Clip List </a>
              </li>
              <li class="nav-item dropdown nav-arrow d-lg-none">
                <a title="Collections" class="nav-link text-white" routerLinkActive="active"
                  (click)="isMenuCollapsed = true"> Collections </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="queueListToggleButton" class="d-flex align-items-center">
        @if (imageRevRequest.totalCount && permissions['imageReverseSearch']) {
        <div class="dropdown header-search-wrapper ms-lg-2 d-none d-lg-block">
          <button class="btn-search-dropdown p-0 btn-notification" type="button" title="Search by Images"
            (click)="maximizeGlobalUploadModal()">
            <span class="search-main" title="Upload Status">
              <i>
                <img src="assets/images/pages/header/ic_cloud.svg" alt="cloud_img_reverse_search" class="wh-icon">
              </i>
            </span>
            @if (imageRevRequest.totalCount === imageRevRequest.doneCount) {
            <img class="btn-notification-status" src="/assets/images/pages/header/succes.svg" alt="success">
            } @else {
            <svg class="btn-notification-progress" viewBox="0 0 100 100">
              <circle class="progress-ring" [attr.stroke-dasharray]="imageRevRequest.circumference"
                [attr.stroke-dashoffset]="imageRevRequest.donePercentage"
                [attr.transform]="'rotate(' + -90 + ' 50 50)' " cx="50" cy="50" r="40"></circle>
            </svg>
            }
          </button>
        </div>
        }
        <div class="dropdown notification-ui header-search-wrapper ms-lg-2 d-none d-lg-block">
          <span
            class="search-main clip-list-icon d-flex justify-content-center align-items-center dropdown-toggle notification-ui_icon"
            role="button" id="navbarDropdown" data-bs-toggle="dropdown" aria-bs-haspopup="true" aria-bs-expanded="false"
            title="Queue List" (click)="getQueueList()">
            <i class="d-flex"><img class="wh-icon" src="assets/images/pages/header/prime_list.svg"
                alt="prime list image"></i>
          </span>
          <div class="dropdown-menu notification-ui_dd dropdown-menu-end" aria-labelledby="navbarDropdown"
            #DropdownMenu>
            <div class="notification-ui_dd-content">
              @if (!(queueList.length)) {
              <div (click)="$event.stopPropagation()" class="notification-list notification-list--unread">
                <div class="d-inline-flex justify-content-start">
                  <div class="notification-list_detail">
                    <p>Queue List Empty</p>
                    <p>No Assets in queue list</p>
                  </div>
                </div>
              </div>
              }
              @for (item of queueList; track item) {
              <div class="notification-list notification-list--unread align-items-center"
                (click)="$event.stopPropagation()">
                <div class="d-inline-flex justify-content-start col-8">
                  <div class="notification-list_img">
                    <img src="/assets/images/pages/header/ic_zip.svg" alt="zip_icon">
                  </div>
                  <div class="notification-list_detail text-break">
                    <p>{{item.actionName}}</p>
                    @if (item.actionStatus===1 && item.actionType !=2) {
                    <p>Zipping Files | {{item.actionMessage}}</p>
                    }
                    <!-- <p *ngIf="item.actionStatus===1">Downloading ...</p> -->
                    @if (item.actionStatus===2 && item.actionType !=2) {
                    <p>Download Completed</p>
                    }
                    @if (item.actionStatus === 4 && item.actionType === 2) {
                    <p>Extracting</p>
                    }
                    @if (item.actionStatus === 0) {
                    <p>In Progress</p>
                    }
                    @if (item.actionStatus === 1 && item.actionType === 2) {
                    <p>Uploading zip file | {{item.actionMessage}}</p>
                    }
                    @if (item.actionStatus === 2 && item.actionType === 2) {
                    <p>Upload Complete</p>
                    }
                    @if (item.actionStatus===3) {
                    <p style="color: #BA0004;">Failed</p>
                    }
                  </div>
                </div>
                @if (item.actionStatus===1 || item.actionStatus===0 || item.actionStatus === 4) {
                <div class="notification-list_feature-img d-flex">
                  @if (item.actionStatus===1 || item.actionStatus===0 ) {
                  <div class="download-comp-ic"
                    [style.background]="'conic-gradient(#3aa316 ' + ((item.doneCount / item.totalCount)*3.6)*100 + 'deg, #f3f3f3 0deg)'">
                    <div class="inner-circle"></div>
                  </div>
                  }
                  <!-- Animated Loader for actionStatus 4 -->
                  @if (item.actionStatus === 4 || item.actionStatus === 0) {
                  <div class="loader"></div>
                  }
                  <img src="/assets/images/pages/header/remove-download.svg" (click)="deleteAssetFromQueue(item.id)"
                    alt="downloading">
                </div>
                }
                @if (item.actionStatus===2) {
                <div class="notification-list_feature-img d-flex">
                  <img src="/assets/images/icons/btn-select.svg" alt="downloading"
                    class="download-comp-ic mt-2 me-3 position-relative mt-n3">
                  <img src="/assets/images/pages/header/remove-download.svg" (click)="deleteAssetFromQueue(item.id)"
                    alt="downloading">
                </div>
                }
                @if (item.actionStatus===3) {
                <div class="notification-list_feature-img d-flex">
                  @if (item.actionType!=2) {
                  <img src="assets/images/icons/retry.svg"
                    (click)="retryDownload(item.id, item.assetArr, item.folderPath, item.folderId, item.downloadType)"
                    alt="retry" class="download-comp-ic mt-2 me-3 position-relative mt-n3">
                  }
                  <img src="/assets/images/pages/header/remove-download.svg" (click)="deleteAssetFromQueue(item.id)"
                    alt="downloading">
                </div>
                }
              </div>
              }

            </div>

          </div>
        </div>
        @if (!(_router.url===appRouteUrl.searchDetails || _router.url=== appRouteUrl.home)) {
        <div class="dropdown header-search-wrapper">
          <button class="btn-search-dropdown dropdown-toggle pe-0" type="button" id="dropdownSearchBar"
            (click)="handleOpenSearchBar()" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="search-main" title="Search">
              <i><img src="/assets/images/pages/header/ic_search_header.svg" alt="search_asset"
                  style="height:15px;"></i>
            </span>
          </button>
          <div class="search-form-main clearfix active-search dropdown-menu" aria-labelledby="dropdownSearchBar">
            <form role="search" class="search-form" autocomplete="off">
              <input type="search" class="search-field" placeholder="Search Asset" name="search" #searchtext>
              <input type="submit" value="" class="search-submit" (click)="getAssetsListSearch(searchtext.value)">
            </form>
          </div>
        </div>
        }
        @if (permissions['people'] || false) {
        <div class="dropdown header-search-wrapper ms-lg-2 d-none d-lg-block">
          <button class="btn-search-dropdown p-0" type="button" id="dropdownSearchBar" (click)="goToPeopleManagement()">
            <span class="search-main" title="People Management">
              <i><img src="assets/images/pages/header/people-management.svg" alt="people-management"
                  class="wh-icon"></i>
            </span>
          </button>
        </div>
        }
        <div class="dropdown header-search-wrapper ms-lg-2 d-none d-lg-block">
          @if (permissions['cliplist'] || false) {
          <button class="btn-search-dropdown p-0" type="button" id="dropdownSearchBar" (click)="goToCliplist()">
            <span class="search-main" title="Add to Cliplist">
              <i><img src="assets/images/pages/header/clip-list.svg" alt="clip-list" class="wh-icon"></i>
            </span>
          </button>
          }

        </div>
        @if (permissions['collection'] || false) {
        <div class="dropdown header-search-wrapper ms-lg-2 d-none d-lg-block">
          <button class="btn-search-dropdown p-0" type="button" id="dropdownSearchBar" (click)="goToCollections()">
            <span class="search-main" title="Add to Collection" [ngClass]="{'bg-danger': isAddedToCollection}">
              <i><img src="assets/images/pages/header/addcollections.svg" alt="addcollections image"
                  class="wh-icon"></i>
            </span>
          </button>
        </div>
        }
        <div class="text-end ms-2 d-flex">
          <div class="dropdown d-inline-flex profile_dropdown">
            <button
              class="user_icon btn bg-white dropdown-toggle d-inline-flex align-items-center justify-content-center"
              data-bs-toggle="dropdown" aria-expanded="false" id="dropdownMenuButton">
              {{getUserFirstName + getUserLastName}}
            </button>
            <ul class="dropdown-menu dropdown-menu-end pt-0" aria-labelledby="dropdownMenuButton">
              <li class="border-bottom dropdown-item loggedin-user-details px-3 py-2">
                <h5 class="w-100 mb-0">{{getUserName}}</h5>
                <span>{{isUserRole}}</span>
              </li>
              <li>
                @if (getUserRoleId === 1 && userDetails?.isOrgAdmin) {
                <a class="dropdown-item user-client" routerLinkActive="active" [routerLink]="appRouteUrl.clients">
                  Manage Organisation
                  <div class="badge">
                    {{userDetails?.clientName}}
                  </div>
                </a>
                } @else {
                @if (getUserAssignedClients?.length) {
                <a class="dropdown-item user-client current border-bottom">
                  <span class="user-client-name">{{userDetails?.clientName}}</span>
                  @if (getUserAssignedClients?.length >1) {
                  <img class="float-right cursorpointer" routerLinkActive="active" [routerLink]="appRouteUrl.clients"
                    src="/assets/images/pages/header/ic_swap.png" alt="ic swap image">
                  }
                </a>
                }
                }
              </li>
              <li><a class="dropdown-item" routerLinkActive="active" [routerLink]="appRouteUrl.profile">Profile</a></li>
              @if (userDetails.authId === 3 && !userDetails?.isOrgAdmin) {
              <li>
                <a class="dropdown-item" routerLinkActive="active" [routerLink]="appRouteUrl.changePassword">
                  Change Password
                </a>
              </li>
              }
              <li><a class="dropdown-item cursorpointer" (click)="showConfirmationPopUp()">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
<app-confirmationPopUp [show]="confirmationPopUpVisible" (onCancel)="closeConfirmationPopUp($event)"
  (onConfirm)="logout()"></app-confirmationPopUp>
