@if(subtitleOff && activeCue){
<div id="subtitleDisplay" #subtitleDisplay class="subtitle-display"></div>
}

<div class="media-controls d-flex">
    <div class="media-play" (click)="togglePlayPause()">
        @if(!mediaToggleFlag ){
        <img class="position-relative" style="height: 15px; width: 15px;"
            src="/assets/images/pages/asset-detail/ic_play.svg" />
        }@else{ <img class="position-relative" style="height: 15px; width: 15px;"
            src="/assets/images/pages/asset-detail/ic_pause.svg" />}
    </div>
    <div class="media-audio-setting d-flex gap-2" (mouseleave)="isVolumeVisible = false">
        <div>
            <button (click)="toggleVolume()" (mouseenter)="onVolumeHover()">
                @if(!isVolumeOn){<img class="position-relative" style="height: 15px; width: 15px;"
                    src="/assets/images/pages/asset-detail/ic_audio.svg" alt="">}
                @else { <img class="position-relative" style="height: 15px; width: 15px;"
                    src="/assets/images/pages/asset-detail/ic_no_audio.svg" />}
            </button>
        </div>
        @if(isVolumeVisible){
        <div class="volume-slider d-flex">
            <input type="range" min="0" max="100" [value]="currentVolumeVal" (click)="adjustVolume($event)">
        </div>
        }

    </div>
    <div class="media-track">
        <input type="range" min="0" [max]="videoDuration" [value]="currentTime" (input)="adjustTrack($event)">
        <div class="mx-2 media-length d-flex"><span>{{ convertTime(currentTime) }}</span>
            @if(isValidDuration(videoDuration)){/<span>{{convertTime(videoDuration)}}</span>}</div>
    </div>

    @if(!captionContainer){
    <div class="media-captions">
        <button (click)="toggleCaptionSelect()" class="caption-btn">CC</button>
        @if(isCaptionVisible){
        <div class="captions-options">
            @for(track of captionTrack;track track ){
            <div>
                <div class="caption-option" (click)="onTrackChange(track.name)"
                    [ngClass]="{'selected': selectedTrack === track.name}">
                    {{ track.name }}
                </div>
            </div>}
        </div>
        }
    </div>
    }
</div>