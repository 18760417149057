import {
  ElementRef,
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { AppService } from 'src/app/core/services/app.service';
import {
  FolderMgmtUtill,
  IFolderDetails,
} from 'src/app/common/utils/folderMgmtUtill';
import { GdriveShare } from 'src/app/common/gdriveshare/gdriveshare';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/core/services/loader.service';
import { FormatS3UrlPipe } from '../../pipes';
import { AppDataService, EventData, EventEmitterService, PermissionsEngine, RestService } from 'src/app/core/services';
import { CommonUtils } from 'src/app/common/utils/common.utils';
import { API_ENDPOINT, APP_EVENTS, APP_ROUTE, FOLDER_INPUT_DETAILS } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { NgClass } from '@angular/common';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { UploadComponent } from '../upload/upload.component';
import { BulkTagComponent } from '../bulk-tag/bulk-tag.component';

interface ITagMore {
  shortTagArrManual: Array<String>;
  longTagArrManual: Array<String>;
  showMoreManualTag: Boolean;
}

@Component({
    selector: 'app-custom-context-menu',
    templateUrl: './custom-context-menu.component.html',
    styleUrls: ['./custom-context-menu.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        ShareButtonsModule,
        UploadComponent,
        BulkTagComponent,
    ],
})
export class CustomContextMenuComponent {
  firstItem: IFolderDetails = {
    firstName: 'First',
    lastName: 'Folder',
    userId: 1,
    folderCount: '',
    assignedCount: '',
    parentId: 0,
    assetCounts: '',
    untaggedAssetsCount: '',
    roleId: 1,
    folderName: '',
    folderId: 0,
    isTaged: 0,
    createdAt: '',
    createByFirstname: '',
    createByLastname: '',
    accessType: 2,
    isCollapse: false,
    isFolderRightClick: false,
    children: [],
  };
  // Variables for integrate parent and child methods and data
  @Input() folderInputDetails: FOLDER_INPUT_DETAILS;
  @Output() onFolderRefresh = new EventEmitter();
  @Output() menuEmitter = new EventEmitter();
  @ViewChild('toggleFolderShareButton') toggleFolderShareButton: ElementRef;
  @ViewChild('toggleMultiShareButton') toggleMultiShareButton: ElementRef;

  assetArr: any = [];
  selectedAssets: any = [];
  isUploadModalClose: boolean = false;
  showAllAssetsActionButtons: boolean = false;
  allSelectedAssetsAreReady: any;
  myparams: any = {
    limit: 500,
    pageno: 1,
  };
  hasUploadPerm: Boolean = false;
  bulkTagAccess: Boolean = false;
  assetUploadPath: any;
  folderPath: any;
  multiSharePopUpIsOpen = false;
  contextmenu = false;
  tagArray: Array<ITagMore> = [];
  manualTagArray: Array<ITagMore> = [];
  urlStr: any;
  assetTag: [0, 1];
  isBulkAssetTag: boolean = false;
  eventName: string;
  folderSharePopUpIsOpen: boolean = false;
  shortTagArrManual: any = [];
  longTagArrManual: any = [];
  formatS3UrlPipe = new FormatS3UrlPipe();
  commonUtils = new CommonUtils();
  preSignedUrl: any = {};
  subscriptions: Subscription[] = [];
  private eventSubscription: Subscription | undefined;
  private shareListen: (() => void) | undefined;
  private contextListen: (() => void) | undefined;
  modalType: any;
  constructor(
    public permissionsEngine: PermissionsEngine,
    public appService: AppService,
    public authService: AuthService,
    private messageService: MessageService,
    private folderMgmt: FolderMgmtUtill,
    private gdriveShare: GdriveShare,
    private sanitise: DomSanitizer,
    private renderer: Renderer2,
    private loader: LoaderService,
    private restService: RestService,
    private router: Router,
    private appDataService: AppDataService,
    private eventEmitterService: EventEmitterService
  ) {
    this.appDataService.addAssetToSelectedAssets(new Map());
    //used this service to select store all selected assets in an array ie "allSelectedAssetsAreReady"
    this.subscriptions.push(this.appDataService.selectedAssetsByUser.subscribe(
      (data) => {
        if (data.size > 0) {
          this.showAllAssetsActionButtons = true;
          this.allSelectedAssetsAreReady = [...data.values()];
          this.selectedAssets = this.allSelectedAssetsAreReady;
          this.assetTag = this.selectedAssets[0].assetTag;
        } else if (data.size === 0) {
          this.showAllAssetsActionButtons = false;
          this.allSelectedAssetsAreReady = [];
          this.selectedAssets = this.allSelectedAssetsAreReady;
        } else {
          this.showAllAssetsActionButtons = false;
        }
      }
    ));
    this.shareListen = this.renderer.listen('window', 'click', (e: Event) => {
      if (
        this.toggleFolderShareButton &&
        e.target !== this.toggleFolderShareButton.nativeElement
      ) {
        this.folderSharePopUpIsOpen = false;
      }
      // Bug-Fix-122377 :: DAM : Admin : Share pop up does not disappears when we move image from one folder to another.
      if (
        this.toggleMultiShareButton &&
        e.target !== this.toggleMultiShareButton.nativeElement
      ) {
        this.multiSharePopUpIsOpen = false;
      }
    });
    // Bug-Fix-122377 :: DAM : Admin : Share pop up does not disappears when we move image from one folder to another.
    this.contextListen = this.renderer.listen('window', 'contextmenu', (e: Event) => {
      if (
        this.toggleFolderShareButton &&
        e.target !== this.toggleFolderShareButton.nativeElement
      ) {
        this.folderSharePopUpIsOpen = false;
      }
      if (
        this.toggleMultiShareButton &&
        e.target !== this.toggleMultiShareButton.nativeElement
      ) {
        this.multiSharePopUpIsOpen = false;
      }
    });
  }
  public appRouteUrl = APP_ROUTE;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['folderInputDetails']) {     
      if (this.folderInputDetails && Object.keys(this.folderInputDetails.folderDetails).length != 0) {
        this.assetUploadPath = this.folderInputDetails.folderDetails.id_path.substring(
          0,
          this.folderInputDetails.folderDetails.id_path.length - 1
        );
        this.hasUploadPerm =
          this.permissionsEngine.folderAccessOnHasPermission(
            this.folderInputDetails?.objectForSourceTrack.folderId,
            this.permissionsEngine.folderAccessType[1]
          ) &&
          this.folderInputDetails?.isCollapse &&
          this.selectedAssets.length < 1;
        this.bulkTagAccess = this.permissionsEngine.folderAccessOnHasPermission(
          this.folderInputDetails.objectForSourceTrack.folderId,
          this.permissionsEngine.folderAccessType[2]
        );
      }
       
    }
  }

  async ngOnInit() {
    this.subscribeToEvent();
    this.subscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Re-subscribe to the event if necessary when navigation ends
        if (!this.eventSubscription || this.eventSubscription.closed) {
          this.subscribeToEvent();
        }
      }
    }));
  }

  subscribeToEvent(): void {
    this.eventSubscription = this.eventEmitterService.subscribe((event: EventData) => {
      if (event.type === APP_EVENTS.BROWSER_BACK) {
        if (this.isUploadModalClose) {
          this.isUploadModalClose = false;
          this.emptyFileList();
          this.router.navigate([this.appRouteUrl.assets], { replaceUrl: true });
        }
      }
    });
    this.subscriptions.push(this.eventSubscription);
  }
  menuSelection(menu: any) {
    switch (menu) {
      case 'download':
        this.downloadMultipleAssets();
        break;
      case 'bulkTagAsset':
        this.bulkTagAsset();
        break;
      case 'share':
        this.menuEmitter.emit('share');
        break;
      case 'clearAll':
        this.clearAllAssests();
        break;
      default:
        break;
    }
  }


  downloadMultipleAssets() {
    let FolderPath: any = {};
    let assetId: any = [];
    if (this.selectedAssets && this.selectedAssets.length) {
      for (let item of this.selectedAssets) {
        this.assetArr.push(
          this.appService.s3BaseUrl + item.assetPath + '/' + item.assetName
        );
        assetId.push(item.id);
      }
      let path = this.selectedAssets[0].assetPath;
      FolderPath.Ids = path.split('/');
      FolderPath.Ids = this.commonUtils.removeClinetID(FolderPath.Ids);
      FolderPath.Url = this.shareFolderUrl();
    }

    const params = {
      assetArr: this.assetArr,
      OriginPath: FolderPath,
    }

    const options = {
      responseType: 'blob'
    }

    this.restService
      .post(`${API_ENDPOINT.baseEndpoint}${this.authService.getAuthData()?.clientId}/${API_ENDPOINT.assets.multiDownload}`, params, options, false)
      .subscribe({
        next: (res: any) => {
          this.selectedAssets = [];
          let params = { fileDetails: this.folderInputDetails.objectForSourceTrack };
          this.onFolderRefresh.emit(params);
          this.folderMgmt.setOpenQueueTrigger(true);
          this.messageService.add({
            severity: 'success',
            summary: 'Success!',
            detail: 'Zip will be downloaded and will be sent to you via email.',
          });
          this.loader.setLoading(false);
        },
        error: (error: any) => {
          this.selectedAssets = [];
          this.appDataService.addAssetToSelectedAssets(new Map());
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
    this.logs(assetId, "ASSETDOWNLOAD");
    this.assetArr = [];
  }
  //================= cancel btn click while assets upload============
  emptyFileList() {
    this.selectedAssets = [];
    let tempParentFolder: IFolderDetails = this.folderInputDetails.objectForSourceTrack;
    while (tempParentFolder.parentId != undefined && tempParentFolder.parentId != 0) {
      tempParentFolder = this.folderMgmt.getTrackRecord(
        tempParentFolder.parentId
      );
      tempParentFolder.isCollapse = true;
    }
    this.firstItem.children.forEach((item1: any) => {
      item1.folderId === tempParentFolder.folderId
        ? tempParentFolder.isCollapse === true
        : (item1.isCollapse = false);
    });
    this.appDataService.notifyChange(this.folderInputDetails.objectForSourceTrack);
    this.appDataService.addAssetToSelectedAssets(new Map());
  }
  //method to bulk tag assets
  bulkTagAsset() {
    this.isBulkAssetTag = true;
    //clear ManualTagArray
    this.manualTagArray = [];
    if (this.selectedAssets && this.selectedAssets.length) {
      this.selectedAssets.forEach((el: any) => {
        //populate ManualTagArray
        this.manualTagArray.push({
          shortTagArrManual:
            el.manualTags != undefined
              ? el.manualTags.length > 2
                ? [...el.manualTags].sort().splice(0, 2)
                : [...el.manualTags].sort()
              : [],
          longTagArrManual:
            el.manualTags != undefined ? [...el.manualTags].sort() : [],
          showMoreManualTag: false,
        }); //manualTagArray.push()
      });
    }
  }


  eventSelection(event: any) {
    this.eventName = event.eventName;
  }




  // Method to clean the component — for example, to cancel background tasks.
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    if(this.shareListen) {
      this.shareListen();
    }
    if(this.contextListen) {
      this.contextListen();
    }
  }
  openAssetsModal(modalType: any) {
    this.modalType = modalType;
    this.isUploadModalClose = true;
  }
  closeModalEvent(event: any) {
    this.isUploadModalClose = false;
    if (event && this.modalType === 'zip') {
      let params = {
        fileDetails: this.folderInputDetails.objectForSourceTrack,
        zipUpload: 'zipUpload',
        folderId: event.folderId,
      };
      this.onFolderRefresh.emit(params);
    } else if (event && this.modalType === 'image') {
      let params = {
        fileDetails: this.folderInputDetails.objectForSourceTrack,
        folderId: event.folderId,
        assetUploadCount: event.assetUploadCount,
      };
      this.onFolderRefresh.emit(params);
    }
  }

  //====================FUNCTION TO SAVE SHARE LINK HISTORY =============================
  saveShareLink() {
    let assetArr: any = [];
    let assetId: any = [];
    let path = this.selectedAssets[0].assetPath;
    let folderPath = path.split('/');
    folderPath = this.commonUtils.removeClinetID(folderPath);
    for (let item of this.selectedAssets) {
      let assetArrObj = {
        assetId: item.id,
        folderId: Number(folderPath[folderPath.length - 1])
      }
      assetArr.push(assetArrObj);
      assetId.push(item.id);
    }
    const params = {
      assetArr: assetArr,
      OriginPath: { Ids: folderPath },
    };
    //call saveShareLink API to save the history of shared assets
    this.restService.post(`${API_ENDPOINT.baseEndpoint}${this.authService.getAuthData()?.clientId}/${API_ENDPOINT.assets.saveShareLink}`, params).subscribe({
      next: (data: any) => {
        return;
        //this.downloadHistoryList = data.result;
      },
      error: (error: any) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: error.error.message,
        });
      },
    });
    this.logs(assetId, "ASSETSHARE");
  }
  ShareMultipleSelectedAssetsOnMail() {
    let selectedAssetUrlsArray: any = [];
    this.selectedAssets.forEach((asset: any) => {
      selectedAssetUrlsArray.push(this.preSignedUrl[asset.id]);
    });
    // Convert the array of URLs to a string with line breaks
    const selectedAssetUrlsString = selectedAssetUrlsArray.join('\n');
    // Encode the URL string
    const encodedUrls = encodeURIComponent(selectedAssetUrlsString);
    // Generate the mailto link
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      'Share'
    )}&body=${encodedUrls}`;

    // Open the mailto link in a new browser window or tab
    window.open(mailtoLink, '_blank');
  }
  getAssertUrlsShareMultipleSelectedAssets() {
    if (this.selectedAssets == undefined) return '';
    let selectedAssetUrls = '';
    this.selectedAssets.forEach((asset: any) => {
      if (asset.isSelected && this.preSignedUrl[asset.id]) {
        selectedAssetUrls =
          selectedAssetUrls + this.preSignedUrl[asset.id];
        selectedAssetUrls = selectedAssetUrls + '\n\n';
      }
    });
    return selectedAssetUrls;
  } //getAssertUrlsShareMultipleSelectedAssets

  async ShareMultipleSelectedAssetsOnGdrive() {
    let sharedAssetsId: any = [];
    this.selectedAssets.forEach((asset: any) => {
      sharedAssetsId.push(asset);
    });
    await this.appDataService.shareAssetsOnGDrive(sharedAssetsId, this.preSignedUrl);
  } //ShareMultipleSelectedAssetsOnGdrive

  yearDayMonthHoursMinutes() {
    // Create a new Date object using the timestamp
    const timestamp = Date.now();
    const dateObj = new Date(timestamp);

    // Extract the individual date and time components
    const year = dateObj.getFullYear().toString().substr(-2);
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');

    // Generate the desired timestamp format
    const formattedTimestamp = `${year}${day}${month}_${hours}${minutes}`;
    return formattedTimestamp;
  }

  // =========== Function to preview document =============
  cleanUrl(url: any) {
    let str = this.formatS3UrlPipe.transform(
      this.appService.s3BaseUrl + url.assetPath + '/' + url.assetName
    );
    this.urlStr = this.sanitise.bypassSecurityTrustResourceUrl(
      'https://docs.google.com/gview?url=' + str
    );
    window.open(this.urlStr.changingThisBreaksApplicationSecurity, '_blank');
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.stopPropagation();
      const element = event.target as HTMLElement;
      element.blur();
      element.focus();
    }
  }
  clearAllAssests() {
    if (this.selectedAssets && this.selectedAssets.length) {
      for (let item of this.selectedAssets) {
        if (item.isSelected == true) item.isSelected = false;
      }
      let params = { fileDetails: this.folderInputDetails.objectForSourceTrack };
      this.onFolderRefresh.emit(params);
    }
    this.multiSharePopUpIsOpen = false;
  }
  onFolderShare() {
    this.folderSharePopUpIsOpen = !this.folderSharePopUpIsOpen;
  }

  shareFolderUrl() {
    let editUrl = '';
    if (this.assetUploadPath !== undefined) {
      editUrl = this.assetUploadPath.split('/');
      editUrl = this.commonUtils
        .removeClinetID(editUrl)
        .filter((segment: any) => segment !== '')
        .join(',');
    }
    let shareLink =
      window.location.origin +
      this.appRouteUrl.assets +
      '/' +
      '?folderPath=' +
      editUrl;
    return shareLink;
  }

  //*bugFix Bug 181244: DAM : ADMIN : Share tray is enabled when we select only one asset.
  Checkmultipleseledcted() {
    if (this.selectedAssets.length < 2) {
      if (this.multiSharePopUpIsOpen) {
        this.multiSharePopUpIsOpen = false;
      }
      return false;
    } else {
      return true;
    }
  }
  //*bugFix Bug 181244: DAM : ADMIN : Share tray is enabled when we select only one asset.




  async checkingForPreSignedUrl() {
    if (this.selectedAssets == undefined) return '';
    let selectAssetIds: any = [];
    this.selectedAssets.forEach((asset: any) => {
      selectAssetIds.push(asset.id);
    })
    this.preSignedUrl = await this.appDataService.generatePreSignedUrl(selectAssetIds, this.preSignedUrl);
  }

  logs(assetId: any, action: any) {
    const logs = {
      data: {
        assetId: assetId
      },
      action: action
    }
    this.appDataService.serverLog(logs);
  }

  async closeBulkTagModal(event: any) {
    if (event) {
      this.onFolderRefresh.emit(event);
    }
    this.isBulkAssetTag = false;
    this.selectedAssets = [];
  }
}
