import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import * as fromServices from './services';
import { TokenInterceptor } from './interceptors/token.interceptor';
const interceptors = [TokenInterceptor];

@NgModule({ declarations: [],
    exports: [], imports: [CommonModule], providers: [...fromServices.services, ...interceptors, provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
