import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatS3Url',
    standalone: true,
})
export class FormatS3UrlPipe implements PipeTransform {
  transform(url: any, ...args: unknown[]): any {
    try {
      const urls = new URL(url);
      const hurl =
        urls.origin +
        url
          .split(urls.origin)
          .filter(Boolean)
          .join('')
          .split('/')
          .map((v: any) => encodeURIComponent(v))
          .join('/');
      return hurl;
    } catch (error) {
      return url;
    }
  }
}
