import { provideRouter, Routes } from '@angular/router';
import { AuthGuard, LoginGuard } from './core/guards';
import { AdminComponent } from './layout/admin/admin.component';
import { AssetNotFoundComponent } from './common/components/asset-not-found/asset-not-found.component';
import { AccessDeniedComponent } from './shared/components';


export const appRoutes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./theme/auth/auth.routing').then((m) => m.authRoutes),
    canActivate: [LoginGuard]
  },
  {
    path: 'main',
    component: AdminComponent ,
    loadChildren: () =>
      import('./theme/main/main.routing').then((m) => m.mainRoutes),
    canActivate: [AuthGuard]
    
  },
  {
    path: 'client',
    data: { moduleSlug: 'client-manager' },
    loadChildren: () =>
      import('./theme/client/client-routes').then((m) => m.clientRoutes),
  },
  { path: 'asset-not-found', component: AssetNotFoundComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: '**', component: AccessDeniedComponent },
];

export const appRouting = provideRouter(appRoutes);
