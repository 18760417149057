<div class="search-input-container">
  <div class="search-input-group w-100 position-relative radius-8">
    <input id="searchInputEle" type="text" class="search_input h-auto" aria-label="search"
      aria-describedby="basic-addon1" [placeholder]="placeholder" [(ngModel)]="searchText" #searchInputEle="ngModel"
      (ngModelChange)="onSearchChange()" (keyup.enter)="onSearchTextChanged(searchInputEle)" [disabled]="disable"
      autocomplete="off" />
      <div class="search_action cursorpointer" (click)="disable? '' : onSearchTextChanged(searchInputEle)">
        <span class="search-icon">
          <img src="/assets/images/icons/ic_search.svg" style="height: 24px;" alt="Search" />
        </span>
      </div>
      @if (searchText) {
        <div class="search_action cursorpointer" (click)="reset(searchInputEle)">
          <img src="/assets/images/icons/icon-close-black.svg" alt="clear">
        </div>
      }
    </div>
  </div>