import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuItems } from 'src/app/common/menu-items/menu-items';
import { CommonUtils } from 'src/app/common/utils/common.utils';
import { APP_EVENTS, APP_ROUTE } from 'src/app/core/constants';
import {
  AuthService,
  EventData,
  EventEmitterService,
  PermissionsEngine,
} from 'src/app/core/services';
import { HeaderComponent } from 'src/app/shared/components';
import { UploadComponent } from 'src/app/theme/main/components/upload/upload.component';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  standalone: true,
  imports: [HeaderComponent, RouterOutlet, UploadComponent],
})
export class AdminComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  showUploadModal: boolean;
  isGlobalUploadModalVisible: boolean = true;
  commonUtils = new CommonUtils();
  dataLoaded: boolean;

  constructor(
    private authService: AuthService,
    private menuItems: MenuItems,
    private router: Router,
    private permissionsEngine: PermissionsEngine,
    private eventEmitterService: EventEmitterService
  ) {}

  dummyData = {
    home: {
      active: true,
      name: 'Home',
      code: 'HOMES',
      checked: true,
      groupBy: '',
      subFeatures: {},
      actions: {},
    },
    users: {
      active: true,
      name: 'User',
      code: 'USERS',
      checked: true,
      groupBy: 'manage',
      subFeatures: {},
      actions: {
        '1': {
          active: true,
          label: 'View',
          checked: false,
        },
        '2': {
          active: true,
          label: 'Create',
          checked: false,
        },
        '3': {
          active: true,
          label: 'Edit',
          checked: false,
        },
        '4': {
          active: true,
          label: 'Delete',
          checked: false,
        },
      },
    },
    roles: {
      active: true,
      name: 'Roles',
      code: 'ROLES',
      checked: true,
      groupBy: 'manage',
      subFeatures: {},
      actions: {
        '1': {
          active: true,
          label: 'View',
          checked: false,
        },
        '2': {
          active: true,
          label: 'Create',
          checked: false,
        },
        '3': {
          active: true,
          label: 'Edit',
          checked: false,
        },
        '4': {
          active: true,
          label: 'Delete',
          checked: false,
        },
      },
    },
    groups: {
      active: true,
      name: 'Groups',
      code: 'GROUPS',
      checked: true,
      groupBy: 'manage',
      subFeatures: {},
      actions: {
        '1': {
          active: true,
          label: 'View',
          checked: false,
        },
        '2': {
          active: true,
          label: 'Create',
          checked: false,
        },
        '3': {
          active: true,
          label: 'Edit',
          checked: false,
        },
        '4': {
          active: true,
          label: 'Delete',
          checked: false,
        },
      },
    },
    collections: {
      active: true,
      name: 'Collections',
      code: 'COLLECTIONS',
      checked: true,
      groupBy: 'manage',
      actions: {
        '1': {
          active: true,
          label: 'View',
          checked: false,
          subFeatures: {},
        },
        '5': {
          active: true,
          label: 'Create and Add',
          checked: false,
          subFeatures: {},
        },
        '6': {
          active: true,
          label: 'Download',
          checked: false,
          subFeatures: {},
        },
        '7': {
          active: true,
          label: 'Delete Collection',
          checked: false,
          subFeatures: {},
        },
      },
    },
    events: {
      active: true,
      name: 'Event',
      code: 'EVENTS',
      checked: true,
      groupBy: 'manage',
      actions: {
        '1': {
          active: true,
          label: 'View',
          checked: false,
          subFeatures: {},
        },
        '2': {
          active: true,
          label: 'Create',
          checked: false,
          subFeatures: {},
        },
        '3': {
          active: true,
          label: 'Edit',
          checked: false,
          subFeatures: {},
        },
      },
    },
    tags: {
      active: true,
      name: 'Tags',
      code: 'TAGS',
      checked: true,
      groupBy: 'manage',
      subFeatures: {},
      actions: {
        '1': {
          active: true,
          label: 'View',
          checked: false,
        },
        '3': {
          active: true,
          label: 'Edit',
          checked: false,
        },
      },
    },
    folders: {
      active: true,
      name: 'Folders',
      code: 'FOLDERS',
      checked: true,
      groupBy: '',
      actions: {
        '8': {
          active: true,
          label: 'Create Root Folder',
          checked: false,
        },
      },
      subFeatures: {},
    },
    search: {
      active: true,
      name: 'Search',
      code: 'SEARCH',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    dashboard: {
      active: true,
      seqeunce: 1,
      name: 'Dashboard',
      code: 'DASHBOARD',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    cliplist: {
      active: true,
      name: 'Cliplist',
      code: 'CLIPLIST',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    category: {
      active: true,
      name: 'Categories',
      code: 'CATEGORIES',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    faceFinder: {
      active: true,
      name: 'Face Finder',
      code: 'FACEFINDER',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    image_reverse_search: {
      active: true,
      name: 'Image Reverse Search',
      code: 'IRS',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    people_management: {
      active: true,
      name: 'People Management',
      code: 'PEOPLE_MANAGE',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    ai_tags: {
      active: true,
      name: 'AI Tags',
      code: 'AITAGS',
      checked: true,
      groupBy: 'ai',
      actions: {},
      subFeatures: {},
    },
    object_detection: {
      active: true,
      name: 'Object Detection',
      code: 'OBJECT_DETECTION',
      checked: true,
      groupBy: 'ai',
      actions: {},
      subFeatures: {},
    },
    text_detection: {
      active: true,
      name: 'Text Detection',
      code: 'TEXT_DETECTION',
      checked: true,
      groupBy: 'ai',
      actions: {},
      subFeatures: {},
    },
    landmark_detection: {
      active: true,
      name: 'Landmark Detection',
      code: 'LAND_DETECTION',
      checked: true,
      groupBy: 'ai',
      actions: {},
      subFeatures: {},
    },
    transcripts_and_insights: {
      active: true,
      name: 'Transcripts & Insights',
      code: 'TRANS_DETECTION',
      checked: true,
      groupBy: 'ai',
      actions: {},
      subFeatures: {},
    },
  };

  menuMaster = {
    home: {
      route: '/main/home',
      label: 'Home',
      menuType: 'link',
      sequence: 1,
      pos: 'left',
      isPage: true,
      viewType: 'text',
    },
    dashboard: {
      route: '/main/home',
      menuType: 'link',
      label: '',
      sequence: 2,
      pos: 'left',
      isPage: true,
      viewType: 'text',
    },
    manage: {
      route: '/main/home',
      menuType: 'sub',
      label: 'Management',
      sequence: 3,
      pos: 'left',
      isPage: true,
      viewType: 'text',
    },
    users: {
      route: '/main/home',
      menuType: 'link',
      label: '',
      sequence: 1,
      pos: '',
      isPage: true,
      viewType: 'text',
    },
    roles: {
      route: '/main/home',
      menuType: 'link',
      label: '',
      sequence: 2,
      pos: '',
      isPage: true,
      viewType: 'text',
    },
    groups: {
      route: '/main/home',
      menuType: 'link',
      label: '',
      sequence: 3,
      pos: '',
      isPage: true,
      viewType: 'text',
    },
    collections: {
      route: '/main/home',
      menuType: 'link',
      label: '',
      sequence: 4,
      pos: '',
      isPage: true,
      viewType: 'text',
    },
    events: {
      route: '/main/home',
      menuType: 'link',
      label: '',
      sequence: 5,
      pos: '',
      isPage: true,
      viewType: 'text',
    },
    tags: {
      route: '/main/home',
      menuType: 'link',
      label: '',
      sequence: 6,
      pos: '',
      isPage: true,
      viewType: 'text',
    },
    folders: {
      route: '/main/assets',
      menuType: 'link',
      label: 'Assets',
      sequence: 4,
      pos: 'left',
      isPage: true,
      viewType: 'text',
    },
    search: {
      route: '/main/home',
      menuType: 'link',
      label: '',
      sequence: 5,
      pos: 'right',
      isPage: true,
      viewType: 'icon',
    },
    cliplist: {
      route: '/main/home',
      menuType: 'link',
      label: '',
      sequence: 6,
      pos: 'right',
      isPage: true,
      viewType: 'icon',
    },
    category: {
      route: '/main/home',
      menuType: 'link',
      label: '',
      sequence: 7,
      pos: 'right',
      isPage: true,
      viewType: 'icon',
    },
    faceFinder: {
      route: '/main/home',
      menuType: 'link',
      label: '',
      sequence: 8,
      pos: 'right',
      isPage: true,
      viewType: 'icon',
    },
    image_reverse_search: {
      route: '/main/home',
      menuType: 'link',
      label: '',
      sequence: 9,
      pos: 'right',
      isPage: true,
      viewType: 'icon',
    },
    people_management: {
      route: '/main/home',
      menuType: 'link',
      label: '',
      sequence: 10,
      pos: 'right',
      isPage: true,
      viewType: 'icon',
    },
    ai_tags: {
      isPage: false,
    },
    object_detection: {
      isPage: false,
    },
    text_detection: {
      isPage: false,
    },
    landmark_detection: {
      isPage: false,
    },
    transcripts_and_insights: {
      isPage: false,
    },
  };

  ngOnInit() {
    // temp changes
    // this.processUserSettings(this.dummyData);
    this.subscriptions.push(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.commonUtils.scrollToAppMain();
        }
      }),
      this.eventEmitterService.subscribe((event: EventData) => {
        switch (event.type) {
          case APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD:
            this.showUploadModal = event.data.display;
            this.isGlobalUploadModalVisible = event.data.visible;
            break;
        }
      })
    );
    if (this.authService.getAuthData()?.isOrgAdmin) {
      this.router.navigate([APP_ROUTE.clients]);
    } else {
      this.permissionsEngine.setup().then((date) => {
        this.dataLoaded = true;
      });
    }
  }

  processUserSettings(userSettings: any) {
    if (userSettings) {
      const result = this.menuItems.transformData(
        userSettings,
        this.menuMaster
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
