import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { lastValueFrom, Subscription } from 'rxjs';
import { API_ENDPOINT, APP_EVENTS, ASSET_TYPE_CONFIG, CONFIRMATION_POPUP_CONFIG, UPLOAD_MESSAGES } from 'src/app/core/constants';
import {
  AuthService,
  EventData,
  EventEmitterService,
  RestService,
  S3UploadServices,
} from 'src/app/core/services';
import pLimit from 'p-limit';
import { AppDataService } from '../../../../core/services/app.data.service';
import { CommonUtils } from 'src/app/common/utils/common.utils';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { FormatS3UrlPipe } from 'src/app/shared/pipes';
import { DialogModule } from 'primeng/dialog';
import { NgStyle } from '@angular/common';
import { ConfirmationPopUpComponent } from '../../../../shared/components/deletepopup/confirmationPopUp.component';
import { ToastModule } from 'primeng/toast';
import { FormatBytesPipe } from '../../../../shared/pipes/format-bytes.pipe';

interface FileUpload {
  file: File;
  status:  0 | 1 | 2 | 3 | 4;  //'pending' | 'uploading' | 'uploaded' | 'error'  | 'deleted'
  message: 0 | 1 | 2 | 3;   //'InQueue | alreadyExist | Unsupported | InternalServerError'
  progress: number;
  data: {}
}
@Component({
    selector: 'app-global-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss'],
    providers: [MessageService],
    standalone: true,
    imports: [
        DialogModule,
        NgStyle,
        ConfirmationPopUpComponent,
        ToastModule,
        FormatBytesPipe,
    ],
})
export class UploadComponent implements OnInit, OnDestroy {
  @Input() type: string = 'add';
  @Input() folder: any;
  private subscriptions: Subscription[] = [];
  isVisible: boolean = true;
  selectedFilesList: Map<string, FileUpload> = new Map();
  selectedFiles: any[] = [];
  selectedFileNameArray: any = [];
  uploadDone: boolean = false;
  message: string[] = [];
  progressList: any = [];
  progressOfUplodingFile: any = [];
  internalServerErrorWithFile: any = [];
  fileAlreadyExistsOrError: any = [];
  successFileNameArray: any = [];
  unSupportedFile: any = [];
  success: boolean = false;
  successFileDetails: any = [];
  userProfile: any;
  imageRevData: any;
  view = {
    upload: true,
    status: false,
  };
  confirmationModal: boolean;
  totalAssetSize: number = 0;
  dataRefresh: boolean;
  isUploading: boolean;
  formatS3UrlPipe = new FormatS3UrlPipe();
  commonUtils = new CommonUtils();
  uploadMessage = UPLOAD_MESSAGES;
  count = {
    Total : 0,
    Success : 0,
    Failed : 0
  }

  constructor(
    private eventEmitterService: EventEmitterService,
    private s3UploadService: S3UploadServices,
    private appDataService: AppDataService,
    private router: Router,
    private messageService: MessageService,
    private restService: RestService,
    public authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.appDataService.getUserDetails().subscribe({
      next: (user: any) => {
        this.userProfile = user.result;
        if (this.userProfile?.isRequestForImageRevSearch) {
          this.setView('status');
          this.getImageRevStatus();
        }
      },
    });
    this.subscriptions.push(
      this.eventEmitterService.subscribe((event: EventData) => {
        switch (event.type) {
          case APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD:
            this.isVisible = event.data.visible;
            if (this.isVisible && this.view.status) {
              this.getImageRevStatus();
            }
            break;
        }
      }));
  }

  setView(view: string) {
    this.view.upload = view === 'upload';
    // Bug fix 125293
    // if (this.view.upload) {
    // this.totalAssetSize = 0;
    //}
    this.view.status = view === 'status';
  }

  selectFiles(event: any) {
    const inputTag = event.target;
    this.progressOfUplodingFile = [];
    const processFilesChunk = async (
      files: any[],
      startIndex: number,
      chunkSize: number
    ) => {
      const endIndex = Math.min(startIndex + chunkSize, files.length);
      const filesToProcess = files.slice(startIndex, endIndex);
      await Promise.all(filesToProcess.map(processFile));
      if (endIndex < files.length) {
        await processFilesChunk(files, endIndex, chunkSize);
      }
    };

    const processFile = async (file: any) => {
      try {
        let fileExists: any = this.selectedFilesList.get(file.name);
  
        // If the file already exists and is deleted, remove it from the list
        if (fileExists?.status === 4) {
          this.selectedFilesList.delete(file.name);
          fileExists = 0;
        }

        if (!fileExists) {
          let type = file.type;
          if (
            type === 'image/png' ||
            type === 'image/jpeg' ||
            type === 'image/jpg' ||
            type === 'image/WEBP' ||
            type === 'image/webp'
          ) {
            this.totalAssetSize += file.size;
            this.selectedFilesList.set(file.name, {
              file,
              status: 0, // Pending
              message: 0, // In Queue
              progress: -1, // Initial progress
              data: {}
            });
            this.count.Total ++ ;
            this.uploadDone = false;
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Error!',
              detail: 'File not supported',
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleFiles = () => {
      if (event && event.target && event.target.files.length > 0) {
        const files = Array.from(event.target.files);
        const chunkSize = 5;
        processFilesChunk(files, 0, chunkSize);
      } else {
        // let dragArray: any = Array.from(event);
        // this.selectedFiles.push(dragArray);
        // this.selectedFiles = Array.prototype.concat.apply(
        //   [],
        //   this.selectedFiles
        // );
      }
    };
    this.setView('upload');
    handleFiles();
    event = null;

    inputTag.value = '';
  }

  async uploadFiles() {
    this.message = [];

    if (this.selectedFilesList.size === 0) {
      this.message.push('File Required');
      return;
    }

    const batchSize = 25; // Set batch size to 25
    const limit = pLimit(batchSize); // Limit concurrency to 25 uploads

    let i = 0;

    // Function to upload a single file and handle errors
    const uploadFile = async (file: any, index: any, data: any) => {
      try {
        this.isUploading = true;
        if (this.uploadDone) {
          if (file.status == 3 && file.message == 3) {
            this.count.Failed--;
            file.status = 1;
            return await this.upload(file, data);
          }
        } else {
          if (file.status == 0) {
            file.status = 1;
            return await this.upload(file, data);
          }
        }
      } catch (error) {
        let obj = {
          file: file,
          status: '',
          message: '',
        };
        this.isUploading = false;
      }
      return null;
    };

    // Loop through the map to upload files in batches
    const mapIterator = this.selectedFilesList.values(); // Get an iterator for the file objects

    while (i < this.selectedFilesList.size) {
      const batch: any[] = [];
      let count = 0;
      let assetParams: any[] = [];

      while (count < batchSize && i < this.selectedFilesList.size) {
        const file = mapIterator.next().value;
        if (file && file.file != null && file.status != 2) {
          batch.push(file);
          const params = {
            assetName: file.file.name,
            assetPath: 'TempAssets',
            mimetype: file.file.type,
            assetSize: file.file.size
          };
          assetParams.push(params);
          count++;
        }
        i++;
      }
      const uploadType = ASSET_TYPE_CONFIG.RIS ;

      let response: any;
      try {
        response = await this.s3UploadService.getPreSignedUrl(uploadType, 'TempAssets', assetParams);
      } catch (err: any) {
        // Handle the error 
        console.error('Error fetching pre-signed URLs:', err);
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: err.error.message || "Something Went Wrong!",
        });
        batch.forEach((file) => {
          if (file.status != 3) {
            this.count.Failed++;
            file.status = 3;
            file.message = 3;
          }
        });
        continue; // Skip this batch due to pre-signed URL error
      }

      const uploadBatchPromises = batch.map((file, index) => {
        return limit(() => uploadFile(file, i + index, response));
      });

      await Promise.all(uploadBatchPromises); // Wait for the current batch to finish
    }

    this.selectedFilesList = new Map(
      [...this.selectedFilesList.entries()].sort((a, b) => {
        if (a[1].status === 3 && b[1].status !== 3) {
          return -1;  // a should come first
        } else if (a[1].status !== 3 && b[1].status === 3) {
          return 1;   // b should come first
        } else {
          return a[1].status - b[1].status;  // Otherwise, sort by status
        }
      })
    );
    this.uploadDone = true;
    this.isUploading = false;
  }

  //----------------------------Single Asset Uplaod Function---------------------------------
  async upload(file: any, data: any) {
    return new Promise(async (resolve) => {
      const asset = file.file;

      if (file) {
        try {
          let event = await this.s3UploadService.uploadFile(asset, file, data);
          if (event.code === 200) {
            const data = event.result;
            if (data[asset.name].status == true) {
              file.status = 2;
              file.data =  data[asset.name].data;
              this.count.Success ++ ;
              file.file = {size : asset.size};
              resolve((this.success = true));
            } else if (data[asset.name].error == "Asset already present"){
              file.status = 3;
              file.message = 1;
              this.count.Failed ++;
              resolve(false);
            }
            else{
              file.status = 3;
              file.message = 4;
              this.count.Failed++;
              resolve(false);
            }
          } else if (event.error) {
            file.status = 3;
            this.handleUploadError(event, file);
            this.count.Failed ++;
            resolve(false);
          } else {
            file.status = 3;
            file.message = 3;
            this.handleUnknownError(file);
            this.count.Failed ++;
            resolve(false);
          }
          file.progress = -1;
        } catch (error) {
          console.error(error);
          file.status = 3;
          file.progress = -1;
          this.handleUnknownError(file);
          resolve(false);
        }
      }
    });
  }

  removeFileFromList(fileList: any[], fileName: string) {
    const index = fileList.indexOf(fileName);
    if (index !== -1) {
      fileList.splice(index, 1);
    }
  }

  handleUploadError(event: any, file: any) {
    const { code, message } = event.error;
    this.fileAlreadyExistsOrError.push(file.name);

    if (code === 400) {
      file.message = 2;
    } else if (code === 500) {
      file.message = 3;
    } else {
      const msg = message === 'Request Entity Too Large.'
        ? 'Please do not upload over 10 files at once.'
        : 'Something went wrong. Could not upload the file(s), please check your internet connection and try uploading again';
      file.message = 3 ;
      this.message.push(msg);
    }
  }

  handleUnknownError(file: any) {
    const msg = 'Something went wrong. Could not upload the file(s), please check your internet connection and try uploading again';
    this.message.push(msg);
  }

  removeImage(media: any) {
    this.totalAssetSize -= media.file.size;
    if (media.status === 2) {
      this.count.Success--;
    } else if (media.status === 3) {
      this.count.Failed--;
    }
    this.count.Total--;

    media.status = 4;
    media.file = null; // Offloading file to reduce memory usage

    if (this.count.Total === 0) {
      this.uploadDone = false;
      this.success = false;
      this.message = [];
    }
  }

  async singleRetry(file: any) {
    this.message = [];
    this.isUploading = true;
    if (file.status == 3 && file.message == 3) {
      const assetParams: any[] = [{ 
        assetName: file.file.name,
        assetPath: 'TempAssets',
        mimetype: file.file.type,
        assetSize: file.file.size
      }]
      let response: any;
      try {
        response = await this.s3UploadService.getPreSignedUrl(ASSET_TYPE_CONFIG.RIS, 'TempAssets', assetParams);
      } catch (err: any) {
        // Handle the error 
        console.error('Error fetching pre-signed URLs:', err);
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: err.error.message || "Something Went Wrong!",
        });
      }
      if(response.result[file.file.name].status == true){
        file.status = 1;
        file.message = 0;
        this.count.Failed--;
        await this.upload(file, response);
      }
    }
  }

  replaceName(name: any) {
    return (
      name.split(/\.(?=[^\.]+$)/)[0].replace(/[^A-Z0-9]+/gi, '_') +
      '.' +
      name.split(/\.(?=[^\.]+$)/)[1]
    );
  }

  submitImages() {
    // preparing payload
    const successFileDetails : any = [];
    for (const [key, value] of this.selectedFilesList.entries()) {
      const value = this.selectedFilesList.get(key);
      if (value?.status == 2) {
        successFileDetails.push(value?.data);
      }
    }
    let assetArrMaster = this.commonUtils.getKeyValuePairsFromArray(
      successFileDetails,
      '',
      '',
      ['assetName', 'assetPath']
    );
    let payload = {
      assetArr: [...assetArrMaster],
    };

    this.restService.post(`${API_ENDPOINT.baseEndpoint}${this.authService.getAuthData().clientId}/${API_ENDPOINT.reverseSearch.uploadRevImages}`,payload).subscribe({
      next: () => {
        this.selectedFilesList.clear();
        this.count = {
          Total : 0,
          Success : 0,
          Failed : 0
        }
        this.setView('status');
        this.getImageRevStatus();
        this.totalAssetSize = 0;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Error!',
          detail: err.error.message || 'Something went wrong!',
        });
      },
    });
  }

  getImageRevStatus(afterDelete?: boolean) {
    this.restService.get(`${API_ENDPOINT.baseEndpoint}${this.authService.getAuthData().clientId}/${API_ENDPOINT.reverseSearch.imageRevStatus}`,"","",false).subscribe({
      next: (data: any) => {
        this.imageRevData = data.result || [];
        this.appDataService.updatePollingStatus(this.imageRevData);
        if (this.imageRevData.length === 0) {
          if (afterDelete || this.view.status) {
            this.setView('upload');
          }
        }
      },
      error: (data: any) => { },
    });
  }

  rotateAnim() {
    this.dataRefresh = true;
    setTimeout(() => {
      this.dataRefresh = false;
    }, 1500);
  }

  minimize() {
    this.eventEmitterService.emit({
      type: APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD,
      data: {
        display: true,
        visible: false,
      },
    });
  }

  onAssetAction(action: string, asset?: any) {
    switch (action) {
      case 'redirect':
        this.minimize();
        const url = this.router.serializeUrl(
          this.router.createUrlTree(
            ['/main/' + asset.jobId + '/image-reverse-search'],
            {
              queryParams: {},
            }
          )
        );
        window.open(url, '_blank');
        break;
      case 'delete':
        var payload = {
          jobIdArr: [asset.jobId],
        };
        this.restService.post(`${API_ENDPOINT.baseEndpoint}${this.authService.getAuthData().clientId}/${API_ENDPOINT.reverseSearch.deleteJob}`,payload).subscribe({
          next: (user: any) => {
            this.getImageRevStatus(true);
            this.uploadDone = false;
            this.selectedFilesList.clear();
            this.count = {
              Total : 0,
              Success : 0,
              Failed : 0
            }
          },
          error: () => { },
        });
        break;
      case 'cancel':
        this.eventEmitterService.emit({
          type: APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD,
          data: {
            display: false,
            visible: false,
          },
        });
        break;
      case 'clear':
        this.confirmationModal = true;
        CONFIRMATION_POPUP_CONFIG.TITLE = "Are you sure?";
        CONFIRMATION_POPUP_CONFIG.SUBTITLE = "Canceling now will result in the loss of queued images. This process cannot be undone.";
        break;
      case 'deleteAll':
        let jobIdArr = this.commonUtils.getValuesFromArray(
          this.imageRevData,
          '',
          '',
          'jobId'
        );
        var payload = {
          jobIdArr: [...jobIdArr],
        };
        this.restService.post(`${API_ENDPOINT.baseEndpoint}${this.authService.getAuthData().clientId}/${API_ENDPOINT.reverseSearch.deleteJob}`,payload).subscribe({
          next: (user: any) => {
            this.getImageRevStatus(true);
            this.uploadDone = false;
            this.selectedFilesList.clear();
            this.count = {
              Total : 0,
              Success : 0,
              Failed : 0
            }
            this.setView('upload');
            this.confirmationModal = false;
          },
          error: () => { },
        });
        break;
    }
  }

  selectedFilesArray() {
    return Array.from(this.selectedFilesList.entries())
      .filter(([key, value]) => value.status !== 4)
      .map(([key, _value]) => key); // Return only the key
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
