import { Component } from '@angular/core';

@Component({
    selector: 'app-asset-not-found',
    templateUrl: './asset-not-found.component.html',
    styleUrls: ['./asset-not-found.component.scss'],
    standalone: true
})
export class AssetNotFoundComponent {

}
