export interface IElk_searchRequestBody {
  searchString: string;
  filters: Array<any>;
  sort: {};
  size: number;
  from: [] | [number, number, string];
} // IElk_searchRequestBody

export interface ITagMore {
  assetId: number;
  shortTagArrManual: Array<string>;
  longTagArrManual: Array<string>;
  shortTagArrAi: Array<string>;
  longTagArrAi: Array<string>;
  showMoreManualTag: boolean;
  showMoreAiTag: boolean;
  fileExt: Array<string>;
}

interface IElkSearchDataResult_aggregations_innerObj {
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: Array<{
    key: string;
    doc_count: number;
    isChecked: boolean;
  }>;
  tempSearchString: string;
} // IElkSearchDataResult_aggregations_innerObj

export interface IElkSearchDataResult_aggregations {
  key: IElkSearchDataResult_aggregations_innerObj;
} // IElkSearchDataResult_aggregations

export interface IElkSearchDataResult {
  took: number;
  timed_out: boolean;
  _shards: {
    total: number;
    successful: number;
    skipped: number;
    failed: number;
  };
  hits: {
    total: {
      value: number;
      relation: string;
    };
    max_score: number;
    hits: Array<IElkSearchDataResult_hits>;
  };
  lastSortValue: [number, number, string];
  aggregations: IElkSearchDataResult_aggregations;
} // IElkSearchDataResult

export interface IElkSearchDataResult_hits {
  _index: string;
  _id: any;
  _score: number;
  _source: IElkSearchHits_source;
} // IElkSearchDataResult_hits

export interface IElkSearchHits_source {
  assetCreatedAt: string;
  assetName: string;
  assetPath: string;
  folderId: number;
  assetSize: number;
  assetStatus: boolean;
  eventDate: string;
  eventDescription: string;
  eventName: string;
  assetType: number;
  thumbnailAssetName: string;
  eventLocation: string;
  tags: Array<string>;
  folderName: string;
  ai_tags: Array<IElkSearchHits_source_aitag>;
  aisuggtags: Array<string>;
  assetEncryptedShareUrl: string;
  isAssetSelected: boolean;
  attachment: any;
  title: any;
  collectionId: any;
  thumbnailPresignedUrl: any;
} // IElkSearchHits_source

interface IElkSearchHits_source_aitag {
  person_name: string;
  emotion: Array<string>;
  gesture: Array<string>;
  attire: Array<string>;
} // IElkSearchHits_source_aitag


export interface IsearchWithAdvProperty {
  adv_search?: string;
  exact_phrase_search: boolean;
  single_asset_from_single_folder: boolean;
  approved_assets: boolean;
  search_within: {
    ai_tags: boolean;
    manual_tags: boolean;
    description: boolean;
    event: boolean;
    location: boolean;
    title: boolean;
    default_tags: boolean;
    asset_id: boolean;
    srt: boolean;
  };
  asset_type: {
    image: boolean;
    video: boolean;
    audio: boolean;
    doc: boolean;
    other: boolean;
  };
  asset_size: {
    size: string;
    sizeInBytes?: number;
    size_for: 'less_than' | 'greater_than' | 'equal_to' | 'null';
  };
  date: {
    start_date: string;
    end_date: string;
  };
}