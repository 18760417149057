<p-dialog [modal]="true" class="asset-trimming-wrapper" [visible]="isTrimVideo" [resizable]="false"
  [breakpoints]="{'1030px': '850px', '991px': '90vw'}" [style]="{width: '800px', height: 'auto'}" [showHeader]="false">

  <div class="modal-header border-0">
    <h3 class="font-bold">Trim Asset</h3>
    <button type="button" (click)="cancelVideo(videoForm)" class="btn-close" ></button>
  </div>

  <div class="container-fluid">
    <div class="video-container w-100 h-100">
      @if (assetType==='3') {
        <div class="h-100 w-100 text center mt-2 position-relative">
          <img src="/assets/images/icons/audio-bg.svg" class="mw-100 mh-100 d-flex m-auto" alt="Audio">
          <audio class="audio-player-box" crossOrigin="anonymous" #video [src]="source" controls style=" width: 100%;max-height: 100%;"
            disablePictureInPicture controlsList="noplaybackrate nodownload">
          </audio>
        </div>
      }
      @if (assetType==='1') {
        <div class="h-100 w-100">
          <video crossOrigin="anonymous" #video [src]="source" controls style="max-height: 300px;"
            disablePictureInPicture controlsList="noplaybackrate nodownload">
          </video>
        </div>
      }
      @if (duration) {
        <div class="d-flex flex-column">
          <h6 class="font-bold mt-3">Select the duration to trim the Asset</h6>
          <div class="video-controls">
            @if (trimsliderPreview) {
              <slide-range [min]="0" [max]="duration" [valueMin]="0" [valueMax]="duration" [step]="1"
              (valueChange)="valueChange($event)"></slide-range>
            }
          </div>
        </div>
      }
      <div class="row">
        <div class="col">
          <div class="d-flex gap-3 flex-wrap trim-btn-group" style="text-align: center;">
            <button type="submit" class="btn btn-primary btn-min-width d-btn-primary border-0" (click)="submitFormData(videoForm)"
              [disabled]="videoForm.invalid || (videoForm.controls['videoName'].length >=1 && videoForm.controls['videoName'].length <2 || videoForm.controls['videoName'].length > 100) && (videoForm.controls['videoName'].dirty || videoForm.controls['videoName'].touched || videoForm.submitted) || previewFile ">
            Trim</button>
            <button type="button" (click)="cancelVideo(videoForm)" class="btn btn-secondary btn-min-width" >Cancel</button>

          </div>
        </div>
      </div>
    </div>
  </div>

</p-dialog>

<p-dialog [modal]="true" [visible]="IspreviewShow" [resizable]="false" [breakpoints]="{'1030px': '850px', '991px': '90vw'}"
  [style]="{width: '600px'}" [showHeader]="false">
  <div class="d-flex justify-content-between flex-row pb-2 mt-3">
    <h5 class="font-bold">Preview</h5>
    <button type="button" (click)="closePreviewModal()" class="btn-close"></button>
  </div>
  @if (assetType==='3') {
    <div class="h-100 w-100">
      <img src="/assets/images/icons/audio-bg.svg" class="mw-100 mh-100 d-flex m-auto"
        alt="Audio">
        <audio crossOrigin="anonymous" #videoElement #video src="{{previewFile}}" controls
          style=" width: 100%;max-height: 100%;" disablePictureInPicture controlsList="noplaybackrate nodownload">
        </audio>
      </div>
    }
    @if (assetType==='1') {
      <div class="h-100 w-100 mb-3">
        <video crossOrigin="anonymous" #video src="{{previewFile}}" style="width: 100%;max-height: 100%;height: 22rem;" controls
        disablePictureInPicture controlsList="noplaybackrate nodownload"></video>
      </div>
    }
    <form [formGroup]="videoForm">
      <div class="row align-items-start mt-3">
        <div class="col-sm">
          <div class="form-group mb-0">
            <label for="VideoName" class="form-label font-bold">{{formAssetsName}}
              <span class="invalid-feedback font-medium d-inline-block w-auto">*</span>
            </label>
            <input type="text" class="form-control" formControlName="videoName" name="videoName" required placeholder="Enter Name" readonly>
            @if (videoForm.controls['videoName'].hasError('required') && videoForm.controls['videoName'].dirty) {
              <div class="invalid-feedback d-block font-medium mb-2"
                >
                Name is required
              </div>
            }
            @if (videoForm.get('videoName').hasError('minlength')) {
              <div class="invalid-feedback d-block font-medium mb-2"
                >
                Name must be at least 2 characters long.
              </div>
            }
            @if (videoForm.get('videoName').hasError('maxlength')) {
              <div class="invalid-feedback d-block font-medium mb-2"
                >Name cannot exceed 100
              characters. </div>
            }
            @if (videoForm.get('videoName').hasError('pattern')) {
              <div class="invalid-feedback d-block font-medium mb-2">
                Only - and _ are allowed as special characters
              </div>
            }
          </div>
        </div>
        <div class="col-sm">
          <div class="form-group mb-0">
            <label for="VideoName" class="form-label font-bold">Type
              <span class="invalid-feedback font-medium d-inline-block w-auto"></span>
            </label>
            <input type="text" class="form-control" placeholder="{{videoType ? videoType:''}}" disabled>
          </div>
        </div>
      </div>
    </form>
    <br>
      <div class="text-center">
        <button type="button" (click)="closePreviewModal()"
        class="btn btn-secondary btn-min-width justify-content-center save-cropped-img me-2">Cancel</button>
        <button type="button" title="Save" (click)="saveCroppedVideo()"
        class="btn btn-primary d-btn-primary btn-min-width border-0 justify-content-center save-cropped-img">Save As New</button>
      </div>
    </p-dialog>
