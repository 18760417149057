@if (type==='switch') {
  <div>
    <div class="btn-group">
      @for (item of options; track item; let i = $index) {
        <label class="btn btn-switch br-r-0 btn-mini m-b-0" (click)="onSelect(i)"
          [ngClass]="{'active':item.Value===selected.value}">
          <strong class="text-transform-uppercase" innerHtml="{{item.label}}"></strong>
        </label>
      }
    </div>
  </div>
}
@if (type==='dropdown' && !multiselect) {
  <div class="btn-dropdown-container">
    <button class="nh-icon-button">
      @if (!customBtnTemplate) {
        <a class="drpdwn-btn dropdown-toggle text-decoration-none" role="button" id="dropdownMenuLink"
          data-bs-toggle="dropdown" aria-expanded="false">
          <span innerHtml="{{title}}"></span>
          <img src="./assets/images/icons/down-arrow-black.svg" class="ms-1">
        </a>
      }
      @if (customBtnTemplate) {
        <ng-container [ngTemplateOutlet]="customBtnTemplate"
          [ngTemplateOutletContext]="{ data: { badge:getBadge(),dropdownRef:'dropdownMenuLink'} }">
        </ng-container>
      }
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        @for (option of options; track option; let i = $index) {
          <li>
            <button class="dropdown-item" (click)="onSelect(i)" value="50"
              [ngClass]="{'active':option.value===selected.value}">
              <span class="text-transform-uppercase" innerHtml="{{option.label}}"></span>
            </button>
          </li>
        }
      </ul>
    </button>
  </div>
}
@if (type==='dropdown' && multiselect) {
  <div class="btn-dropdown-container">
    <button class="nh-icon-button">
      @if (!customBtnTemplate) {
        <a class="drpdwn-btn dropdown-toggle text-decoration-none" role="button" id="multiSelectDropdownMenuLink"
          data-bs-toggle="dropdown" aria-expanded="false">
          <span innerHtml="{{title}}"></span>
          <img src="./assets/images/icons/down-arrow-black.svg" class="ms-1">
        </a>
      }
      @if (customBtnTemplate) {
        <ng-container [ngTemplateOutlet]="customBtnTemplate"
          [ngTemplateOutletContext]="{ data: { badge:getBadge(),dropdownRef:'multiSelectDropdownMenuLink'} }">
        </ng-container>
      }
      <ul class="dropdown-menu" aria-labelledby="multiSelectDropdownMenuLink">
        @for (option of options; track option; let i = $index) {
          <li role="menuitem" class="dropdown-item multselect-item">
            <input type="checkbox" [value]="option.checked" id="btn-checkbox-{{option.id}}" name="{{option.label}}"
              (click)="onSelect(i)" [checked]="option.checked" />
              <label for="{{option.id}}">
                {{ option.label }}
              </label>
            </li>
          }
        </ul>
      </button>
    </div>
  }
