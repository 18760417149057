import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatBytes',
    standalone: true,
})
export class FormatBytesPipe implements PipeTransform {
  transform(bytes: any, ...args: any[]): unknown {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = args[0] < 0 ? 0 : args[0];
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    if (args[1] === 'exactValue') {
      let decimalPlaces: number;
      switch (sizes[i]) {
        case 'KB':
          decimalPlaces = 0;
          break;
        case 'MB':
          decimalPlaces = 1;
          break;
        default:
          decimalPlaces = 2;
          break;
      }
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPlaces))} ${
        sizes[i]
      }`;
    } else {
      return `${Math.round(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)))} ${
        sizes[i]
      }`;
    }
  }
}
