import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { CommonUtils } from 'src/app/common/utils/common.utils';
import { Columns, IPageData } from 'src/app/core/models/models';
import { NgStyle, NgClass, NgTemplateOutlet, SlicePipe, CurrencyPipe, DatePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    standalone: true,
    imports: [
        NgStyle,
        NgClass,
        RouterLink,
        NgTemplateOutlet,
        PaginationModule,
        FormsModule,
        SlicePipe,
        CurrencyPipe,
        DatePipe,
    ],
})
export class TableComponent implements OnInit, OnChanges {
  @Input() dataSource: any[] = [];
  @Input() columns!: Columns[];
  @Input() pagination!: IPageData;
  @Output() onActionPerformed = new EventEmitter();
  @Input() defaultSortKey?: string;
  @Input() sort: any;
  @Input() order: any;
  currenSortBy: string;
  reverse: boolean = false;
  commonUtils = new CommonUtils();

  constructor() {}

  ngOnInit(): void {
    if (this.defaultSortKey) {
      this.currenSortBy = this.defaultSortKey;
    }
    if (this.isReadMoreColumn(this.columns) && this.dataSource?.length) {
      this.dataSource.forEach((el: any) => {
        el.isReadMore = false;
      });
    }
  }

  isReadMoreColumn(columns: any[]) {
    return columns.findIndex((column) => column.readMore);
  }

  ngOnChanges(): void {}

  onPageChange(data: any) {
    if (this.pagination.pageno !== data.page) {
      this.pagination.pageno = data.page;
      this.commonUtils.scrollToAppMain();
      this.onActionPerformed.emit({
        type: 'pagination',
        data: {
          limit: this.pagination.limit,
          pageno: this.pagination.pageno,
        },
      });
    }
  }

  setOrder(column: any) {
    this.currenSortBy = column.sortKey;
    this.reverse = !this.reverse;
    this.onActionPerformed.emit({
      type: 'sort',
      data: {
        sortBy: column.sortKey,
        orderBy: this.reverse ? 'DESC' : 'ASC',
      },
    });
  }

  readMore(index: number) {
    this.dataSource[index].isReadMore = !this.dataSource[index].isReadMore;
  }

  disableRightClick(event: Event): void {
    event.preventDefault();
  }
}
