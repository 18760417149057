import { Component, Input, OnInit } from '@angular/core';
import { DialogModule } from 'primeng/dialog';

@Component({
    selector: 'app-warning-pop-up',
    templateUrl: './warning-pop-up.component.html',
    styleUrls: ['./warning-pop-up.component.scss'],
    standalone: true,
    imports: [DialogModule],
})
export class WarningPopUpComponent implements OnInit {
  show: boolean = true;
  @Input() heading: any;
  @Input() subHeading: any;

  ngOnInit(): void {}
}
