<!-- dialog box for creating sub-folder -->
<p-dialog [modal]="true" [draggable]="false" [visible]="isCreateModalShow" class="onboarduser-form" [resizable]="false"
  [breakpoints]="{ '1030px': '850px', '991px': '90vw' }" [style]="{ width: '600px' }" [showHeader]="false">
  <div class="onboard-user-form-wrapper">
    <form [formGroup]="creareSubfolderForm">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content radius-24 p-0">
          <div class="modal-header border-0 pb-0">
            <h3 class="mb-1 mt-1">Create New Folder</h3>
            <button type="button" (click)="closeCreateModal()" class="btn-close" aria-label="Close"></button>
          </div>
          <div class="modal-body p-4 pt-3">
            <div class="row pt-2">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-9">
                    <div class="mb-4">
                      <div class="mb-4">
                        <label class="mb-2 field-title">Folder Name<span style="color: #ff0000">*</span></label>
                        <input type="text" class="form-control" formControlName="folderName" name="folderName"
                          required />
                        @if (
                        creareSubfolderForm.controls['folderName'].hasError(
                        'required'
                        ) &&
                        creareSubfolderForm.controls['folderName'].dirty
                        ) {
                        <div class="invalid-feedback fs-12 mt-2 d-block font-medium mb-2">
                          Folder Name is required
                        </div>
                        }
                        @if (
                        creareSubfolderForm
                        .get('folderName')
                        .hasError('minlength') ||
                        creareSubfolderForm
                        .get('folderName')
                        .hasError('maxlength')
                        ) {
                        <div class="invalid-feedback fs-12 mt-2 d-block font-medium mb-2">
                          Folder Name should range from 2 to 100 characters
                        </div>
                        }
                        @if (
                        creareSubfolderForm
                        .get('folderName')
                        .hasError('pattern')
                        ) {
                        <div class="invalid-feedback fs-12 mt-2 d-block font-medium mb-2">
                          Only - and _ are allowed as special characters.
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-start align-items-start px-4 pt-2 pb-4">
            <div class="d-grid d-flex gap-3 flex-wrap">
              <button type="submit" class="btn btn-primary d-btn-primary border-0 btn-min-width"
                (click)="createSubfolder(creareSubfolderForm)" [disabled]="
                  creareSubfolderForm.invalid ||
                  (creareSubfolderForm.controls['folderName'].length >= 1 &&
                    creareSubfolderForm.controls['folderName'].length < 2 &&
                    (creareSubfolderForm.controls['folderName'].dirty ||
                      creareSubfolderForm.controls['folderName'].touched ||
                      creareSubfolderForm.submitted)) ||
                  handleButtonClicked
                ">
                Create
              </button>
              <button type="button" (click)="closeCreateModal()" class="btn btn-secondary btn-min-width"
                style="color: #116acc; border: 1px solid #116acc">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>

<!-- dialog box for creating folder with assignee -->
<p-dialog [modal]="true" [draggable]="false" [visible]="isRootCreateFolderModalShow" class="onboarduser-form"
  [resizable]="false" [breakpoints]="{ '1030px': '850px', '991px': '90vw' }" [style]="{ width: '800px' }"
  [showHeader]="false">
  <div class="onboard-user-form-wrapper">
    <form #frmFolder="ngForm" role="form" novalidate>
      <!-- start new html-->
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content radius-24 p-0">
          <div class="modal-header border-0 pb-0">
            <h3 class="mb-1 ">{{isEditMode==='add' ? 'Create New Folder' : 'Edit Folder'}}</h3>

            <button type="button" (click)="closeRootCreateModal(frmFolder)" class="btn-close"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-4 pt-3">
            <div class="row pt-2">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-5">
                    <div class="mb-4">
                      <div class="mb-4">
                        <label class="mb-2">Folder Name<span style="color: #ff0000">*</span></label>
                        <input type="text" class="form-control" [(ngModel)]="folder.folderName" name="folderName"
                          #folderName="ngModel" id="folderName" required placeholder="Enter Folder Name"
                          pattern="^[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$" [disabled]="isEditMode === 'edit'" />
                        @if (folder.folderName &&
                        ((folder.folderName.length >= 1 &&
                        folder.folderName.length < 2) || folder.folderName.length> 100) &&
                          (folderName.dirty ||
                          folderName.touched ||
                          frmFolder.submitted)
                          ) {
                          <div class="invalid-feedback d-block font-medium mb-2">
                            Folder Name should range from 2 to 100 characters
                          </div>
                          }
                          @if (folderName.errors?.['pattern']) {
                          <div class="invalid-feedback d-block font-medium mb-2 textarea-error-space-msg w-75">
                            Only - and _ are allowed as special characters.
                          </div>
                          }

                          @if (folder.folderName &&
                          folderName.errors &&
                          (folderName.dirty ||
                          folderName.touched ||
                          frmFolder.submitted)
                          ) {
                          <div class="invalid-feedback d-block font-medium mb-2">
                            @if (folderName.errors?.['required']) {
                            <div>
                              Folder Name is required
                            </div>
                            }
                          </div>
                          }
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5"></div>
                  <div class="col-12">
                    @if (folder.folderAccess.length > 1 || (folder.folderAccess.length === 1 && isEditMode === 'edit'))
                    {
                    <label class="mb-2 col-lg-5">Assign to<span class="text-danger">*</span></label>
                    <label class="mb-2 col-lg-5 ps-3">Access Type<span class="text-danger">*</span></label>
                    }
                    @for (row of folder.folderAccess; track row; let i = $index) {
                    <div class="row">
                      <!-- <div class="row" [hidden]="type==='add' && row.roleId===1"> -->
                      <div class="col-lg-5">
                        <div class="mb-2 position-relative">
                          <p-dropdown [options]="userList" [(ngModel)]="row.assignedTo" #assignedTo="ngModel" name="assignedTo_{{i}}" placeholder="Select"
                            optionLabel="fullName" optionValue="userId" [style]="{'width': '100%' }"
                            [hidden]="row.roleId === 1 || (isEditMode !== 'edit' && row.roleId === 2)" [showClear]="false"
                            (onChange)="changeUser($event, i)" (ngModelChange)="onFormChange(frmFolder)" [required]="true" [filter]="true"
                            scrollHeight="150px" [resetFilterOnHide]="true"  optionDisabled="disabled" appendTo="body">
                          </p-dropdown>
                          
                          @if (frmFolder.submitted) {
                          <div class="invalid-feedback d-block font-medium mb-2">
                            @if (assignedTo.errors?.['required']) {
                            <div>
                              Assign to is required
                            </div>
                            }
                          </div>
                          }
                        </div>
                      </div>
                      <div class="col-lg-5">
                        <div class="mb-2 position-relative">
                          @if (!row.accessType) {
                          <span class="placeholder-text font-regular">Select</span>
                          }
                          <select [hidden]="row.roleId === 1 || (isEditMode!=='edit' && row.roleId === 2)"
                            class="form-select form-control custom-select"
                            class="form-select form-control custom-select" [(ngModel)]="row.accessType"
                            #accessType="ngModel" name="accessType_{{i}}" (ngModelChange)="onFormChange(frmFolder)"
                            required>
                            <option value="Select" disabled>Select</option>
                            @for (item1 of accessTypeList; track item1) {
                            <option [ngValue]="item1.id">{{item1.name}}</option>
                            }
                          </select>
                          @if (frmFolder.submitted) {
                          <div class="invalid-feedback d-block font-medium mb-2">
                            @if (accessType.errors?.['required']) {
                            <div>
                              Access Type is required
                            </div>
                            }
                          </div>
                          }
                        </div>
                      </div>
                      @if (row.roleId !== 1 && row.roleId !== 2) {
                      <div [ngClass]="i == 0 ? 'delete-row-icon' : '' "
                        class="col-md-1 d-flex justify-content-end align-items-center">
                        <a role="button" class="d-flex align-items-center ms-3 trash-row"
                          (click)="removeUserOnfolderAccessList(i,frmFolder)">
                          <img src="assets/images/icons/delete.svg" class="ms-1" alt="Delete">
                        </a>
                      </div>
                      }
                      <!-- </div> -->
                    </div>
                    }
                  </div>
                  <div class="col-12">
                    <a (click)="addItem(frmFolder)"
                      class="text-decoration-none fs-16 font-medium dam-primary-btn-color cursorpointer">
                      <img src="assets/images/icons/Add-user.svg" class="ms-1" alt="add new user" />Add New User
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-start align-items-start px-4 pt-2 pb-4">
            <div class="d-grid d-flex gap-3 flex-wrap">
              <button type="button" (click)="closeRootCreateModal(frmFolder)" class="btn btn-secondary btn-min-width"
                style="color: #116acc; border: 1px solid #116acc">
                Cancel
              </button>
              <!-- [disabled]="frmFolder.invalid || !isFormDirty ||(((folder.folderName.length >= 1 && folder.folderName.length < 2) || folder.folderName.length > 100) && (folderName.dirty || folderName.touched || frmFolder.submitted))" -->
              <button type="submit" class="btn btn-primary d-btn-primary border-0 btn-min-width"
                [disabled]=" isEditMode=='add' ? (frmFolder.invalid) :(frmFolder.invalid || !isFormDirty)"
                (click)="submitFormDataToCreateFolder(frmFolder)">
                {{isEditMode=='add'?'Save':'Update'}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end new html-->
    </form>
  </div>
</p-dialog>
