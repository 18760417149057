import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { API_ENDPOINT } from 'src/app/core/constants'
import { AuthService, RestService } from 'src/app/core/services';
import { DialogModule } from 'primeng/dialog';

@Component({
    selector: 'app-add-event-modal',
    templateUrl: './add-event-modal.component.html',
    styleUrls: ['./add-event-modal.component.scss'],
    standalone: true,
    imports: [DialogModule, FormsModule],
})
export class AddEventModalComponent {
  @Output() closeEvent = new EventEmitter<any>();

  createEventForm: any;
  showSuccessMessage: any = false;
  event: any = {
    eventName: '',
    eventTime: '',
    description: '',
    eventLocation: '',
  };
  totalText = 1000;

  constructor(
    private messageService: MessageService,
    private restService: RestService,
    private authService: AuthService
  ) {
    this.createEventForm = new FormGroup({
      eventName: new FormControl(''),
      eventTime: new FormControl(''),
      description: new FormControl(''),
      eventLocation: new FormControl(''),
    });
  }
  //Create Event
  submitFormData(frmEvent: any) {
    this.event.eventName = this.event.eventName.trim().replace(/\s+/g, ' ');
    this.event.eventLocation = this.event.eventLocation
      .trim()
      .replace(/\s+/g, ' ');
    if (frmEvent.form.valid) {
      let queryCall = this.restService.post(
        `${API_ENDPOINT.baseEndpoint}${this.authService.getAuthData()?.clientId}/${API_ENDPOINT.event.createEvent}`,
        this.event
      );
      queryCall.subscribe({
        next: (data: any) => {
          frmEvent.form.reset();
          frmEvent.form.markAsPristine();
          this.showSuccessMessage = true;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Event created Successfully',
          });
          //this.tagAsset();
          this.closeEvent.emit({ id: data.result.id });
        },
        error: (err) => {
          // alert(err.error.message);
          //this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Something went wrong please try later' });
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: err.error.message,
          }); //changes as per bug 176643
          frmEvent.form.reset();
          frmEvent.form.markAsPristine();
          this.closeEvent.emit();
        },
      });
      
    } else {
      setTimeout(function () {
        if (document.querySelector('.required')) {
          document.querySelector('required')?.scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
    this.showSuccessMessage = false;
  }
  
  closeEventModal(frmEvent: any) {
    this.closeEvent.emit(false);
  }
  resetFormData(frmEvent: any) {
    frmEvent.form.reset();
    frmEvent.form.markAsPristine();
  }
}
