import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom }from 'rxjs';
import { environment } from 'src/app/environment/environment';
import { RestService } from './rest.service';
import { API_ENDPOINT } from '../constants/static';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class S3UploadServices {
  public api_url = environment.apiUrl;

  constructor(private http: HttpClient, private restService : RestService, private authService: AuthService) {
  }

  async getPreSignedUrl(
    uploadType: string,
    childFolderId: string,
    assetParams: any []
  ): Promise<any> {
    // Define the parameters for pre-signed URL
    const paramsPreSignedUrl = {
      folderId: childFolderId,
      uploadedBy: '1',
      uploadType: uploadType,
      assetList: assetParams
    };
    let endpoint = API_ENDPOINT.baseEndpoint + this.authService.getAuthData().clientId + '/upload/presigned-url';
    try {
      // Send request to get pre-signed URL
      const response = await lastValueFrom(
        this.restService.post(endpoint, paramsPreSignedUrl, '', false)
      );
      return response;
    } catch (err: any) {
      console.error('Error fetching pre-signed URLs:', err);
      throw err;
    }
  }

  // function to Upload File 
  async uploadFile(file: File, asset: any, event?: any) {
    try {
      let data: any = event.result;
      if (data[file.name].status === false) {
        return event;
      }
      else {
        const presignedUrl = data[file.name].data.preSignedUrl;
        try {
          const response = environment.infra === "aws"
            ? await this.uploadFileToS3(presignedUrl, file, asset)
            : await this.uploadFileToGCP(presignedUrl, file, asset);
          return event;
        } catch (error) {
          return error;
        }
      }

    } catch (error) {
      return error;
    }
  }

// initiate multiPartCode
  async initiateMultiPart(params: any, file: File, asset: any) {
    try {
      let uploadId: any;
      // Calling InitiateMultipart API to initiate MultiPart Upload

      const resp: any = await this.restService.post("asset/initiateMultipart", params, '', false).toPromise();

      if (resp.message === "Multipart Initiated") {
        uploadId = resp.result;
        return await this.uploadMultipartFile(file, uploadId, params.assetPath, params, asset);
      } else {
        return resp;
      }
    } catch (err) {
      return err;
    }
  }

// function to generate PreSigned Url for each chunks and then Uploading it.
async uploadMultipartFile(file: File, uploadId: any, folderPath: string, data: any, asset: any) {
  let uploadPartsArray: any[] = [];
  let progressRef = { value: 0 }; // Shared progress reference
  const CHUNK_SIZE = 30 * 1024 * 1024; // 30MB
  const CHUNKS_COUNT = Math.floor(file.size / CHUNK_SIZE) + 1;
  const BATCH_SIZE = 10; // Number of chunks to upload concurrently

  asset.progress = progressRef.value;

  const uploadChunks = async (chunks: any[]) => {
    const uploadPromises = chunks.map(async (chunk) => {
      const { blob, index, params } = chunk;
      const getUploadUrlResp: any = await this.restService.post("asset/getPresignedUrlUnique", params, '', false).toPromise();
      const presignedUrl = getUploadUrlResp.result;
      
      let uploadPromise: Promise<any>;
      if (environment.infra === "aws") {
        uploadPromise = this.uploadPart(presignedUrl, blob, index,file, uploadPartsArray, progressRef, params, asset, CHUNKS_COUNT);
      } else {
        uploadPromise = this.uploadPartGCP(presignedUrl, blob, index,file, uploadPartsArray, progressRef, params, asset, CHUNKS_COUNT);
      }

      return uploadPromise;
    });

    const results = await Promise.all(uploadPromises);
    return results;
  };

  const chunks = [];
  for (let index = 1; index < CHUNKS_COUNT + 1; index++) {
    const start = (index - 1) * CHUNK_SIZE;
    const end = index * CHUNK_SIZE;
    const blob = (index < CHUNKS_COUNT) ? file.slice(start, end) : file.slice(start);
    const params = {
      fileName: file?.name,
      folderPath: folderPath,
      uploadId: uploadId,
      partNumber: index,
    };
    
    chunks.push({ blob, index, params });

    if (chunks.length === BATCH_SIZE || index === CHUNKS_COUNT) {
      const processedChunks = chunks.length; // Get the actual number of chunks being processed
      await uploadChunks(chunks);
      chunks.length = 0; // Clear the chunks array
    }
  }

  try {
    // Sorting UploadPartsArray in ascending order in accordance to their PartNumber
    uploadPartsArray.sort((a, b) => a.PartNumber - b.PartNumber);
    const api = "asset/completeMultiPartUpload";
    const completeUploadResp: any = await this.restService.post(
      api,
      {
        params: {
          fileName: file?.name,
          folderPath: folderPath,
          parts: uploadPartsArray,
          uploadId: uploadId,
          folderId: data.folderId,
          uploadedBy: '1',
          assetPath: data.assetPath,
          assetSize: data.assetSize,
        },
      },
      '', false
    ).toPromise();
    asset.progress = -1;
    return completeUploadResp;
  } catch (err) {
    asset.progress = -1;
    return err;
  }
}

  // function to upload part to s3 using PreSinged URL

  async uploadPart(presignedUrl: string, blob: Blob, index: any, file: File, uploadPartsArray: any, progressRef: { value: number }, params: any,asset: any, ChunksCount: any, maxRetries: number = 3): Promise<any> {
    const fileType = file.type == 'text/plain' ? 'application/octet-stream' : file.type ;
    const headers = new HttpHeaders({
      'Content-Type': fileType,
    });
  
    let retries = 0;
  
    const attemptUpload = async (): Promise<any> => {
      try {
        const response: any = await this.http.put(presignedUrl, blob, { headers, observe: 'response' })
          .toPromise();
        const etag = response.headers.get('Etag');
        uploadPartsArray.push({
          ETag: etag,
          PartNumber: index,
        });
        progressRef.value ++;
        asset.progress = Math.round(100 *  progressRef.value/ ChunksCount);
        return response;
      } catch (error) {
        if (retries < maxRetries) {
          retries++;
          const getUploadUrlResp: any = await this.restService.post(`asset/getPresignedUrlUnique`, params,'', false).toPromise();
          presignedUrl = getUploadUrlResp.result;
          console.log(`Upload attempt ${retries} failed. Retrying...`);
          return attemptUpload(); // Retry the upload
        } else {
          console.error(`Max retries reached. Upload failed for chunk ${index}.`);
          uploadPartsArray.push(error);
        }
      }
    };
    return attemptUpload();
  }

  async uploadPartGCP(presignedUrl: string, blob: Blob, index: any, file: File, uploadPartsArray: any, progressRef: { value: number }, params: any,asset: any, ChunksCount: any, maxRetries: number = 3): Promise<any> {
    const fileType = file.type == 'text/plain' ? 'application/octet-stream' : file.type;
    const headers: { [key: string]: string } = {
      'Content-Type': fileType,
    };
  
    let retries = 0;
  
    const attemptUpload = async (): Promise<any> => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', presignedUrl, true);
        Object.keys(headers).forEach((header) => {
          xhr.setRequestHeader(header, headers[header]);
        });
  
        xhr.onload = function () {
          if (xhr.status === 200) {
            const etag = xhr.getResponseHeader('ETag');
            uploadPartsArray.push({
              ETag: etag,
              PartNumber: index,
            });
            progressRef.value ++;
            asset.progress = Math.round(100 *  progressRef.value/ ChunksCount);
            resolve(xhr.responseText);
          } else {
            reject(`Failed to upload part. Status: ${xhr.status}`);
          }
        };
  
        xhr.onerror = function () {
          reject('XHR request error');
        };
  
        xhr.upload.onprogress = function (event) {
          if (event.lengthComputable) {
            // can write some logic for individual chunk progress here 
          }
        };
  
        xhr.send(blob);
      });
    };
  
    // Attempt upload with retries
    try {
      const response = await attemptUpload();
      return response;
    } catch (error) {
      if (retries < maxRetries) {
        retries++;
        console.log(`Upload attempt ${retries} failed. Retrying...`);
        // Retry with new presigned URL
        const getUploadUrlResp: any = await this.restService.post("asset/getPresignedUrlUnique", params,'',false).toPromise();
        presignedUrl = getUploadUrlResp.result;
        return attemptUpload();
      } else {
        console.error(`Max retries reached. Upload failed for chunk ${index}.`);
        uploadPartsArray.push(error);
        //throw error; // Rethrow the error after retries are exhausted
      }
    }
  }
  
  
  uploadFileToGCP(presignedUrl: string, file: File, asset: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Update progress
      asset.progress = 0;
      const fileType = file.type === 'text/plain' ? 'application/octet-stream' : file.type;
  
      // Create a new XMLHttpRequest
      const xhr = new XMLHttpRequest();
      
      xhr.open('PUT', presignedUrl, true);
      xhr.setRequestHeader('Content-Type', fileType);
  
      // Track the progress of the upload
      xhr.upload.onprogress = (event: ProgressEvent) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          asset.progress = progress;
        }
      };
  
      // Handle the response
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response); // File upload completed successfully
        } else {
          reject({ status: xhr.status, statusText: xhr.statusText, code: xhr.status }); // File upload failed with status
        }
      };
  
      // Handle errors
      xhr.onerror = () => {
        reject({ status: xhr.status || 400 , statusText: "CORS or internet connection issue", code: xhr.status || 403 });
        reject(xhr); // Network error
      };
  
      // Send the file
      xhr.send(file);
    });
  }
  

  uploadFileToS3(presignedUrl: string, file: File, asset: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const fileType = file.type == 'text/plain' ? 'application/octet-stream' : file.type ;
      const headers = {
        'Content-Type': fileType
      };

      this.http.put(presignedUrl, file, {
        headers: headers,
        reportProgress: true, // if you want progress updates
        observe: 'events'
      })
      .subscribe({
        next: (event: any)=> {
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total !== undefined) {
              const percentDone = Math.round((100 * event.loaded) / event.total);
              // You can handle progress updates here if needed'
              asset.progress = percentDone;
              
            } else {
              // Handle the case where event.total is undefined
            }
          } else if (event.type === HttpEventType.Response) {
            resolve(event.body); // File upload successful
          }
        },
        error:(error: any) => {
          reject(error); // File upload failed
        }
    });
    });
  }
}



