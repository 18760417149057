<div class="modal search-ad-fil-modal fade" id="AdvanceFilter" tabindex="-1" aria-labelledby="AdvanceFilterLabel"
  aria-hidden="true" #modal>
  <div class="modal-dialog modal-dialog-scrollable m-auto">
    <div class="modal-content radius-24 advance-filter-w">
      <div class="modal-header border-0 mb-4">
        <h4 class="font-bold m-0">Advanced Filter</h4>
        <button type="button" (click)="closeAdvanceFilter()" id="closeAdvFilter" class="btn-close"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="px-3">
        <form>
          <div class="search-wrap w-100 position-relative radius-8 px-2">
            <input [attr.type]="searchWithAdvProperty.search_within.asset_id ? 'number' : 'text'" class="search_input"
              placeholder="Search" aria-label="search" aria-describedby="basic-addon1"
              [(ngModel)]="searchWithAdvProperty.adv_search" [ngModelOptions]="{standalone: true}"
              (ngModelChange)="onModelChange($event)" (keydown)="onKeyDown($event)"
              (keyup.enter)="replaceAdvSearchWithMainSearch()">
            @if (searchWithAdvProperty.adv_search) {
            <div class="search_action cursorpointer clear-ic-btn" (click)="searchWithAdvProperty.adv_search=''">
              <img src="/assets/images/icons/icon-close-black.svg" alt="clear">
            </div>
            }
            @if (searchWithAdvProperty.adv_search) {
            <div class="search_action cursorpointer search-ic-btn" (click)="replaceAdvSearchWithMainSearch()"
              data-bs-dismiss="modal">
              <span class="search-icon">
                <img src="/assets/images/icons/ic_search.svg" style="height: 20px;" alt="search-icon">
              </span>
            </div>
            }
          </div>
        </form>
        <div class="border-bottom w-100 mt-4 mb-1"></div>
      </div>
      <div class="modal-body p-0 px-3">
        <div class="alpha-list">
          <div class="row" [ngClass]="{'disable': searchWithAdvProperty.search_within.asset_id}">
            <div class="col-auto my-1 d-flex">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="defaultCheckExactPhraseSearch"
                  [(ngModel)]="searchWithAdvProperty.exact_phrase_search">
                <label class="form-check-label " for="defaultCheckExactPhraseSearch">
                  Exact Phrase Search
                </label>
              </div>
            </div>
            <div class="col-auto my-1 d-flex">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="defaultCheckSingleAsset"
                  [(ngModel)]="searchWithAdvProperty.single_asset_from_single_folder">
                <label class="form-check-label" for="defaultCheckSingleAsset">
                  Single Asset from Single Folder
                </label>
              </div>
            </div>
            <div class="col-auto my-1 d-flex">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="defaultCheckApprovedAssets"
                  [(ngModel)]="searchWithAdvProperty.approved_assets">
                <label class="form-check-label" for="defaultCheckApprovedAssets">
                  Approved Assets
                </label>
              </div>
            </div>
          </div>
          <div class="border-bottom w-100 mb-1"></div>
        </div>
        <div class="fs-6 font-bold title-color mb-1">Search Within:</div>
        <div class="row" [ngClass]="{'disabled-checkbox': searchWithAdvProperty.search_within.asset_id}">
          <!-- <div class="col-auto my-1 d-flex">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="defaultCheckAll"
                [ngModel]="(this.searchWithAdvProperty.search_within.ai_tags && this.searchWithAdvProperty.search_within.manual_tags && this.searchWithAdvProperty.search_within.description && this.searchWithAdvProperty.search_within.event && this.searchWithAdvProperty.search_within.location && this.searchWithAdvProperty.search_within.title && this.searchWithAdvProperty.search_within.default_tags && this.searchWithAdvProperty.search_within.srt )"
                (click)="(searchWithAdvProperty.search_within.ai_tags && searchWithAdvProperty.search_within.manual_tags && searchWithAdvProperty.search_within.description && searchWithAdvProperty.search_within.event && searchWithAdvProperty.search_within.location && searchWithAdvProperty.search_within.title && searchWithAdvProperty.search_within.default_tags && searchWithAdvProperty.search_within.srt )?[searchWithAdvProperty.search_within.ai_tags=false, searchWithAdvProperty.search_within.manual_tags=false, searchWithAdvProperty.search_within.description=false, searchWithAdvProperty.search_within.event=false,searchWithAdvProperty.search_within.location=false, searchWithAdvProperty.search_within.title=false, searchWithAdvProperty.search_within.default_tags=false, searchWithAdvProperty.search_within.srt=false ]:[searchWithAdvProperty.search_within.ai_tags= true, searchWithAdvProperty.search_within.manual_tags=true, searchWithAdvProperty.search_within.description=true, searchWithAdvProperty.search_within.event=true, searchWithAdvProperty.search_within.location=true, searchWithAdvProperty.search_within.title=true, searchWithAdvProperty.search_within.default_tags=true, searchWithAdvProperty.search_within.srt= true]">
              <label class="form-check-label" for="defaultCheckAll">
                All
              </label>
            </div>
          </div> -->
          <div class="col-auto my-1 d-flex">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="defaultCheckAi"
                [(ngModel)]="searchWithAdvProperty.search_within.ai_tags">
              <label class="form-check-label " for="defaultCheckAi">
                AI Tags
              </label>
            </div>
          </div>
          <div class="col-auto my-1 d-flex">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="defaultCheckManual"
                [(ngModel)]="searchWithAdvProperty.search_within.manual_tags">
              <label class="form-check-label" for="defaultCheckManual">
                Manual Tags
              </label>
            </div>
          </div>
          <div class="col-auto my-1 d-flex">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="defaultCheckDescription"
                [(ngModel)]="searchWithAdvProperty.search_within.description">
              <label class="form-check-label" for="defaultCheckDescription">
                Description
              </label>
            </div>
          </div>
          <div class="col-auto my-1 d-flex">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="defaultCheckEvent"
                [(ngModel)]="searchWithAdvProperty.search_within.event">
              <label class="form-check-label" for="defaultCheckEvent">
                Event
              </label>
            </div>
          </div>
          <div class="col-auto my-1 d-flex">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="defaultCheckLocation"
                [(ngModel)]="searchWithAdvProperty.search_within.location">
              <label class="form-check-label" for="defaultCheckLocation">
                Location
              </label>
            </div>
          </div>
          <div class="col-auto my-1 d-flex">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="defaultCheckTitle"
                [(ngModel)]="searchWithAdvProperty.search_within.title">
              <label class="form-check-label" for="defaultCheckTitle">
                Title
              </label>
            </div>
          </div>
          <div class="col-auto my-1 d-flex">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="defaultCheckDefaultTags"
                [(ngModel)]="searchWithAdvProperty.search_within.default_tags">
              <label class="form-check-label" for="defaultCheckDefaultTags">
                Default Tags
              </label>
            </div>
          </div>
          @if (roleId != 3) {
          <!-- [ngClass]="{'disable': searchWithAdvProperty.search_within.asset_id}" -->
          <div class="col-auto my-1 d-flex">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="defaultCheckTranscript"
                [(ngModel)]="searchWithAdvProperty.search_within.srt">
              <label class="form-check-label" for="defaultCheckTranscript">
                Transcript
              </label>
            </div>
          </div>
          }
          <!-- AssetId should be a last child element always or else the CSS last child logic for disabled-checkbox will fail and will create another issue -->
          <div class="col-auto my-1 d-flex">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="defaultCheckAsset_ID"
                [(ngModel)]="searchWithAdvProperty.search_within.asset_id" (change)="resetSearchTextField()">
              <label class="form-check-label" for="defaultCheckAsset_ID">
                Asset ID
              </label>
            </div>
          </div>
        </div>
        <div class="border-bottom w-100 mb-1"></div>
        <div class="">
          <div class="fs-6 font-bold title-color mb-1">Asset Type:</div>
          <div class="row" [ngClass]="{'disable': searchWithAdvProperty.search_within.asset_id}">
            <div class="col-auto my-1 d-flex">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="defaultCheckImage"
                  [(ngModel)]="searchWithAdvProperty.asset_type.image">
                <label class="form-check-label" for="defaultCheckImage">
                  Image </label>
              </div>
            </div>
            <div class="col-auto my-1 d-flex">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="defaultCheckVideo"
                  [(ngModel)]="searchWithAdvProperty.asset_type.video">
                <label class="form-check-label" for="defaultCheckVideo">
                  Video
                </label>
              </div>
            </div>
            <div class="col-auto my-1 d-flex">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="defaultCheckAudio"
                  [(ngModel)]="searchWithAdvProperty.asset_type.audio">
                <label class="form-check-label" for="defaultCheckAudio">
                  Audio
                </label>
              </div>
            </div>
            <div class="col-auto my-1 d-flex">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="defaultCheckDocument"
                  [(ngModel)]="searchWithAdvProperty.asset_type.doc">
                <label class="form-check-label" for="defaultCheckDocument">
                  Document
                </label>
              </div>
            </div>
            <div class="col-auto my-1 d-flex">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="defaultCheckOthers"
                  [(ngModel)]="searchWithAdvProperty.asset_type.other">
                <label class="form-check-label" for="defaultCheckOthers">
                  Others
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="border-bottom w-100 mb-1"></div>
        <div class="AssetSize">
          <div class="fs-6 font-bold title-color mb-1">Asset Size:</div>
          <div class="row" [ngClass]="{'disable': searchWithAdvProperty.search_within.asset_id}">
            <div class="col-auto my-1 d-flex">
              <div class="form-check">
                <input type="text" class="form-control form-control-sm" placeholder="Size in MB" maxlength="6"
                  aria-controls="rolecon" [(ngModel)]="searchWithAdvProperty.asset_size.size"
                  (input)="onInputChanged($event)" (ngModelChange)="convertMegabyteToByte($event)">
              </div>
            </div>
            <div class="col-auto my-1 d-flex">
              <div class="form-check ps-0">
                <input class="form-check-input" type="radio" name="SizeRadioAdvance" id="flexRadioDefault1"
                  value="less_than" [(ngModel)]="searchWithAdvProperty.asset_size.size_for"
                  (click)="changeRadioButtonForAssetSize('less_than')"
                  [disabled]="!searchWithAdvProperty.asset_size.size">
                <label class="form-check-label" for="flexRadioDefault1">
                  Less Than
                </label>
              </div>
            </div>
            <div class="col-auto my-1 d-flex">
              <div class="form-check ps-0">
                <input class="form-check-input" type="radio" name="SizeRadioAdvance" id="flexRadioDefault2"
                  value="greater_than" [(ngModel)]="searchWithAdvProperty.asset_size.size_for"
                  (click)="changeRadioButtonForAssetSize('greater_than')"
                  [disabled]="!searchWithAdvProperty.asset_size.size">
                <label class="form-check-label" for="flexRadioDefault2">
                  Greater Than
                </label>
              </div>
            </div>

          </div>
        </div>
        <div class="border-bottom w-100 mb-1"></div>
        <div class="Datewise">
          <div class="fs-6 font-bold title-color mb-1">Date:</div>
          <div class="row" [ngClass]="{'disable': searchWithAdvProperty.search_within.asset_id}">
            <div class="col-auto my-1 d-flex">
              <div class="d-flex flex-column align-items-start">
                <label>Start Date:</label>
                <div class="date-picker-container">
                  <i class="pi pi-calendar date-icon"></i>
                  <p-calendar [(ngModel)]="startDate" dateFormat="mm/dd/yy" class="custom-calendar" [showIcon]="false"
                    inputId="icon" placeholder="mm/dd/yyyy" [maxDate]="calendarMaxDate"
                    (ngModelChange)="onStartChange($event)" [readonlyInput]="true"></p-calendar>
                </div>
              </div>
            </div>
            <div class="col-auto my-1 d-flex">
              <div class="d-flex flex-column align-items-start">
                <label>End Date:</label>
                <div class="date-picker-container">
                  <i class="pi pi-calendar date-icon"></i>
                  <p-calendar [(ngModel)]="endDate" class="custom-calendar" [showIcon]="false" inputId="icon"
                    dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" [disabled]="startDate === undefined"
                    [minDate]="startDate || getCurrentDate()" (ngModelChange)="onendDateChange($event)"
                    [readonlyInput]="true"></p-calendar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0 justify-content-start pt-2">
        <button type="button" class="btn btn-primary d-btn-primary border-0 btn-min-width" data-bs-dismiss="modal"
          (click)="replaceAdvSearchWithMainSearch()"
          [disabled]="!searchWithAdvProperty.adv_search || checkDateValues() || searchWithAdvProperty.adv_search.trim() === '' ">Apply</button>
        <button type="button" class="btn btn-secondary btn-min-width"
          (click)="resetSearchWithAdvProperty()">Reset</button>
      </div>
    </div>
  </div>
</div>
