import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
} from '@angular/core';
import {
  AppDataService,
  AppService,
  LocalStorageService,
} from 'src/app/core/services';
import { FormatS3UrlPipe } from 'src/app/shared/pipes';
import { CommonUtils } from 'src/app/common/utils/common.utils';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { Carousel, CarouselModule } from 'primeng/carousel';
@Component({
  selector: 'app-asset-preview',
  templateUrl: './asset-preview.component.html',
  styleUrls: ['./asset-preview.component.scss'],
  standalone: true,
  imports: [AsyncPipe, CarouselModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AssetPreviewComponent {
  @Input() assetsList: any;
  @Input() currentItem: any;
  @Input() preSignedUrl: any;
  @Output() closeSliderModal = new EventEmitter<any>();
  @Output() onActionPerformed = new EventEmitter();
  assetImageList: any = [];
  assetImageSelected: any = [];
  slideConfig: any;
  formatS3UrlPipe = new FormatS3UrlPipe();
  flattenedItem: any;
  assetPreviewAssetList: any = [];
  selectedItem: any;
  commonUtils = new CommonUtils();
  private assetsSubject = new BehaviorSubject<string>('');
  asset$ = this.assetsSubject.asObservable();
  @ViewChild('carousel') carousel!: Carousel;
  @ViewChild('sliderModal') sliderModal!: ElementRef;
  responsiveOptions = [
    { breakpoint: '1024px', numVisible: 1, numScroll: 1 },
    { breakpoint: '768px', numVisible: 1, numScroll: 1 },
    { breakpoint: '560px', numVisible: 1, numScroll: 1 },
  ];
  currentSlideIndex: number = 0;
  constructor(
    public appService: AppService,
    protected cdRef: ChangeDetectorRef,
    private appDataService: AppDataService,
    private localStorageService: LocalStorageService
  ) {
    this.slideConfig = { slidesToShow: 1, slidesToScroll: 1, infinite: true };
  }
  assetRefresh : boolean = false; 
  async ngOnInit() {
    
    this.selectedItem = {};
    if (this.currentItem._source) {
      this.selectedItem = {
        ...this.currentItem._source,
        _index: this.currentItem._index,
        _id: this.currentItem._id,
        _score: this.currentItem._score,
        sort: this.currentItem.sort,
        isSelected: this.currentItem.isSelected,
      };
    } else {
      this.selectedItem = this.currentItem;
    }
    // loop to remove source
    for (const item of this.assetsList) {
      if (item._source) {
        this.flattenedItem = {
          ...item._source,
          _index: item._index,
          _id: item._id,
          _score: item._score,
          sort: item.sort,
          isSelected: item.isSelected,
        };
        this.assetPreviewAssetList.push(this.flattenedItem);
      } else {
        this.assetPreviewAssetList.push(item);
      }
    }

    // to key in _id format for redirection
    if (!this.assetPreviewAssetList._id) {
      for (const element of this.assetPreviewAssetList) {
        if (element['assetId']) {
          element['_id'] = element['assetId'];
          //delete element['assetId'];
        }
        if (element['id']) {
          element['_id'] = element['id'];
          //delete element['id'];
        }
      }
    }
    if (!this.selectedItem._id) {
      if (this.selectedItem['assetId']) {
        this.selectedItem['_id'] = this.selectedItem['assetId'];
        delete this.selectedItem['assetId'];
      }
      if (this.selectedItem['id']) {
        this.selectedItem['_id'] = this.selectedItem['id'];
        delete this.selectedItem['id'];
      }
    }
    this.fetchInitialImageUrl([this.currentItem._id]);
    this.assetImageList = [];
    this.assetImageSelected = [];
    let indexToSplit = this.assetPreviewAssetList.findIndex(
      (x: any) => x._id == this.selectedItem._id
    );
    let first = this.assetPreviewAssetList.slice(0, indexToSplit);
    let second = this.assetPreviewAssetList.slice(indexToSplit + 1);
    this.assetImageList.push(this.selectedItem);
    this.assetImageList = this.assetImageList.concat(second.concat(first));

    this.assetImageList = this.assetImageList.filter(
      (selectedItem: any) =>
        selectedItem?.assetType == 0 ||
        selectedItem?.assetType == 1 ||
        selectedItem?.assetType == 3
    );
    const initialIndex = this.assetImageList.findIndex(
      (item: any) => item._id === this.currentItem._id
    );
    this.currentSlideIndex = initialIndex >= 0 ? initialIndex : 0;
  }

  closeSlider() {
    this.assetImageList = [];
    this.assetImageSelected = [];
    this.currentSlideIndex = 0;
    this.onActionPerformed.emit({ type: 'close', data: undefined });
  }

  searchResult(id: any, assetPath: any) {
    // this.router.navigateByUrl("/main/" + Id + "/search-result");
    this.appService.searchResult(id, { assetPath: assetPath });
  }

  redirectToForPreviewInNewTab(asset: any) {
    let url = this.formatS3UrlPipe.transform(
      this.appService?.s3BaseUrl + asset?.assetPath + '/' + asset?.assetName
    );
    window.open(`${url}`, '_blank');
  }

  onActionChange(asset: any) {
    this.onActionPerformed.emit({
      type: 'download',
      data: asset,
    });
  }

  async fetchInitialImageUrl(assetId: any) {
    if (!this.preSignedUrl[assetId]) {
      this.preSignedUrl = await this.appDataService.generatePreSignedUrl(
        assetId,
        this.preSignedUrl
      );
    }
    this.assetsSubject.next(this.preSignedUrl[assetId]);
    setTimeout(() => {
      this.assetRefresh = false;  
    },0);
  }
  loadAssets(event: any) {
    const newAssets: any = [];
    this.assetImageList = [...this.assetImageList, ...newAssets];
  }
  onPageChange(event: any): void {
    const newIndex = event.page;
    // this.beforeChange(this.currentSlideIndex, newIndex);
    // this.afterChange(newIndex);
    this.currentSlideIndex = newIndex;
  }

  navigatePrev(): void {
    this.assetsSubject.next('');
    this.assetRefresh = true;
    const prevIndex =
      this.currentSlideIndex > 0
        ? this.currentSlideIndex - 1
        : this.assetImageList.length - 1;

    this.beforeChange(this.currentSlideIndex, prevIndex);
    this.currentSlideIndex = prevIndex;
    this.afterChange(prevIndex);
  }

  navigateNext(): void {
    this.assetsSubject.next('');
    this.assetRefresh = true;
    const nextIndex =
      this.currentSlideIndex < this.assetImageList.length - 1
        ? this.currentSlideIndex + 1
        : 0;

    this.beforeChange(this.currentSlideIndex, nextIndex);
    this.currentSlideIndex = nextIndex;
    this.afterChange(nextIndex);
  }

  beforeChange(prevIndex: number, nextIndex: number): void {
    const prevMediaId = `#media${prevIndex}`;
    const prevMedia = document.querySelector(prevMediaId) as HTMLMediaElement;
    prevMedia?.pause();
  }

  afterChange(currentIndex: number): void {
    const currentAsset = this.assetImageList[currentIndex];
    if (currentAsset) {
      this.fetchInitialImageUrl(currentAsset.id || currentAsset._id);
    }
  }
  get currentAsset(): any {
    return this.assetImageList[this.currentSlideIndex];
  }
  
  //for navigaition via arrow key ----------------------------------------
  ngAfterViewInit(): void {
    this.sliderModal.nativeElement.focus();
  }
  onKeyPress(event: KeyboardEvent): void {
    if (event.code === 'ArrowLeft') {
      this.navigatePrev();
    } else if (event.code === 'ArrowRight') {
      this.navigateNext();
    }
  }
  //---------------------------------------------------------------
}
