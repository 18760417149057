import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { environment } from '../../environment/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public s3BaseUrl =
    environment.infra === 'aws'
      ? environment.s3BaseUrl
      : environment.GCPBaseUrl;
  public previousHitURL: any = ''; //

  constructor(private clipboard: Clipboard, private router: Router) {}

  //common function for copyLinkToClipboard
  copyLinkToClipboard(assetPresignedUrl: any): void {
    this.clipboard.copy(assetPresignedUrl);
  }
  // function to redirected to search result page
  searchResult(id: any, queryParams: any, target?: any, reload?: boolean) {
    queryParams.assetPath = queryParams.assetPath.split('/').slice(1).join('/');
    const urlTree = this.router.createUrlTree(
      ['/main/' + id + '/search-result'],
      {
        queryParams: { ...queryParams },
      }
    );
    if (reload) {
      window.open(urlTree.toString(), target ? target : '_self');
    } else {
      this.router.navigateByUrl(urlTree.toString(), {
        skipLocationChange: false,
        state: {
          urlSendFrom: this.router.url,
        },
      });
    }
  }

  parseSubtitle(srtContent: string): string[] {
    return srtContent.split('\n\n\n');
  }

  getSubtitle(url: string): Observable<string> {
    const fetchPromise = fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain',
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Error while downloading');
      }
      return response.text();
    });
    return from(fetchPromise);
  }

  getImage(url: string): Observable<string> {
    const fetchPromise = fetch(url, {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error while downloading');
        }
        return response.blob(); // Get the response as a Blob
      })
      .then((blob) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string); // Convert Blob to Base64 string
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });

    return from(fetchPromise);
  }

  base64ToBlob(base64: string, mimeType: string): Blob {
    const byteCharacters = atob(base64.split(',')[1]); // Decode the base64 string (ignoring the data URL prefix)
    const byteArrays = [];
  
    // Convert the byte characters to a byte array
    for (let offset = 0; offset < byteCharacters.length; offset++) {
      byteArrays.push(byteCharacters.charCodeAt(offset));
    }
  
    // Create a Blob from the byte array and the given MIME type
    return new Blob([new Uint8Array(byteArrays)], { type: mimeType });
  }
}
