import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
  Inject,
  HostListener,
} from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';
import { MessageService } from 'primeng/api';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  FolderMgmtUtill,
  IFolderDetails,
} from '../../../common/utils/folderMgmtUtill';
import { GdriveShare } from '../../../common/gdriveshare/gdriveshare';
import { DOCUMENT, NgClass, NgStyle } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { Chips, ChipsModule } from 'primeng/chips';
import { CommonUtils } from 'src/app/common/utils/common.utils';
import { environment } from 'src/app/environment/environment';
import {
  API_ENDPOINT,
  API_KEYPOINT,
  APP_EVENTS,
  APP_ROUTE,
  CONFIRMATION_POPUP_CONFIG,
} from 'src/app/core/constants';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  AppDataService,
  EventData,
  EventEmitterService,
  LoaderService, PermissionsEngine,
  RestService,
} from 'src/app/core/services';
import { lastValueFrom, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { ContextMenuComponent } from '../context-menu/context-menu.component';
import { DialogModule } from 'primeng/dialog';
import { ConfirmationPopUpComponent } from '../deletepopup/confirmationPopUp.component';
import { DropdownModule } from 'primeng/dropdown';
import { AddEventModalComponent } from '../add-event-modal/add-event-modal.component';
import { CreateFolderComponent } from '../create-folder/create-folder.component';
import { UploadComponent } from '../upload/upload.component';
@Component({
  selector: 'app-dynamic-nested-list',
  templateUrl: './dynamic-nested-list.component.html',
  styleUrls: ['./dynamic-nested-list.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    ContextMenuComponent,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmationPopUpComponent,
    ChipsModule,
    DropdownModule,
    AddEventModalComponent,
    CreateFolderComponent,
    UploadComponent,
  ],
})
export class DynamicNestedListComponent implements OnInit {
  s3PathToImport: string = '';
  firstItem: IFolderDetails = {
    firstName: 'First',
    lastName: 'Folder',
    userId: 1,
    folderCount: '',
    assignedCount: '',
    parentId: 0,
    assetCounts: '',
    untaggedAssetsCount: '',
    roleId: 1,
    folderName: '',
    folderId: 0,
    isTaged: 0,
    createdAt: '',
    createByFirstname: '',
    createByLastname: '',
    accessType: 2,
    isCollapse: false,
    isFolderRightClick: false,
    children: [],
  };

  @Output() refresh = new EventEmitter();
  @Output() onFolderRefresh = new EventEmitter();
  @Input() items: any;
  @Input() parentId: any;
  @Input() folderContextMenuList: any;
  @Input() assetCountDisableFlag: boolean;
  @Input() isOpenedTrhoughModal: any = false;
  @Input() trackOFCurrentFolder: any;
  @ViewChild('contextMenuRightClick', { static: false })
  contextMenuRightClick: ElementRef;
  @ViewChild('chips') chips: Chips;
  queryParams: any;
  userId: any;
  userDetails: any;
  selectedItems: any = [];
  contextmenuX = -9999;
  contextmenuY = -9999;
  isDeleteModalShow: boolean = false;
  isImportFolderByS3PathShow: boolean = false;
  renameModal: boolean = false;
  folderArray: any = [];
  isFolderTag: boolean = false;
  renameForm: any;
  uploadTagsForm: any;
  folderPath: any;
  eventsList: any;
  submitted: boolean = false;
  assetsIdArray: any = [];
  IsmodelEventShow: boolean = false;
  event: any = {
    eventName: '',
    eventTime: '',
    description: '',
    eventLocation: '',
  };
  totalText = 1000;
  remainingText: number;
  folderName: any;
  assetsDetails: any = [];
  totalUntagRecords: any = 0;
  assetPendingCount: any = 0;
  assetCompleteCount: any = 0;
  assetStatus: any;
  assetTag: any = [0, 1];
  isCreateModalShow: boolean = false;
  isUploadModalClose: boolean = false;
  modalType: any;
  childFolderId = 0;
  assetUploadPath: any;
  folderDetails: any;
  objectForSourceTrack: any;
  sourceLocation: any = '';
  sourceFolderId: any = '';
  accessFolderRights: any;
  isbulkFolderAiTag: boolean = false;
  toastMsgbulkFolderAiTag: any;
  importConfig = {
    importFrom: environment.infra === 'aws' ? 'S3' : 'GCS',
    importFromLabel:
      'Import Folder ' +
      (environment.infra === 'aws' ? 'By S3 Path' : 'from GCS'),
  };
  createfolderDetails: any;
  commonUtils = new CommonUtils();
  currentItemId: any;
  currentItem: IFolderDetails;
  private folderScrollEventHandler: EventListenerOrEventListenerObject;
  subscription: Subscription[] = [];
  private eventSubscription: Subscription | undefined;
  folderType: any = 'sub';
  isScrollFolder: boolean = false;
  private isContextMenuOpen = false;
  private contextListen: (() => void) | undefined;
  preSignedUrl: any = {};
  constructor(
    private eRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    public renderer: Renderer2,
    public permissionsEngine: PermissionsEngine,
    private gdriveShare: GdriveShare,
    private clipboard: Clipboard,
    private folderMgmt: FolderMgmtUtill,
    public appService: AppService,
    public restService: RestService,
    public authService: AuthService,
    private messageService: MessageService,
    private appDataService: AppDataService,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private loadingService: LoaderService
  ) {
    (this.renameForm = new FormGroup({
      updatedName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern('^[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$'),
      ]),
    })),
      /* Add validation for mandatory fields */
      (this.uploadTagsForm = new FormGroup({
        assetTag: new FormControl(0),
        assetArr: new FormControl(''),
        tagArray: new FormControl([]),
        eventId: new FormControl(''),
        folderId: new FormControl(''),
      })),
      (this.userDetails = this.authService.getAuthData());
    if (this.userDetails) {
      this.userId = this.userDetails.userId;
    }
    /* This events get called by all clicks on the page */
    this.contextListen = this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.contextMenuRightClick?.nativeElement) {
        this.disableContextMenu();
        this.folderMgmt.removeFolderHighlight();
        document
          .getElementsByTagName('body')[0]
          .classList.remove('context-menu-body-opened');
      }
    });
  }
  async ngOnInit() {
    this.assetUploadPath = this.folderDetails?.id_path.substring(
      0,
      this.folderDetails?.id_path.length - 1
    );
    this.folderMgmt.transferApiDataObservar$.subscribe(async (data) => {
      if (
        data &&
        data.data &&
        data.data.result &&
        data.data.result.length > 0 &&
        data.flag == true
      ) {
        await this.callFolderDetails(data.data, data.id);
        data.flag = false;
      }
    });
    this.subscribeToEvent();
    this.subscription.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // Re-subscribe to the event if necessary when navigation ends
          if (!this.eventSubscription || this.eventSubscription.closed) {
            this.subscribeToEvent();
          }
        }
      })
    );
  }

  subscribeToEvent(): void {
    this.eventSubscription = this.eventEmitterService.subscribe(
      (event: EventData) => {
        if (event.type === APP_EVENTS.BROWSER_BACK) {
          if (
            this.isDeleteModalShow ||
            this.IsmodelEventShow ||
            this.isCreateModalShow ||
            this.renameModal ||
            this.isFolderTag ||
            this.isImportFolderByS3PathShow ||
            this.isUploadModalClose
          ) {
            this.isDeleteModalShow = false;
            this.IsmodelEventShow = false;
            this.isCreateModalShow = false;
            this.renameModal = false;
            this.isFolderTag = false;
            this.isImportFolderByS3PathShow = false;
            if (this.isUploadModalClose) {
              this.isUploadModalClose = false;
              this.emptyFileList();
            }
            this.router.navigate([APP_ROUTE.assets], { replaceUrl: true });
          }
        }
      }
    );
    this.subscription.push(this.eventSubscription);
  }

  // ================== isCollapse folder method for open parent folder ( dynamic list of all uploads/core uploads)
  async openFolder(item: any) {
    this.items.children.forEach((item1: any) => {
      item1.folderId === item.folderId
        ? (item.isCollapse = !item.isCollapse)
        : (item1.isCollapse = false);
    });
    if (item.isCollapse == true) {
      if (this.trackOFCurrentFolder && this.trackOFCurrentFolder.length > 0) {
        this.trackOFCurrentFolder.pop();
      }
      this.trackOFCurrentFolder.push(item.folderId);
    }
    await this.refresh.emit(item);
  }
  // ================== isCollapse sub-folder method for open sub-folder folder ( dynamic list of all uploads/core uploads)
  refreshed(event: any) {
    if (event) {
      this.refresh.emit(event);
    }
  }
  // ============= open folder click for right click copy/ move folder ===== //
  openFolderRefreshMethod(event: any) {
    if (event) {
      this.onFolderRefresh.emit(event);
    }
  }
  //=========== disable click out of context menu click=========//
  clickedOutsideMenu() {
    this.disableContextMenu();
  }

  //=========== disable context menu click=========//
  disableContextMenu() {
    if (this.currentItem) this.currentItem.isFolderRightClick = false;
    this.isScrollFolder = false;
  }
  //=========== perfrom action based on context menu click=========//
  handleMenuSelection(menuselection: any) {
    switch (menuselection) {
      case 'createFolder':
        this.openCreateModal('sub');
        break;
      case 'uploadAsset':
        this.openAssetsModal('image');
        break;
      case 'zipUpload':
        this.openAssetsModal('zip');
        break;
      case 'copyFolder':
        this.assetActionFunction('copy');
        break;
      case 'moveFolder':
        this.assetActionFunction('move');
        break;
      case 'delete':
        this.opendeleteModal();
        break;
      case 'download':
        this.onFolderDownload();
        break;
      case 'tagFolder':
        this.onFolderTag();
        break;
      case 'rename':
        this.onRename();
        break;
      case 'importFolderByS3Path':
        this.openimportFolderByS3PathModal();
        break;
      case 'share':
        this.shareFolderUrl();
        break;
      case 'whatsapp':
        this.whatsappShare();
        break;
      case 'mail':
        this.emailShare();
        break;
      case 'copyLink':
        this.clipboard.copy(this.shareFolderUrl());
        this.messageService.add({
          severity: 'success',
          summary: 'Success!',
          detail: 'Folder Link Copied!',
        });
        break;
      case 'gDrive':
        this.gdriveShareUploads();
        break;
      case 'bulkAiTag':
        this.callBulkAiTag();
        break;
      default:
        this.disableContextMenu();
        break;
    }
  }
  //=========== open rename modal =========//
  onRename() {
    this.disableContextMenu();
    this.renameModal = true;
    this.renameForm.patchValue({
      updatedName: this.folderName,
    });
  }

  //============= Get URL of the selected folder ================//
  shareFolderUrl() {
    let editUrl = '';
    let clientId = '';
    if (this.assetUploadPath !== undefined) {
      editUrl = this.assetUploadPath.split('/');
      clientId = editUrl[0];
      editUrl = this.commonUtils
        .removeClinetID(editUrl)
        .filter((segment: any) => segment !== '')
        .join(',');
    }
    let shareLink =
      window.location.origin +
      APP_ROUTE.assets +
      '/' +
      '?folderPath=' +
      editUrl +
      '/' +
      clientId;
    return shareLink;
  }

  //=========== download folder call=========//
  onFolderDownload() {
    this.disableContextMenu();
    this.folderArray = [];
    if (this.selectedItems && this.selectedItems.length) {
      if (this.selectedItems[0].assetConts == 0) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'This folder is empty! Please add some assets before downloading it.',
        });
      } else {
        let id = this.selectedItems[0].folderId;
        let url = this.shareFolderUrl();
        this.folderMgmt.setOpenQueueTrigger(true);
        const params = {
          folderId: id,
          folderURL: url,
        };

        const options = {
          responseType: 'blob',
        };

        this.restService
          .post(
            API_ENDPOINT.baseEndpoint +
              this.userDetails.clientId +
              '/' +
              API_ENDPOINT.folder.folderDownload,
            params,
            options
          )
          .subscribe({
            next: (res: any) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success!',
                detail:
                  'Zip will be downloaded and will be sent to you via email.',
              });
            },
            error: (error: any) => {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning!',
                detail: error.error.message,
              });
            },
          });
        this.logs([id], 'FOLDERDOWNLOAD');
      }
    }
  }
  //=========== rename folder call=========//
  updateName() {
    let folderName = this.renameForm.value.updatedName.trim();
    let id = this.selectedItems[0].folderId;
    let parentId = this.selectedItems[0].parentId;

    let params = {
      folderName,
      parentId,
    };
    this.restService
      .patch(
        API_ENDPOINT.baseEndpoint +
          this.userDetails.clientId +
          '/' +
          API_ENDPOINT.folder.renameFolder +
          id,
        params
      )
      .subscribe({
        next: async (res: any) => {
          if (res.code === 200) {
            let objectForSourceTrack = this.objectForSourceTrack;
            objectForSourceTrack.folderName = folderName;
            objectForSourceTrack.sourceLocation = this.sourceLocation;
            let params = {
              fileDetails: objectForSourceTrack,
              rename: 'rename',
            };
            this.onFolderRefresh.emit(params);
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Folder renamed successfully',
            });
            this.appDataService.addAssetToSelectedAssets(new Map());
          }
          this.renameModal = false;
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
    this.renameForm.reset();
    this.renameModal = false;
  }
  //============= open delete folder confirmation modal  =========//
  opendeleteModal() {
    this.isDeleteModalShow = true;
    CONFIRMATION_POPUP_CONFIG.TITLE = 'Are you sure you want to Delete?';
    CONFIRMATION_POPUP_CONFIG.SUBTITLE = '';
    this.disableContextMenu();
  }
  //============= close delete folder modal  =========//
  closedeleteModal() {
    this.isDeleteModalShow = false;
    this.disableContextMenu();
  }
  //=============  folder delete method =========//
  async onDeleteFolder() {
    this.isDeleteModalShow = true;
    this.disableContextMenu();
    this.folderArray = [];
    this.selectedItems.forEach((ele: any) => {
      this.folderArray.push({
        folderId: ele.folderId,
        folderName: ele.folderName,
        folderPath: this.folderPath,
      });
    });
    this.restService
      .delete(
        API_ENDPOINT.baseEndpoint +
          this.userDetails.clientId +
          '/' +
          API_ENDPOINT.folder.folderDelete +
          this.folderArray[0].folderId
      )
      .subscribe({
        next: async (data: any) => {
          if (data.code === 200) {
            if (this.selectedItems[0].parentId === 0) {
              let queryParam = {
                limit: 500,
                pageno: 1,
                parentId: this.selectedItems[0].parentId,
                userId: this.userId,
                isFolderSearch: false,
              };
              await this.folderMgmt
                .setFolderElementList(queryParam)
                .then((obj) => {
                  this.folderMgmt.getTrackRecord(
                    this.selectedItems[0].parentId
                  ).children = this.folderMgmt.getFolderList();
                });
            }

            this.isDeleteModalShow = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Folder deleted successfully',
            });
            let params = {
              fileDetails: this.objectForSourceTrack,
              delete: 'delete',
            };
            this.onFolderRefresh.emit(params);
          }
        },
        error: (err: any) => {
          this.isDeleteModalShow = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
        },
      });
    this.logs([this.selectedItems[0].folderId], 'FOLDERDELETE');
  }

  //============= Import Folder By S3 Path modal work start =========//
  openimportFolderByS3PathModal() {
    this.isImportFolderByS3PathShow = true;
    this.disableContextMenu();
  }
  closeimportFolderByS3PathModal() {
    this.isImportFolderByS3PathShow = false;
    this.disableContextMenu();
    //clean path
    this.s3PathToImport = '';
  }
  //hit api and get import folder form S3
  importFolderByS3Path() {
    let requestBody = {
      url: '',
      folderId: Number,
    };
    requestBody.url = this.s3PathToImport;
    requestBody.folderId = this.selectedItems[0].folderId;
    //hit api
    this.restService
      .post(
        API_ENDPOINT.baseEndpoint +
          this.userDetails.clientId +
          '/' +
          API_ENDPOINT.folder.importFolder,
        requestBody
      )
      .subscribe({
        next: (data: any) => {
          this.closeimportFolderByS3PathModal();
          let params = {
            fileDetails: this.objectForSourceTrack,
            importFolderFromS3: 'importFolderFromS3',
          };
          this.onFolderRefresh.emit(params);
          this.messageService.add({
            severity: 'success',
            summary: 'Folder Path is Accepted',
            detail: data.message,
          });
        },
        error: (error: any) => {
          this.closeimportFolderByS3PathModal();
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
  }
  //============= Import Folder By S3 Path modal work end =========//

  //This function is used to check valid tags while tagging any asset
  checkInput(event: any) {
    if (
      event.value.replace(/[^a-z0-9\-\s]/gi, '').length < event.value.length
    ) {
      this.uploadTagsForm.value.tagArray.pop(); // remove last entry from values
    }
  }

  /* =======================Getting event list into dropdown==============================*/

  async getEvents() {
    this.restService
      .get(
        `${API_ENDPOINT.baseEndpoint}${
          this.authService.getAuthData()?.clientId
        }/${API_ENDPOINT.event.getEvents}`
      )
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            this.eventsList = data.result.result;
            if (this.eventsList && this.eventsList.length) {
              this.eventsList.filter((item: any) => {
                item['eventId'] = item.id;
              });
            }
          }
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
  }

  /* =======================Function to upload tags to assets==============================*/

  uploadTags() {
    this.submitted = true;
    this.uploadTagsForm.value.assetArr = this.assetsIdArray;
    this.uploadTagsForm.value.assetTag =
      this.uploadTagsForm.value.assetTag === null ||
      this.uploadTagsForm.value.assetTag === undefined ||
      this.uploadTagsForm.value.assetTag === ''
        ? 0
        : this.uploadTagsForm.value.assetTag;
    this.uploadTagsForm.value.folderId = this.selectedItems[0].folderId;
    //set Default value for form attributes
    if (this.uploadTagsForm.value.tagArray == null) {
      this.uploadTagsForm.value.tagArray = [];
    }
    if (this.uploadTagsForm.value.assetTag == null) {
      this.uploadTagsForm.value.assetTag = 0;
    }
    this.restService
      .post(API_KEYPOINT.assets.uploadBulkTags, this.uploadTagsForm.value)
      .subscribe({
        next: async (data: any) => {
          if (data.code == 200) {
            this.submitted = false;
            // Reset the form after submission and set the default value for the radio button
            this.resetTagForm();
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Tags & Metadata updated successfully!',
            });
            this.objectForSourceTrack.isCollapse = false;
            let params = {
              fileDetails: this.objectForSourceTrack,
              tagFolder: 'tagFolder',
            };
            this.onFolderRefresh.emit(params);
            this.isFolderTag = false;
            this.document.body.classList.remove('p-overflow-hidden-1');
          }
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
    if (this.chips && this.chips.inputViewChild) {
      const inputElement = this.chips.inputViewChild.nativeElement;
      if (inputElement) {
        inputElement.value = '';
      }
    }
  }
  //====================RESET TAG FORM ====================================//
  resetTagForm() {
    this.uploadTagsForm.reset({
      assetTag: 0, // Set the assetTag control to 0 (Pending) by default
    });
    this.uploadTagsForm.markAsPristine();
    this.uploadTagsForm.markAsUntouched();
    this.assetTag = [0, 1];
  }

  cancel() {
    this.renameForm.reset();
    this.renameModal = false;
    this.disableContextMenu();
    this.selectedItems = [];
  }
  openEventModal() {
    this.IsmodelEventShow = true;
  }

  goBack() {
    this.submitted = false;
    this.isFolderTag = false;
    let assetTag = this.uploadTagsForm.value.assetTag;
    this.uploadTagsForm.reset();
    this.uploadTagsForm.markAsPristine();
    this.uploadTagsForm.markAsUntouched();
    this.uploadTagsForm.controls['assetTag'].setValue(assetTag);
    this.selectedItems = [];
    this.disableContextMenu();
    this.IsmodelEventShow = false;
    if (this.isFolderTag == false) {
      this.document.body.classList.remove('p-overflow-hidden-1');
    } else {
      this.document.body.classList.toggle('p-overflow-hidden-1');
    }
    if (this.chips && this.chips.inputViewChild) {
      const inputElement = this.chips.inputViewChild.nativeElement;
      if (inputElement) {
        inputElement.value = '';
      }
    }
  }

  closeEventModal(event: any) {
    if (event && event.id) {
      this.uploadTagsForm.value.eventId = event.id;
      this.getEvents();
    }
    this.IsmodelEventShow = false;
    this.document.body.classList.add('p-overflow-hidden-1');
  }

  callFolderInfo() {
    this.isbulkFolderAiTag = false;
    if (this.selectedItems && this.selectedItems.length) {
      this.restService
        .get(
          API_ENDPOINT.baseEndpoint +
            this.userDetails.clientId +
            '/' +
            API_ENDPOINT.folder.folderInfo +
            this.selectedItems[0].folderId
        )
        .subscribe((data: any) => {
          this.folderName = this.selectedItems[0].folderName;
          if (data && data.result.length && data.result[0]) {
            this.createfolderDetails = data.result[0];
            this.folderPath = data.result[0].id_path;
            this.assetUploadPath = this.folderPath.substring(
              0,
              this.folderPath.length - 1
            );
            const numAssets = parseInt(this.createfolderDetails.assets);
            const numImages = parseInt(this.createfolderDetails.imageCount);
            // Bug 182945: DAM : Admin : User gets success toast for folder having zero assets and other than image assets after generating bulk AI tag.

            if (numAssets === 0 && numImages === 0) {
              this.isbulkFolderAiTag = false;
              this.toastMsgbulkFolderAiTag =
                'The folder you are attempting to tag is empty';
            } else if (numAssets >= 1 && numImages === 0) {
              this.isbulkFolderAiTag = false;
              this.toastMsgbulkFolderAiTag =
                'There is no image in the folder you are trying to tag';
            } else if (numAssets >= 1 && numImages >= 1) {
              this.isbulkFolderAiTag = true;
            }
          }
        });
    }
  }

  async onFolderTag() {
    this.assetsIdArray = [];
    this.queryParams = {
      search: '',
      folderId: this.selectedItems[0].folderId,
      searchedBy: 1,
      assetType: [],
      sortBy: 'DESC',
      fromDate: '',
      toDate: '',
      require: 'allRecords',
      pageno: 1,
      tagCategory: 0,
      assetTag: this.assetTag,
    };
    let keypoint =
      API_ENDPOINT.baseEndpoint +
      this.userDetails.clientId +
      '/' +
      API_ENDPOINT.assets.assetsList;
    await new Promise((resolve, reject) => {
      this.restService.post(keypoint, this.queryParams).subscribe({
        next: async (data: any) => {
          if (data.code == 200) {
            this.assetsDetails = data.result;
            this.totalUntagRecords = this.assetsDetails.length
              ? data.totalCount
              : 0;
            this.assetPendingCount = this.assetsDetails.filter(
              (x: any) => x.assetTag == 0
            ).length;
            this.assetCompleteCount = this.assetsDetails.filter(
              (x: any) => x.assetTag == 1
            ).length;

            if (this.assetsDetails && this.assetsDetails.length) {
              this.assetsDetails.forEach((element: { id: any }) => {
                this.assetsIdArray.push(element.id);
              });
            }
            this.disableContextMenu();
            if (
              this.selectedItems &&
              this.selectedItems.length &&
              this.totalUntagRecords === 0
            ) {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning!',
                detail: 'There are no assets in this folder to tag!',
              });
            } else {
              this.isFolderTag = true;
              await this.getEvents();
            }
          }
          resolve(data);
        },
        error: (err: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
          reject(err.error.message);
        },
      });
    });
  }
  //============= Open create folder modal  =========//
  openCreateModal(type: any) {
    this.disableContextMenu();
    this.isCreateModalShow = true;
    this.folderType = type;
  }
  //============= close create folder modal  =========//
  closeCreateModal(event: any) {
    this.isCreateModalShow = false;
    this.disableContextMenu();
    this.onFolderRefresh.emit(event);
  }
  openAssetsModal(modalType: any) {
    this.modalType = modalType;
    this.isUploadModalClose = true;
    this.disableContextMenu();
  }
  closeModalEvent(event: any) {
    this.isUploadModalClose = false;
    this.disableContextMenu();
    if (event && this.modalType === 'zip') {
      let params = {
        fileDetails: this.objectForSourceTrack,
        zipUpload: 'zipUpload',
        folderId: event.folderId,
      };
      this.onFolderRefresh.emit(params);
    } else if (event && this.modalType === 'image') {
      let params = {
        fileDetails: this.objectForSourceTrack,
        folderId: event.folderId,
        assetUploadCount: event.assetUploadCount,
      };
      this.onFolderRefresh.emit(params);
    }
  }
  //================= cancel btn click while assets upload============
  emptyFileList() {
    this.selectedItems = [];
    let tempParentFolder: IFolderDetails = this.objectForSourceTrack;
    // Add a check to prevent infinite loading
    while (
      tempParentFolder.parentId != undefined &&
      tempParentFolder.parentId != 0
    ) {
      tempParentFolder = this.folderMgmt.getTrackRecord(
        tempParentFolder.parentId
      );
      tempParentFolder.isCollapse = true;
    }
    this.firstItem.children.forEach((item1: any) => {
      item1.folderId === tempParentFolder.folderId
        ? tempParentFolder.isCollapse === true
        : (item1.isCollapse = false);
    });
    this.appDataService.notifyChange(this.objectForSourceTrack);
    this.appDataService.addAssetToSelectedAssets(new Map());
  }
  assetActionFunction(actionType: 'copy' | 'move') {
    this.disableContextMenu();
    if (this.selectedItems.length === 0) return;
    const params = {
      fileDetails: this.objectForSourceTrack,
      itemsCopyFolder: true,
      assetsHeader: actionType === 'copy' ? 'Copy To' : 'Move to',
      assetsFooter: actionType === 'copy' ? 'Copy' : 'Move',
      assetsCopyType: actionType,
      folderCopyMove: 'folderCopyMove',
      sourceFolderId: this.sourceFolderId,
      sourceLocation: this.sourceLocation,
    };
    this.onFolderRefresh.emit(params);
  }
  //==============call Folder info api for getting source localtion=========//
  async callFolderDetails(data: any, id: any) {
    return new Promise(async (resolve) => {
      this.sourceFolderId = id;
      if (data && data.result.length && data.result[0]) {
        this.sourceLocation = data.result[0]?.id_path.substring(
          0,
          data.result[0]?.id_path.length - 1
        );
        this.accessFolderRights = data.result[0]?.accessType;
      }
      resolve(false);
    });
  }
  ngOnDestroy() {
    document
      .getElementsByTagName('body')[0]
      .classList.remove('context-menu-body-opened');
    this.subscription.forEach((s) => s.unsubscribe());
    if (this.contextListen) {
      this.contextListen();
    }
  }

  // function for share via Email

  emailShare() {
    // Encode the email body for the mailto link
    const encodedEmailBody = encodeURIComponent(this.shareFolderUrl());

    // Create the mailto link
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      'Folder Share'
    )}&body=${encodedEmailBody}`;

    // Open the mailto link in a new browser window or tab
    window.open(mailtoLink, '_blank');
    this.logs([this.selectedItems[0].folderId], 'FOLDERSHARE');
  }

  // function for share via Whatsapp

  whatsappShare() {
    const sharingText = this.shareFolderUrl();
    const whatsappLink = `https://web.whatsapp.com/send/?text=${encodeURIComponent(
      sharingText
    )}`;
    window.open(whatsappLink);
    this.logs([this.selectedItems[0].folderId], 'FOLDERSHARE');
  }

  // function share via gDrive

  async gdriveShareUploads(): Promise<void> {
    try {
      // Check if the user is logged in
      if (!this.gdriveShare.isUserLoggedIn()) {
        await this.gdriveShare.getUserLoggedIn();
        this.messageService.add({
          severity: 'success',
          summary: 'Share Initiated',
          detail: `Folder creation started on your Google Drive.`,
        });
      }
      this.loadingService.setLoading(true);
      // Perform the folder sharing task
      await this.appDataService.functionToShareFolderOnGDrive(this.currentItem);
  
      // Perform the next task after successful operation
      this.logs([this.selectedItems[0].folderId], "FOLDERSHARE");
      this.messageService.add({
        severity: 'success',
        summary: 'Success!',
        detail: `All assets added to your G-drive.`,
      });
    } catch (error) {
      // Handle errors
      console.error("An error occurred during the folder sharing process:", error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error!',
        detail: `Something went wrong, please refresh and retry`,
      });
    } finally {
      // Ensure loading is turned off in all cases
      this.loadingService.setLoading(false);
    }
  }
  

  checkFolderAccessForModal(item: any) {
    if (this.isOpenedTrhoughModal) {
      return item.accessType != 1;
    } else {
      return true;
    }
  }
  //=============  bulk AI Tagging method =========//
  async callBulkAiTag() {
    if (this.isbulkFolderAiTag) {
      this.disableContextMenu();
      this.queryParams = {
        folderId: Number(this.selectedItems[0].folderId),
      };
      this.messageService.add({
        severity: 'success',
        summary: 'Success!',
        detail:
          'AI tags are being generated. Please refresh later to view the tags.',
      });
      this.restService
        .get(
          `${API_ENDPOINT.baseEndpoint}${
            this.authService.getAuthData()?.clientId
          }/${API_ENDPOINT.elk.bulkAiTag}/${this.queryParams.folderId}`
        )
        .subscribe({
          next: (data: any) => {
            if (data.code == 200) {
              // this.messageService.add({ severity: 'success', summary: 'Success!', detail: data.message });
            }
          },
          error: (err: any) => {
            // Bug 182944: DAM : Admin : Two overlapping success and warning toasts are displayed for Bulk AI tagging.
            //this.messageService.add({ severity: 'warn', summary: 'Warning!', detail: err.error.message });
          },
        });
    } else {
      this.disableContextMenu();
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: this.toastMsgbulkFolderAiTag,
      });
    }
  }
  logs(folderId: any, action: any) {
    const logs = {
      data: {
        folderId: folderId,
      },
      action: action,
    };
    this.appDataService.serverLog(logs);
  }
  //get the window position and context menu position
  async getContextMenuPosition(itemId: any, item: any) {
    // Old code
    // await this.folderContextMenuList.filter(async (el: any) => {
    //   // Enhancement 137748: Hiding "TRIM" functionality on temporary basis
    //   // added first if codition to hide the copyFolder and moveFolder option
    //   if (
    //     item.parentId == 0 &&
    //     (el.id == 'copyFolder' || el.id == 'moveFolder')
    //   ) {
    //     el['hasPermission'] = false;
    //   } else {
    //     el['hasPermission'] =
    //       await this.permissionsEngine.folderAccessOnHasPermission(
    //         item.folderId,
    //         el.minPermission
    //       );
    //   }
    // });

    // another appoarch:137748
    // for (const el of this.folderContextMenuList) {
    //   // Enhancement 137748: Hiding "TRIM" functionality on temporary basis
    //   // added first if codition to hide the copyFolder and moveFolder option
    //   const isRootFolder = item.parentId === 0;
    //   const isRestrictedAction = el.id === 'copyFolder' || el.id === 'moveFolder';

    //   el['hasPermission'] = isRootFolder && isRestrictedAction
    //     ? false
    //     : await this.permissionsEngine.folderAccessOnHasPermission(
    //         item.folderId,
    //         el.minPermission
    //       );
    // }

    // approach 2:137748
    this.folderContextMenuList = await Promise.all(
      this.folderContextMenuList.map(async (el: any) => {
        // Hide "copyFolder" and "moveFolder" options when parentId is 0
        if (
          item.parentId === 0 &&
          (el.id === 'copyFolder' || el.id === 'moveFolder')
        ) {
          el['hasPermission'] = false;
        } else {
          el['hasPermission'] =
            await this.permissionsEngine.folderAccessOnHasPermission(
              item.folderId,
              el.minPermission
            );
        }
        return el;
      })
    );

    this.isScrollFolder = false;
    const element = document.querySelector(`#${itemId}`) as HTMLElement;
    if (element) {
      const rect = element.getBoundingClientRect();
      this.contextmenuX = rect.right + window.scrollX;
      this.contextmenuY = rect.top + window.scrollY;
      setTimeout(() => {
        const menuElement = this.eRef.nativeElement.querySelector(
          `#currentItemUnique-${item.folderId}`
        );
        if (menuElement) {
          const menuRect = menuElement.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          const windowWidth = window.innerWidth;

          if (this.contextmenuY + menuRect.height > windowHeight) {
            this.contextmenuY = windowHeight - menuRect.height;
          }
          if (this.contextmenuX + menuRect.width > windowWidth) {
            this.contextmenuX = windowWidth - menuRect.width;
          }
          this.contextmenuY = Math.max(0, this.contextmenuY);
          this.contextmenuX = Math.min(300, this.contextmenuX);
          this.isContextMenuOpen = true;
        }
      }, 0);
    }
  }
  async scrollBasedOnItemPosition(item: any) {
    const container = document.getElementById('folderMngScrollContainer'); // Scrollable container
    if (container) {
      const containerRect = container.getBoundingClientRect(); // Get the container's position relative to the window
      const menuElement = this.eRef.nativeElement.querySelector(
        `#item-${item.folderId}`
      ); // The folder element clicked

      if (menuElement) {
        await new Promise((resolve) => setTimeout(resolve, 50));
        const elementRect = menuElement.getBoundingClientRect();
        if (elementRect.top < containerRect.top) {
          container.scrollTo({
            top: container.scrollTop - (containerRect.top - elementRect.top),
            behavior: 'smooth',
          });
        } else if (elementRect.bottom > containerRect.bottom) {
          const scrollAmount = elementRect.bottom - containerRect.bottom;
          container.scrollTo({
            top: container.scrollTop + scrollAmount,
            behavior: 'smooth',
          });
          this.scrollIntoView(menuElement);
        }
      }
    }
    setTimeout(() => this.getContextMenuItem(item), 0);
  }

  //=========== scroll the folder position based on context menu position=========//
  async scrollIntoView(element: HTMLElement) {
    return new Promise<void>((resolve) => {
      const rect = element.getBoundingClientRect();
      const initialX = rect.right + window.scrollX;
      window.scrollTo({
        left: initialX,
        behavior: 'smooth',
      });
      this.isScrollFolder = true;
    });
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    const clickedInside = this.eRef.nativeElement.contains(event.target);
    if (this.currentItem?.isFolderRightClick) {
      if (!clickedInside) {
        this.onClickOutside(event);
      }
    }
  }
  onClickOutside(event: MouseEvent) {
    this.currentItem.isFolderRightClick = false;
  }
  @HostListener('document:contextmenu', ['$event'])
  onRightClick(event: MouseEvent) {
    let clickedInside = this.eRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.disableContextMenu();
    }
  }
  async handleFolderClick(
    event: MouseEvent,
    item: IFolderDetails,
    index: number
  ) {
    event.preventDefault();
    if (event.button === 0) {
      if (this.trackOFCurrentFolder && this.trackOFCurrentFolder.length > 0) {
        this.trackOFCurrentFolder.pop();
      }
      item.isFolderRightClick = false;
      await this.openFolder(item);
    }
    if (event.button === 2) {
      await this.handleRightClick(item);
    }
  }
  async handleRightClick(item: IFolderDetails) {
    if (
      this.trackOFCurrentFolder[this.trackOFCurrentFolder.length - 1] !=
      item.folderId
    ) {
      if (!item.isFolderRightClick) {
        this.disableContextMenu();
        this.currentItem = item;
        this.currentItem.isFolderRightClick = true;

        if (!item.isCollapse) {
          await this.handleFolderExpand(item);
        } else {
          await this.openFolder(this.currentItem);
          requestAnimationFrame(async () => {
            await this.openFolder(item);
          });
        }
        await this.scrollBasedOnItemPosition(item);
      } else {
        await this.openFolder(this.currentItem);
        requestAnimationFrame(async () => {
          await this.openFolder(item);
        });
        await this.scrollBasedOnItemPosition(item);
      }
    } else if (item.isCollapse == true) {
      this.currentItem = item;
      this.currentItem.isFolderRightClick = true;
      this.folderMgmt.highlightFolder(item);
      await this.scrollBasedOnItemPosition(item);
    }
  }
  // Handles folder expansion and ensures children are loaded
  private async handleFolderExpand(item: IFolderDetails) {
    await new Promise<void>((resolve) => {
      this.permissionsEngine.setFolderlistByUserId();
      this.openFolder(item).then(() => {
        resolve();
      });
    });
  }

  async getContextMenuItem(item: any) {
    let allContextMenus: any =
      document.getElementsByClassName('checkRightClickClass') || [];
    for (let i = 0; i < allContextMenus?.length; i++) {
      let oneItem = allContextMenus[i];
      oneItem.classList.add('hideContextMenuElement');
    }

    setTimeout(() => {
      let currentContextMenu: any = document.getElementById(
        `currentItemUnique-${item.folderId}`
      );
      currentContextMenu?.classList.add('displayHideContextMenuElement');
      this.getItemPosition(`item-${item.folderId}`, item);
      currentContextMenu?.classList.remove('hideContextMenuElement');
      currentContextMenu?.classList.add('showContextMenuElement');
      this.folderMgmt.highlightFolder(item);
    }, 0);
  }

  async getItemPosition(itemId: any, item: any) {
    this.currentItemId = itemId;
    if (item.isCollapse) {
      this.folderMgmt.setTrackRecord(item);
      this.objectForSourceTrack = item;
      this.selectedItems = [];
      this.selectedItems.push(item);
      this.childFolderId = this.selectedItems[0].folderId;
      await this.callFolderInfo();
      item.isFolderRightClick = true;
      setTimeout(() => this.getContextMenuPosition(itemId, item), 0);
    }
  }
  listenScrollDisableContextMenu() {
    const scrollContainer = document.getElementById('folderMngScrollContainer');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', () => {
        if (this.isScrollFolder) {
          this.isScrollFolder = false;
          return;
        }
        if (this.isContextMenuOpen) {
          this.disableContextMenu();
          this.isContextMenuOpen = false; // Set flag to false
          this.folderMgmt.removeFolderHighlight();
        }
      });
    }
  }
  scrollToAssetContainerTop() {
    let ele = document.getElementById('folderMngScrollContainer');
    if (ele) {
      ele.scrollTop = 0;
      this.isScrollFolder = true;
    }
  }
  listenAssetScroll() {
    let ele = document.getElementById('folderMngScrollContainer');
    this.folderScrollEventHandler = () => {
      if (this.isScrollFolder) {
        this.isScrollFolder = false;
        return;
      }
      if (this.isContextMenuOpen) {
        this.disableContextMenu();
        this.isContextMenuOpen = false; // Set flag to false
        this.folderMgmt.removeFolderHighlight();
      }
      if (ele) {
        ele.removeEventListener('scroll', this.folderScrollEventHandler);
      }
      this.reAddScrollListener();
    };
    if (ele) {
      ele.addEventListener('scroll', this.folderScrollEventHandler);
    }
  }

  reAddScrollListener(): void {
    let ele = document.getElementById('folderMngScrollContainer');
    if (ele) {
      ele.addEventListener('scroll', this.folderScrollEventHandler);
    }
  }
  ngAfterViewInit(): void {
    this.listenAssetScroll();
  }

  handleAdd(tagToAdd: any) {
    const tagValue = tagToAdd.value.toLowerCase();
    const tagArray = this.uploadTagsForm.value.tagArray;
    const tagControl = this.uploadTagsForm.get('tagArray');
    const tagPattern = /^[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$/;
    if (!tagPattern.test(tagValue)) {
      tagControl.setErrors({
        invalidTag: 'Only - and _ are allowed as special characters.',
      });
      this.uploadTagsForm.value.tagArray.pop();
      tagToAdd.value = '';
      return;
    }
    for (let i = 0; i < tagArray.length - 1; i++) {
      if (tagArray[i].toLowerCase() === tagValue) {
        this.uploadTagsForm.value.tagArray.pop();
      }
    }
  }
}
