<p-dialog [modal]="true" [closeOnEscape]="false" [(visible)]="show" [showHeader]="false" class="onboard-user-popup" [resizable]="false"
  [draggable]="false" [style]="{'width': '500px','boarder-radius':'5px'}" [dismissableMask]="false">
  <div class="p-3">
    <div class="float-end">
      <button type="button" class="btn-close " aria-label="Close" (click)="close()"></button>
    </div>

    <div class="modal-body p-4 pt-3 mt-4">
      <h5 class="font-medium text-center">{{confirmationPopUpConfig.TITLE}}</h5>
      @if (confirmationPopUpConfig.SUBTITLE) {
        <div class="sub-title text-center">{{confirmationPopUpConfig.SUBTITLE}}</div>
      }
    </div>

    <div class="modal-footer justify-content-center gap-sm-3 mt-3">
      <button type="button" class="btn btn-primary d-btn-primary border-0 btn-min-width"
      (click)="confirm()">{{confirmationPopUpConfig.CONFIRMTXT}}</button>
      <button type="button" class="btn btn-secondary btn-min-width"
      (click)="close()">{{confirmationPopUpConfig.CANCELTXT}}</button>

    </div>
  </div>
</p-dialog>
