<app-spinner></app-spinner>
<div class="app-main" id="appmain" [ngClass]="overflow ? 'overflow' :'no-overflow'">
  <router-outlet></router-outlet>
</div>
@if (isLoggedOut) {
  <app-warning-pop-up [heading]="'You have been logged out.'"
  [subHeading]="'We have detected a login from another device.'"></app-warning-pop-up>
}


