import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/app/environment/environment';
import { LoaderService } from './loader.service';

@Injectable()
export class RestService {
  private activeRequests = 0;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private loadingService: LoaderService
  ) {}

  private createHeaders(authRequired: boolean, options: any = {}): HttpHeaders {
    const contentType = options.contentType || 'application/json';

    let headers = new HttpHeaders();
    headers.append('Content-Type', contentType);
    if (authRequired) {
      const token = this.authService.getAuthData()?.token;

      if (token) {
        headers = headers.set('Authorization', `Bearer ${token}`);
      }
    }
    return headers;
  }

  private incrementActiveRequests(): void {
    this.activeRequests++;
    if (this.activeRequests === 1) {
      this.loadingService.setLoading(true);
    }
  }

  private decrementActiveRequests(): void {
    this.activeRequests--;
    if (this.activeRequests === 0) {
      this.loadingService.setLoading(false);
    }
  }

  /*
  ## How to Use the get Function

  Parameters:
  - `endpoint`: (string) The API endpoint to which the GET request will be sent. This is a required parameter.
  - `params`: The params to be sent in the request body. This is an optional parameter.
  - `options`: (object) Additional options to customize the request. This is an optional parameter and may include:
    - `responseType`: The type of response expected (e.g., 'json', 'text', 'blob'). Default is 'json'.
    - `observe`: Specifies which part of the HTTP response to return (e.g., 'body', 'events', 'response'). Default is 'body'.
    - `reportProgress`: A boolean indicating whether to report the progress of the request. Default is false.
    - `contentType`: The content type of the request (e.g., 'application/json'). Default is 'application/json'.
    - `loadingRequired`: (boolean) A flag indicating whether the request should show loader. Default is true. Set to false if loader is not required.
  - `authRequired`: (boolean) A flag indicating whether the request should include an authorization token in the headers. Default is true. Set to false if the bearer token is not required.
*/

  public get(
    endpoint: string,
    params?: any,
    options: any = {},
    loadingRequired: boolean = true,
    authRequired: boolean = true
  ): Observable<any> {
    const headers = this.createHeaders(authRequired, options);
    // Show loader if required
    if (loadingRequired) {
      this.incrementActiveRequests();
    }

    let httpParams = new HttpParams();

    if (params) {
      Object.keys(params).forEach((key) => {
        const value = params[key];

        // Skip empty values
        if (
          value === null ||
          value === undefined ||
          (typeof value === 'string' && value.trim() === '') ||
          (Array.isArray(value) && value.length === 0) ||
          (typeof value === 'object' && Object.keys(value).length === 0)
        ) {
          // Hide loader
          if (loadingRequired) {
            this.decrementActiveRequests();
          }
          throw new Error(`Invalid value for query parameter: ${key}`);
        }

        if (Array.isArray(value)) {
          // Convert array to multiple query params (e.g., key=val1&key=val2)
          value.forEach((item) => {
            httpParams = httpParams.append(key, item);
          });
        } else if (typeof value === 'object') {
          // Serialize objects into JSON string
          httpParams = httpParams.set(key, JSON.stringify(value));
        } else {
          // Add other types (number, string, boolean)
          httpParams = httpParams.set(key, value);
        }
      });
    }

    return this.httpClient
      .get(`${environment.apiUrl}${endpoint}`, {
        headers,
        params: httpParams,
        responseType: options.responseType || 'json',
        observe: options.observe || 'body',
        reportProgress: options.reportProgress || false,
      })
      .pipe(
        finalize(() => {
          // Hide loader after response is received or error occurs
          if (loadingRequired) {
            this.decrementActiveRequests();
          }
        })
      );
  }

  /*
  ## How to Use the post Function

  Parameters:
  - `endpoint`: (string) The API endpoint to which the post request will be sent. This is a required parameter.
  - `data`: The data to be sent in the request body. This is a required parameter.
  - `options`: (object) Additional options to customize the request. This is an optional parameter and may include:
    - `responseType`: The type of response expected (e.g., 'json', 'text', 'blob'). Default is 'json'.
    - `observe`: Specifies which part of the HTTP response to return (e.g., 'body', 'events', 'response'). Default is 'body'.
    - `reportProgress`: A boolean indicating whether to report the progress of the request. Default is false.
    - `contentType`: The content type of the request (e.g., 'application/json'). Default is 'application/json'.
    - `loadingRequired`: (boolean) A flag indicating whether the request should show loader. Default is true. Set to false if loader is not required.
  - `authRequired`: (boolean) A flag indicating whether the request should include an authorization token in the headers. Default is true. Set to false if the bearer token is not required.
*/

  public post(
    endpoint: string,
    data: any,
    options: any = {},
    loadingRequired: boolean = true,
    authRequired: boolean = true
  ): Observable<any> {
    const headers = this.createHeaders(authRequired, options);

    if (loadingRequired) {
      this.incrementActiveRequests();
    }

    return this.httpClient
      .post(`${environment.apiUrl}` + endpoint, data, {
        headers,
        responseType: options.responseType || 'json',
        observe: options.observe || 'body',
        reportProgress: options.reportProgress || false,
      })
      .pipe(
        finalize(() => {
          if (loadingRequired) {
            this.decrementActiveRequests();
          }
        })
      );
  }

  /*
  ## How to Use the put Function

  Parameters:
  - `endpoint`: (string) The API endpoint to which the PUT request will be sent. This is a required parameter.
  - `data`: (any) The data to be sent in the request body. This is a required parameter.
  - `options`: (object) Additional options to customize the request. This is an optional parameter and may include:
    - `responseType`: The type of response expected (e.g., 'json', 'text', 'blob'). Default is 'json'.
    - `observe`: Specifies which part of the HTTP response to return (e.g., 'body', 'events', 'response'). Default is 'body'.
    - `reportProgress`: A boolean indicating whether to report the progress of the request. Default is false.
    - `contentType`: The content type of the request (e.g., 'application/json'). Default is 'application/json'.
    - `loadingRequired`: (boolean) A flag indicating whether the request should show loader. Default is true. Set to false if loader is not required.
  - `authRequired`: (boolean) A flag indicating whether the request should include an authorization token in the headers. Default is true. Set to false if the bearer token is not required.
*/

  public put(
    endpoint: string,
    data: any,
    options: any = {},
    loadingRequired: boolean = true,
    authRequired: boolean = true
  ): Observable<any> {
    const headers = this.createHeaders(authRequired, options);
    // Show loader if required
    if (loadingRequired) {
      this.incrementActiveRequests();
    }

    return this.httpClient
      .put(`${environment.apiUrl}` + endpoint, data, {
        headers,
        responseType: options.responseType || 'json',
        observe: options.observe || 'body',
        reportProgress: options.reportProgress || false,
      })
      .pipe(
        finalize(() => {
          // Hide loader after response is received or error occurs
          if (loadingRequired) {
            this.decrementActiveRequests();
          }
        })
      );
  }

  /*
  ## How to Use the delete1 Function

  Parameters:
  - `endpoint`: (string) The API endpoint to which the DELETE request will be sent. This is a required parameter.
  - `data`: (any) The data to be sent in the request body. This is an optional parameter.
  - `queryParams`: (any) The query params to be sent in the request body. This is an optional parameter.
  - `options`: (object) Additional options to customize the request. This is an optional parameter and may include:
    - `responseType`: The type of response expected (e.g., 'json', 'text', 'blob'). Default is 'json'.
    - `observe`: Specifies which part of the HTTP response to return (e.g., 'body', 'events', 'response'). Default is 'body'.
    - `reportProgress`: A boolean indicating whether to report the progress of the request. Default is false.
    - `contentType`: The content type of the request (e.g., 'application/json'). Default is 'application/json'.
    - `loadingRequired`: (boolean) A flag indicating whether the request should show loader. Default is true. Set to false if loader is not required.
  - `authRequired`: (boolean) A flag indicating whether the request should include an authorization token in the headers. Default is true. Set to false if the bearer token is not required.
*/

  public delete(
    endpoint: string,
    data?: any,
    queryParams?: { [key: string]: any },
    options: any = {},
    loadingRequired: boolean = true,
    authRequired: boolean = true
  ): Observable<any> {
    const headers = this.createHeaders(authRequired, options);

    // Show loader if required
    if (loadingRequired) {
      this.incrementActiveRequests();
    }

    const requestOptions: any = {
      headers,
      responseType: options.responseType || 'json',
      observe: options.observe || 'body',
      reportProgress: options.reportProgress || false,
    };

    // Only include the body property if data is provided
    if (data) {
      requestOptions.body = data;
    }

    // Add query parameters if provided
    if (queryParams) {
      requestOptions.params = new HttpParams({ fromObject: queryParams });
    }

    return this.httpClient
      .delete(`${environment.apiUrl}${endpoint}`, requestOptions)
      .pipe(
        finalize(() => {
          // Hide loader after response is received or error occurs
          if (loadingRequired) {
            this.decrementActiveRequests();
          }
        })
      );
  }

  /*
  ## How to Use the Patch Function

  Parameters:
  - `endpoint`: (string) The API endpoint to which the PATCH request will be sent. This is a required parameter.
  - `data`: (any) The data to be sent in the request body. This is a required parameter.
  - `options`: (object) Additional options to customize the request. This is an optional parameter and may include:
    - `responseType`: The type of response expected (e.g., 'json', 'text', 'blob'). Default is 'json'.
    - `observe`: Specifies which part of the HTTP response to return (e.g., 'body', 'events', 'response'). Default is 'body'.
    - `reportProgress`: A boolean indicating whether to report the progress of the request. Default is false.
    - `contentType`: The content type of the request (e.g., 'application/json'). Default is 'application/json'.
    - `loadingRequired`: (boolean) A flag indicating whether the request should show loader. Default is true. Set to false if loader is not required.
  - `authRequired`: (boolean) A flag indicating whether the request should include an authorization token in the headers. Default is true. Set to false if the bearer token is not required.
*/

  public patch(
    endpoint: string,
    data: any,
    options: any = {},
    loadingRequired: boolean = true,
    authRequired: boolean = true
  ): Observable<any> {
    const headers = this.createHeaders(authRequired, options);
    // Show loader if required
    if (loadingRequired) {
      this.incrementActiveRequests();
    }

    return this.httpClient
      .patch(`${environment.apiUrl}` + endpoint, data, {
        headers,
        responseType: options.responseType || 'json',
        observe: options.observe || 'body',
        reportProgress: options.reportProgress || false,
      })
      .pipe(
        finalize(() => {
          // Hide loader after response is received or error occurs
          if (loadingRequired) {
            this.decrementActiveRequests();
          }
        })
      );
  }
}
