import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CONFIRMATION_POPUP_CONFIG } from 'src/app/core/constants';
import { DialogModule } from 'primeng/dialog';

@Component({
    selector: 'app-confirmationPopUp',
    templateUrl: './confirmationPopUp.component.html',
    styleUrls: ['./confirmationPopUp.component.scss'],
    standalone: true,
    imports: [DialogModule],
})
export class ConfirmationPopUpComponent {
  @Input() show: any;
  @Output() onCancel = new EventEmitter<any>();
  @Output() onConfirm = new EventEmitter<any>();
  confirmationPopUpConfig = CONFIRMATION_POPUP_CONFIG;

  constructor() {}

  close() {
    this.onCancel.emit(false);
  }

  confirm() {
    this.onConfirm.emit(true);
  }
}
