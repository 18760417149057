<!----------------Modal to tak assets in bulk (BULK TAG)------------------->
<p-dialog [modal]="true" [draggable]="false" [visible]="isBulkAssetTagVisible"
  [breakpoints]="{'1030px': '850px', '991px': '90vw'}" [style]="{'width': '90%', 'top':'2%', 'height':'90%'}" [showHeader]="false"
  (onHide)="goBack()" class="bulk-tagging-popup-wrapper" [resizable]="false" [draggable]="false">
  <div class="container-fluid">
    <div class="d-flex justify-content-between px-3 mt-3">
      <div class="d-flex align-items-center custom-width">
        <div class="d-lg-block ms-3">
          <h2 class="font-medium">BULK TAGGING</h2>
        </div>
        <!-- <div>
          <button class="btn font-bold btn-outline-tag btn-min-width"
            style="border-color: rgb(25 25 25 / 25%); pointer-events: none;">
            Total Selected: {{selectedAssets.length}}
          </button>
        </div> -->
      </div>
      <button type="button" (click)="goBack()" class="btn-close" aria-label="Close"></button>
    </div>
    
    <div class="custom-width d-flex justify-content-end">
     <h6 class="font-bold">Total Selected: {{selectedAssets.length}}</h6>
        
    
    </div>
    <div class="row">
      <form [formGroup]="uploadBulkTagsForm">
        <div class="col">
          <div class="detail-card">
            <div class="row">
              <div class="col-lg-8 pe-4">
                

                <div class="card bulk-details-sec">
                  <div class="card-body bulk-details-container">
                    <div class="bulkdetails font-bold">
                      <ul>
                        @for (element of assetNameArray; track element) {
                          <li>
                            {{ element }}
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mt-4 mt-lg-0 img-detail-sec">
                <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link w-50" id="nav-home-tab" type="button" role="tab" (click)="isActiveTab=1"
                    [ngClass]="{'active': isActiveTab===1}">Details</button>
                    <button class="nav-link w-50" id="nav-profile-tab" type="button" role="tab" (click)="isActiveTab=2"
                    [ngClass]="{'active': isActiveTab===2}">Tagging</button>
                  </div>
                </nav>

                <div class="tab-content overflow-auto" id="nav-tabContent">
                  @if (isActiveTab===1) {
                    <div class="tab-pane fade" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"
                      [ngClass]="{'show active': isActiveTab===1}">
                      <div class="row row flex-column d-flex">
                        <div class="col flex-column d-flex justify-content-start img-details">
                          <h3 _ngcontent-vvf-c90="" class="txtheading">Asset Details</h3>
                          <div class="col-12">
                            <div class="form-outline mt-3">
                              <textarea rows="3" class="form-control mt-1 mb-2" aria-describedby="assest"
                                placeholder="Title" maxlength="{{totalText}}"
                                pattern="^[a-zA-Z0-9_,.@-]+( [a-zA-Z0-9_,.@-]+)*$"
                              formControlName="assetTitle"></textarea>
                            </div>
                            <span class="remaning">{{uploadBulkTagsForm.get('assetTitle').value?.length || 0}} /
                            {{totalText}}</span>
                            <div class="form-outline mt-3 mb-3">
                              <textarea rows="5" class="form-control mt-1 mb-2" aria-describedby=" AssestDescription"
                                placeholder="Description" maxlength="{{totalText}}"
                                pattern="^[a-zA-Z0-9_,.@-]+( [a-zA-Z0-9_,.@-]+)*$"
                              formControlName="assetDescription"></textarea>
                            </div>
                            <span class="remaning">{{uploadBulkTagsForm.get('assetDescription').value?.length || 0}} /
                            {{totalText}}</span>
                          </div>
                          <div>
                            <h3 class="txtheading">Event</h3>
                            <div class="row  my-5">
                              <div class="col-12">
                                <div class="mb-2 fs-18-14">Event Name</div>
                              </div>
                              <div class="col-12">
                                <div class="form-check ps-0">
                                  <div class="col-12">
                                    <p-dropdown [options]="eventsList" placeholder="Select an event"
                                      optionLabel="eventName" optionValue="id" formControlName="eventId"
                                      [showClear]="uploadBulkTagsForm.value.eventId ? true:false" [style]="{'width':'100%'}" scrollHeight="150px" [required]="true"
                                      [filter]="true" [resetFilterOnHide]="true">
                                    </p-dropdown>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 create-event-block mt-3">
                                <div class="col d-flex justify-content-end">
                                  <button type="button" class="btn btn-primary d-btn-primary border-0"
                                    (click)="openEventModal()">
                                    <i class=" nm_icon fs-24">
                                      <img src="./assets/images/icons/icon-plus.svg" class="p-1" height="20px"
                                        width="20px" alt="">
                                      </i>
                                    Create Event</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 my-5">
                              <button type="button" class="btn btn-secondary btn-min-width me-3"
                                (click)="resetBulkTagForm()"
                              style="color: #116ACC; border: 1px solid #116ACC;">Reset</button>
                              <button type="button"
                                class="btn btn-primary new-btn btn-min-width cursorpointer addtocollection"
                            (click)="SaveBulkTagInfo()" [disabled]="
                              !uploadBulkTagsForm.dirty">Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    @if (isActiveTab===2) {
                      <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"
                        [ngClass]="{'show active': isActiveTab===2}">
                        <div class="row flex-column d-flex">
                          <div class="col-sm-12 mt-4 flex-column">
                            <div class="col-12">
                              <div class="form-outline">
                                <label class="form-label fs-18-14" for="textAreaExample">Manual
                                Tags:</label>
                                <div class="card border-0 radius-8 h-100 overflow-visible">
                                  <div class="p-0 dam-neutral-80 ">
                                    <div>
                                      <div class="d-grid d-flex flex-wrap gap-2 py-2 bulk-tag-m">
                                        <p-chips class="full-width-element w-100" [allowDuplicate]="false"
                                          formControlName="tagArray" type="reset" separator="," placeholder="Add Tags"
                                          [required]="true" [addOnBlur]="true" [addOnTab]="true"
                                        (onAdd)="handleAdd($event)"></p-chips>
                                        <span class="note"> Note: Press enter or Tab key after you added the
                                        tag name. </span>
                                      </div>
                                      @if (uploadBulkTagsForm.get('tagArray').hasError('invalidTag')) {
                                        <div class="invalid-feedback d-block fs-12">
                                          <span class="d-flex align-items-center">
                                            <img src="/assets/images/icons/warning.svg" class="warning-img" alt="Error Icon">
                                            <span>{{ uploadBulkTagsForm.get('tagArray').getError('invalidTag') }}</span>
                                          </span>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 my-5">
                              <div>
                                <h5 class="mb-2 fs-18-14">Tagging Status</h5>
                                <div class="d-flex justify-content-start align-items-center">
                                  <div class="radio">
                                    <input id="radio-1" type="radio" class="me-1" name="assetTag" [value]="1"
                                      [(ngModel)]="assetTag" formControlName="assetTag">
                                      <label for="radio-1" class="radio-label text-nowrap">Complete</label>
                                    </div>
                                    <div class="radio">
                                      <input id="radio-2" type="radio" class="me-1" name="assetTag" [value]="0"
                                        [(ngModel)]="assetTag" formControlName="assetTag">
                                        <label for="radio-2" class="radio-label text-nowrap">Pending</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12 mt-5">
                                    <button type="button" class="btn btn-secondary btn-min-width me-3"
                                      (click)="resetBulkTagForm()"
                                    style="color: #116ACC; border: 1px solid #116ACC;">Reset</button>
                                    <button type="button"
                                      class="btn btn-primary new-btn btn-min-width cursorpointer addtocollection"
                              (click)="SaveBulkTagInfo()" [disabled]="
                              !uploadBulkTagsForm.dirty">Save</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </p-dialog>

      @if (IsmodelEventShow) {
        <app-add-event-modal (closeEvent)="closeEventModal($event)"></app-add-event-modal>
      }