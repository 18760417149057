<p-dialog [modal]="true" [(visible)]="show" [showHeader]="false" [resizable]="false" [draggable]="false"
  [style]="{'width': '500px','border-radius': '3px'}" [dismissableMask]="false" class="onboarduser-form">
  <div class="onboard-user-form-wrapper">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content radius-24 p-0">
        <div class="modal-header d-flex justify-content-md-start justify-content-center h-auto radius-0">
          <h1 class="modal-title ps-modal-header-title fs-5">
            Image Editor
          </h1>
          <button type="button" class="collection-btn-close btn-close m-0 position-absolute end-0 me-4"
            aria-label="Close" (click)="close()"></button>
        </div>
        <div class="modal-body">
          <div #canvasContainer class="canvas-container position-relative">
            @if(loading){
            <div class="loader-overlay">
              <div class="spinner"></div>
            </div>
            }
            <canvas id="imageCanvas"></canvas>
          </div>

          <!-- <div class="button-container mt-2 mx-5">
            <button [ngClass]="{'selected-button': selectedAspectRatio === 'Custom'}"
              (click)="changeAspectRatio()">Custom</button>
            <button [ngClass]="{'selected-button': selectedAspectRatio === '1:1'}"
              (click)="changeAspectRatio(1, 1)">1:1</button>
            <button [ngClass]="{'selected-button': selectedAspectRatio === '4:3'}"
              (click)="changeAspectRatio(4, 3)">4:3</button>
            <button [ngClass]="{'selected-button': selectedAspectRatio === '3:4'}"
              (click)="changeAspectRatio(3, 4)">3:4</button>
            <button [ngClass]="{'selected-button': selectedAspectRatio === '16:9'}"
              (click)="changeAspectRatio(16, 9)">16:9</button>
          </div> -->

          <div class="button-container">
            <button>
              <img src="assets/images/icons/ic_crop.svg" alt="Crop" />
            </button>
            <button (click)="move()">
              <img src="assets/images/icons/ic_move.svg" alt="Move" />
            </button>
            <button (click)="zoomImage(1.1)">
              <img src="assets/images/icons/ic_zoom_in.svg" alt="Zoom In" />
            </button>
            <button (click)="zoomImage(0.9)">
              <img src="assets/images/icons/ic_zoom_out.svg" alt="Zoom Out" />
            </button>
            <button (click)="rotateImage(-90)">
              <img src="assets/images/icons/ic_anticlockwise_rotate.svg" alt="Rotate Left" />
            </button>
            <button (click)="rotateImage(90)">
              <img src="assets/images/icons/ic_clockwise_rotate.svg" alt="Rotate Right" />
            </button>
            <button (click)="flipImage()">
              <img src="assets/images/icons/ic_flip_icon.svg" alt="Flip Image" />
            </button>

            <button class="btn btn-primary apply-btn px-4 ms-3" (click)="initiateCropping()"> Save </button>

          </div>

        </div>

      </div>
    </div>
  </div>
</p-dialog>