<div class="table-component">
  <div class="table-content min-h-auto">
    <table class="table">
      <thead>
        <tr>
          @for (column of columns; track column) {
            <th class="th-sm" scope="col" [ngStyle]="{ width: column.width }">
              <div class="{{column.thClass}}">
                {{ column.label }}
                @if (column.sortKey) {
                  <div class="column-sorting ms-3"
                [ngClass]="{'desc': (reverse && currenSortBy === column.sortKey || (order === 'DESC' && sort === column.sortKey)),
                'asc': (!reverse && currenSortBy === column.sortKey || (order === 'ASC' && sort === column.sortKey))}">
                    <span (click)='setOrder(column)'><i class="bi bi-caret-up-fill"></i></span>
                    <span (click)='setOrder(column)'><i class="bi bi-caret-down-fill"></i></span>
                  </div>
                }
              </div>
            </th>
          }
        </tr>
      </thead>
      @if (dataSource && dataSource.length && dataSource.length > 0) {
        <tbody class="font-medium">
          @for (data of dataSource; track data; let i = $index) {
            <tr>
              @for (column of columns; track column) {
                <td class="name-space {{column.tdClass}}"
                  [ngClass]="{'read-more': column.readMore}">
                  @if (!column.template) {
                    @if (!column.type) {
                      @if (column.readMore) {
                        @if (!data.isReadMore) {
                          {{data[column.key]|| '-' | slice:0:50 }}
                          @if (data[column.key].length > 50) {
                            <button type="button"
                              class="btn btn-sm text-primary p-0 h-auto font-bold border-0" (click)="readMore(i)">...read more
                            </button>
                          }
                        } @else {
                          {{data[column.key]|| '-'}}
                          <button type="button" class="btn btn-sm text-primary p-0 h-auto font-bold border-0"
                            (click)="readMore(i)">read less
                          </button>
                        }
                      } @else {
                        <div [ngClass]="{ 'description-ellipse': column.ellipse }"
                          [title]="column.ellipse ? data[column.key] : ''">
                          {{ data[column.key] }}
                        </div>
                      }
                    }
                    @if (column.type == 'link') {
                      @if (column.linkConfig) {
                        <a class="view-link cursorpointer"
                          [routerLink]="[column.linkConfig.routerLink,data[column.linkConfig.pathParamKey]]">
                          {{ data[column.key] }}
                        </a>
                      } @else {
                        <div [ngClass]="{ 'description-ellipse': column.ellipse }"
                          [title]="column.ellipse ? data[column.key] : ''">
                          {{ data[column.key] }}
                        </div>
                      }
                    }
                    @if (column.type == 'serial') {
                      {{reverse && currenSortBy === 'serialNumber' ? dataSource.length+pagination.limit *(pagination.pageno-1)-i : pagination.limit *(pagination.pageno-1)+i+1}}
                    }
                    @if (column.type == 'date') {
                      {{
                      data[column.key] | date : column.format || "dd-MM-yy HH:mm"
                      }}
                    }
                    @if (column.type == 'amount') {
                      {{
                      data[column.key]
                      | currency
                      : "&#8377; "
                      : "symbol"
                      : column.format || "1.0-0"
                      }}
                    }
                    @if (column.type == 'status') {
                      <div class="status">
                        <span [ngClass]="data[column.key]">{{
                          data[column.key]
                        }}</span>
                      </div>
                    }
                  }
                  @if (column.template) {
                    <ng-container [ngTemplateOutlet]="column.template"
                      [ngTemplateOutletContext]="{ data: data, field: column.key }">
                    </ng-container>
                  }
                </td>
              }
            </tr>
          }
        </tbody>
      } @else {
        <tbody>
          <tr>
            <td class="no-data" [attr.colspan]="columns.length">
              <h3 class="text-center">No Records Found</h3>
            </td>
          </tr>
        </tbody>
      }
    </table>
  </div>
  @if (pagination && dataSource && dataSource.length>=0) {
    <div class="custom-pagination pt-1 pb-3">
      <pagination [itemsPerPage]="pagination.limit" [totalItems]="pagination.totalResults"
        (pageChanged)="onPageChange($event)" [(ngModel)]="pagination.pageno" name="currentPage" [maxSize]="5"
        [boundaryLinks]="true" [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
        [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" (contextmenu)="disableRightClick($event)">
      </pagination>
      <ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
        <img src="assets/images/icons/angle-left-solid.svg" alt="angle-left-solid-image">
      </ng-template>
      <ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
        <img src="assets/images/icons/angle-right-solid.svg" alt="angle-right-solid-image">
      </ng-template>
      <ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
        <img src="assets/images/icons/angles-right-solid.svg" alt="angles-right-solid-image">
      </ng-template>
      <ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
        <img src="assets/images/icons/angles-left-solid.svg" alt="angles-left-solid-image">
      </ng-template>
    </div>
  }
</div>
