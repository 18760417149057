import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { CommonUtils } from 'src/app/common/utils/common.utils';
import { IFolderDetails } from 'src/app/common/utils/folderMgmtUtill';
import { API_ENDPOINT, API_KEYPOINT } from 'src/app/core/constants';
import { AppDataService, AuthService, RestService } from 'src/app/core/services';
import { DialogModule } from 'primeng/dialog';
import { NgClass } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { ChipsModule } from 'primeng/chips';
import { AddEventModalComponent } from '../add-event-modal/add-event-modal.component';

@Component({
    selector: 'app-bulk-tag',
    templateUrl: './bulk-tag.component.html',
    styleUrls: ['./bulk-tag.component.scss'],
    standalone: true,
    imports: [DialogModule, FormsModule, ReactiveFormsModule, NgClass, DropdownModule, ChipsModule, AddEventModalComponent]
})
export class BulkTagComponent {
  firstItem: IFolderDetails = {
    firstName: 'First',
    lastName: 'Folder',
    userId: 1,
    folderCount: '',
    assignedCount: '',
    parentId: 0,
    assetCounts: '',
    untaggedAssetsCount: '',
    roleId: 1,
    folderName: '',
    folderId: 0,
    isTaged: 0,
    createdAt: '',
    createByFirstname: '',
    createByLastname: '',
    accessType: 2,
    isCollapse: false,
    isFolderRightClick: false,
    children: [],
  };
  uploadBulkTagsForm: any;
  commonUtils = new CommonUtils();
  totalText = 1000;
  event: any = {
    eventName: '',
    eventTime: '',
    description: '',
    eventLocation: '',
  };
  isuploadBulkTagsForm: boolean = false;
  IsmodelEventShow: boolean = false;
  assetDescription: any;
  assetTitle : any;
  manualTagArray: any = [];
  bulkAssetStatus: any = 0;
  shortTagArrManual: any = [];
  longTagArrManual: any = [];
  uploadTagsForm : any;
  isActiveTab: any = 2;
  isBulkAssetTagVisible : boolean = false;
  @Output() closeBulktag = new EventEmitter<any>();
  @Input() selectedAssets : any;
  @Input() objectForSourceTrack : any;
  @Input() submitted : any;
   eventsList : any;
  @Input() childFolderId : any;
  @Input() assetTag : any;
   assetNameArray : any = [];
   assetsIdArray: any = [];

  constructor(
    private appDataService: AppDataService,
    private restService: RestService,
    private messageService: MessageService,
    private authService: AuthService
  ) {
    this.uploadBulkTagsForm = new FormGroup({
      assetTag: new FormControl(''),
      assetArr: new FormControl(),
      tagArray: new FormControl([]),
      eventId: new FormControl(''),
      folderId: new FormControl(''),
      assetDescription: new FormControl('', [
        this.commonUtils.assetInputValidator(this.totalText),
      ]),
      assetTitle: new FormControl('', [
        this.commonUtils.assetInputValidator(this.totalText),
      ]),
    });

    this.uploadTagsForm = new FormGroup({
      assetTag: new FormControl(''),
      assetArr: new FormControl(),
      tagArray: new FormControl('', [Validators.required]),
      eventId: new FormControl('', [Validators.required]),
      folderId: new FormControl(''),
    });
  }

  ngOnInit()
  {
    if (this.selectedAssets && this.selectedAssets.length) {
      this.selectedAssets.forEach((el: any) => {
        this.assetsIdArray.push(el.id);
        this.assetNameArray.push(el.assetName);
    this.isBulkAssetTagVisible = true;
      });
      this.assetTag = this.selectedAssets[0].assetTag;
      this.uploadBulkTagsForm.get('assetTag').patchValue(this.assetTag)
    }
    this.getEvents();
  }
  

  goBack() {
    this.event = {};
    this.submitted = false;
    this.uploadBulkTagsForm.reset();
    this.uploadBulkTagsForm.markAsPristine();
    this.uploadBulkTagsForm.markAsUntouched();
    this.isBulkAssetTagVisible = false;
    this.selectedAssets = [];
    this.appDataService.notifyChange(this.objectForSourceTrack);
    this.appDataService.addAssetToSelectedAssets(new Map());
    this.closeBulktag.emit(false);
  }

  openEventModal() {
    this.IsmodelEventShow = true;
  }

  resetBulkTagForm() {
    this.event = {};
    this.submitted = false;
    this.uploadBulkTagsForm.reset({
      assetTag: 0, // Set the assetTag control to 0 (Pending) by default
    });
    this.uploadBulkTagsForm.markAsPristine();
    this.uploadBulkTagsForm.markAsUntouched();
    this.assetTag=0;
  }

 async SaveBulkTagInfo() {
    this.assetDescription = this.uploadBulkTagsForm.value.assetDescription;
    this.assetTitle = this.uploadBulkTagsForm.value.assetTitle;
    this.manualTagArray = this.uploadBulkTagsForm.value.tagArray;
    this.bulkAssetStatus = this.uploadBulkTagsForm.value.assetTag;
    if (this.eventsList && this.eventsList.length) {
      this.eventsList.filter((newel: any) => {
        if (newel.id === this.uploadBulkTagsForm.value.eventId) {
          this.event.eventName = newel.eventName;
          this.event.eventDescription = newel.description;
          this.event.eventLocation = newel.eventLocation;
          this.event.eventTime = newel.eventTime;
        }
        this.isuploadBulkTagsForm = false;
      });
    }
    if (this.manualTagArray && this.manualTagArray.length) {
      this.shortTagArrManual = this.manualTagArray.slice(0, 10);
      this.longTagArrManual = this.manualTagArray.slice(10);
    }
   await this.uploadBulkTag();
  }

 async uploadBulkTag() {
    this.uploadBulkTagsForm.value.assetArr = this.assetsIdArray;
    this.uploadTagsForm.value.assetTag =
      this.uploadTagsForm.value.assetTag === null ||
      this.uploadTagsForm.value.assetTag === undefined ||
      this.uploadTagsForm.value.assetTag === ''
        ? 0
        : this.uploadTagsForm.value.assetTag;
    this.uploadBulkTagsForm.value.folderId = this.childFolderId;
    // set Default value for form attributes
    if (this.uploadBulkTagsForm.value.tagArray == null) {
      this.uploadBulkTagsForm.value.tagArray = [];
    }
    if (this.uploadBulkTagsForm.value.assetTag == null) {
      this.uploadBulkTagsForm.value.assetTag = 0;
    }
    if (this.uploadBulkTagsForm.value.assetDescription == null) {
      this.uploadBulkTagsForm.value.assetDescription = '';
    }
    if (this.uploadBulkTagsForm.value.assetTitle == null) {
      this.uploadBulkTagsForm.value.assetTitle = '';
    }
    this.restService
      .post(API_KEYPOINT.assets.uploadBulkTags, this.uploadBulkTagsForm.value)
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            let params = { fileDetails: this.objectForSourceTrack };
            this.closeBulktag.emit(params);              
            this.uploadBulkTagsForm.get('tagArray').reset([]);
    this.uploadBulkTagsForm.reset();
    this.uploadBulkTagsForm.markAsUntouched();
    this.uploadBulkTagsForm.markAsPristine();
    this.isBulkAssetTagVisible = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Tags & Metadata updated successfully!',
            });
          }
        },
        error: (error: any) => {
          this.selectedAssets = [];
          this.isBulkAssetTagVisible = false;
          this.uploadBulkTagsForm.get('tagArray').reset([]);
          this.uploadBulkTagsForm.reset();
          this.uploadBulkTagsForm.markAsUntouched();
          this.uploadBulkTagsForm.markAsPristine();
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
  }

  handleAdd(tagToAdd: any) {
    const tagValue = tagToAdd.value.toLowerCase();
    const tagArray = this.uploadBulkTagsForm.value.tagArray;
    const tagControl = this.uploadBulkTagsForm.get('tagArray');
    const tagPattern = /^[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$/;
    if (!tagPattern.test(tagValue)) {
      tagControl.setErrors({ invalidTag: 'Only - and _ are allowed as special characters.' });
      this.uploadBulkTagsForm.value.tagArray.pop();
      tagToAdd.value = '';
      return;
    }
    for (let i = 0; i < tagArray.length - 1; i++) {
      if (tagArray[i].toLowerCase() === tagValue) {
        this.uploadBulkTagsForm.value.tagArray.pop();
      }
    }
  }

  getEvents() {
    this.restService.get(`${API_ENDPOINT.baseEndpoint}${this.authService.getAuthData()?.clientId}/${API_ENDPOINT.event.getEvents}`).subscribe({
      next: (data: any) => {
        if (data.code == 200) {
          this.eventsList = data.result.result;
        }
      },
      error: (error: any) => {
        this.selectedAssets = [];
        this.appDataService.addAssetToSelectedAssets(new Map());
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: error.error.message,
        });
      },
    });
  }

  closeEventModal(event: any) {
    if (event && event.id) {
      this.uploadTagsForm.value.eventId = event.id;
      this.uploadBulkTagsForm.patchValue({
        eventId: event.id,
      });
      this.getEvents();
      this.uploadBulkTagsForm.markAsDirty();

    }
    this.IsmodelEventShow = false;
  }
}
